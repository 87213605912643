*,
*:before,
*:after {
	box-sizing: border-box !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
html {
	font-family: sans-serif;
	-webkit-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	text-size-adjust: 100%;
	-ms-overflow-style: -ms-autohiding-scrollbar;
}
body {
	margin: 0;
}

[hidden] {
	display: none !important;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section,
summary {
	display: block;
}
audio,
canvas,
progress,
video {
	display: inline-block;
	vertical-align: baseline;
}
audio:not([controls]) {
	display: none;
	height: 0;
}
:active {
	outline: none;
}
a {
	color: inherit;
	background-color: transparent;
	text-decoration: none;
}
a:active,
a:hover {
	outline: 0;
}
b,
strong {
	font-weight: bold;
}
small {
	font-size: 80%;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	font-size: inherit;
	font-weight: inherit;
}
p:last-child,
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
	margin-bottom: 0;
}
img {
	max-width: 100%;
	height: auto;
	border-style: none;
	vertical-align: top;
}
ul,
ol {
	margin: 0;
	padding: 0;
	list-style-position: inside;
}
pre {
	overflow: auto;
}
code,
kbd,
pre,
samp {
	font-family: monospace, monospace;
	font-size: 16px;
}
button,
input,
optgroup,
select,
textarea {
	color: inherit;
	font: inherit;
	margin: 0;
}
button,
input[type="submit"] {
	padding: 0;
	overflow: visible;
	background: none;
	border: none;
	border-radius: 0;
	-webkit-appearance: none;
}
button,
select {
	text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: button;
	cursor: pointer;
}
button[disabled],
html input[disabled] {
	cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}
input {
	line-height: normal;
	-moz-appearance: none;
	border-radius: 0;
}
input[type="checkbox"],
input[type="radio"] {
	box-sizing: border-box;
	padding: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	height: auto;
}
input[type="search"] {
	-webkit-appearance: none;
	box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
	color: inherit;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
	color: inherit;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
	color: inherit;
}
input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
	color: inherit;
}
input::placeholder,
textarea::placeholder {
	color: inherit;
}
.is-tabbing input[type="radio"]:focus + label {
	outline: 1px dotted #212121;
	outline: 5px auto -webkit-focus-ring-color;
}
fieldset {
	border: 1px solid #c0c0c0;
	margin: 0 2px;
	padding: 6px 10px 12px;
}
legend {
	border: 0;
	padding: 0;
}
textarea {
	overflow: auto;
}
optgroup {
	font-weight: bold;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
td,
th {
	padding: 0;
}
.flickity-enabled {
	position: relative;
	overflow: visible !important;
}
.flickity-enabled:focus {
	outline: none;
}
.flickity-viewport {
	overflow: hidden;
	position: relative;
	height: 100%;
	width: 100%;
}
.flickity-slider {
	position: absolute;
	width: 100%;
	height: 100%;
}
.flickity-enabled.is-draggable {
	-webkit-tap-highlight-color: transparent;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.flickity-enabled.is-draggable .flickity-viewport {
	cursor: -webkit-grab;
	cursor: grab;
}
.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
	cursor: -webkit-grabbing;
	cursor: grabbing;
}
.flickity-page-dots {
	position: absolute;
	width: 100%;
	padding: 0;
	bottom: 0;
	list-style: none;
	text-align: center;
	line-height: 0;
}
.flickity-rtl .flickity-page-dots {
	direction: rtl;
}
.flickity-page-dots .dot {
	position: relative;
	display: inline-block;
	width: 6px;
	height: 6px;
	margin: 0 5px;
	background: rgba(103, 114, 121, 0.8);
	border-radius: 100%;
	cursor: pointer;
	-webkit-transform: scale(1);
	transform: scale(1);
	will-change: transform;
	transition: background 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
	transition: background 0.2s ease-in-out, transform 0.2s ease-in-out;
	transition: background 0.2s ease-in-out, transform 0.2s ease-in-out,
		-webkit-transform 0.2s ease-in-out;
}
.flickity-page-dots .dot::before {
	position: absolute;
	content: "";
	top: -4px;
	right: -4px;
	left: -4px;
	bottom: -4px;
}
.flickity-page-dots .dot.is-selected {
	-webkit-transform: scale(1.35);
	transform: scale(1.35);
	background: #1e2d7d;
}
.flickity-prev-next-button {
	position: absolute;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 50px;
	height: 50px;
	top: calc(50% - 25px);
	border-radius: 100%;
	visibility: visible;
	background: #abb1b4;
	opacity: 0;
	transition: all 0.2s ease-in-out;
	-webkit-transform: scale(0.7);
	transform: scale(0.7);
	z-index: 1;
}
.flickity-prev-next-button:hover {
	background: #00badb;
}
.flickity-prev-next-button svg {
	position: relative;
	width: 15px;
	height: 15px;
	fill: #fff;
	vertical-align: middle;
}
.flickity-prev-next-button[disabled] {
	opacity: 0;
	visibility: hidden;
}
.flickity-prev-next-button.previous {
	left: -25px;
}
.flickity-prev-next-button.previous svg {
	left: 1px;
}
.flickity-prev-next-button.next {
	right: -25px;
}
.flickity-prev-next-button.next svg {
	left: -1px;
}
.flickity-enabled:hover .flickity-prev-next-button:not([disabled]) {
	-webkit-transform: scale(1);
	transform: scale(1);
	opacity: 1;
}
.flickity-enabled.is-fade .flickity-slider > * {
	pointer-events: none;
	z-index: 0;
	visibility: hidden;
	transition: opacity 0.3s linear, visibility 0.3s linear !important;
	opacity: 0 !important;
}
.flickity-enabled.is-fade .flickity-slider > .is-selected {
	pointer-events: auto;
	z-index: 1;
	visibility: visible;
	opacity: 1 !important;
}
.modal[aria-hidden="true"] .flickity-slider > * {
	pointer-events: none !important;
}
.product-gallery__carousel.is-fade .flickity-slider > * {
	transition: opacity 0.3s linear !important;
}
@-webkit-keyframes drift-fadeZoomIn {
	0% {
		-webkit-transform: scale(1.2);
		transform: scale(1.2);
		opacity: 0;
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 1;
	}
}
@keyframes drift-fadeZoomIn {
	0% {
		-webkit-transform: scale(1.2);
		transform: scale(1.2);
		opacity: 0;
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 1;
	}
}
@-webkit-keyframes drift-fadeZoomOut {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 1;
	}
	100% {
		-webkit-transform: scale(0.5);
		transform: scale(0.5);
		opacity: 0;
	}
}
@keyframes drift-fadeZoomOut {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 1;
	}
	100% {
		-webkit-transform: scale(0.5);
		transform: scale(0.5);
		opacity: 0;
	}
}
@-webkit-keyframes drift-loader-rotate {
	0% {
		-webkit-transform: translate(-50%, -50%) rotate(0);
		transform: translate(-50%, -50%) rotate(0);
	}
	50% {
		-webkit-transform: translate(-50%, -50%) rotate(-180deg);
		transform: translate(-50%, -50%) rotate(-180deg);
	}
	100% {
		-webkit-transform: translate(-50%, -50%) rotate(-360deg);
		transform: translate(-50%, -50%) rotate(-360deg);
	}
}
@keyframes drift-loader-rotate {
	0% {
		-webkit-transform: translate(-50%, -50%) rotate(0);
		transform: translate(-50%, -50%) rotate(0);
	}
	50% {
		-webkit-transform: translate(-50%, -50%) rotate(-180deg);
		transform: translate(-50%, -50%) rotate(-180deg);
	}
	100% {
		-webkit-transform: translate(-50%, -50%) rotate(-360deg);
		transform: translate(-50%, -50%) rotate(-360deg);
	}
}
@-webkit-keyframes drift-loader-before {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	10% {
		-webkit-transform: scale(1.2) translateX(6px);
		transform: scale(1.2) translateX(6px);
	}
	25% {
		-webkit-transform: scale(1.3) translateX(8px);
		transform: scale(1.3) translateX(8px);
	}
	40% {
		-webkit-transform: scale(1.2) translateX(6px);
		transform: scale(1.2) translateX(6px);
	}
	50% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	60% {
		-webkit-transform: scale(0.8) translateX(6px);
		transform: scale(0.8) translateX(6px);
	}
	75% {
		-webkit-transform: scale(0.7) translateX(8px);
		transform: scale(0.7) translateX(8px);
	}
	90% {
		-webkit-transform: scale(0.8) translateX(6px);
		transform: scale(0.8) translateX(6px);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
@keyframes drift-loader-before {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	10% {
		-webkit-transform: scale(1.2) translateX(6px);
		transform: scale(1.2) translateX(6px);
	}
	25% {
		-webkit-transform: scale(1.3) translateX(8px);
		transform: scale(1.3) translateX(8px);
	}
	40% {
		-webkit-transform: scale(1.2) translateX(6px);
		transform: scale(1.2) translateX(6px);
	}
	50% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	60% {
		-webkit-transform: scale(0.8) translateX(6px);
		transform: scale(0.8) translateX(6px);
	}
	75% {
		-webkit-transform: scale(0.7) translateX(8px);
		transform: scale(0.7) translateX(8px);
	}
	90% {
		-webkit-transform: scale(0.8) translateX(6px);
		transform: scale(0.8) translateX(6px);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
@-webkit-keyframes drift-loader-after {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	10% {
		-webkit-transform: scale(1.2) translateX(-6px);
		transform: scale(1.2) translateX(-6px);
	}
	25% {
		-webkit-transform: scale(1.3) translateX(-8px);
		transform: scale(1.3) translateX(-8px);
	}
	40% {
		-webkit-transform: scale(1.2) translateX(-6px);
		transform: scale(1.2) translateX(-6px);
	}
	50% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	60% {
		-webkit-transform: scale(0.8) translateX(-6px);
		transform: scale(0.8) translateX(-6px);
	}
	75% {
		-webkit-transform: scale(0.7) translateX(-8px);
		transform: scale(0.7) translateX(-8px);
	}
	90% {
		-webkit-transform: scale(0.8) translateX(-6px);
		transform: scale(0.8) translateX(-6px);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
@keyframes drift-loader-after {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	10% {
		-webkit-transform: scale(1.2) translateX(-6px);
		transform: scale(1.2) translateX(-6px);
	}
	25% {
		-webkit-transform: scale(1.3) translateX(-8px);
		transform: scale(1.3) translateX(-8px);
	}
	40% {
		-webkit-transform: scale(1.2) translateX(-6px);
		transform: scale(1.2) translateX(-6px);
	}
	50% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	60% {
		-webkit-transform: scale(0.8) translateX(-6px);
		transform: scale(0.8) translateX(-6px);
	}
	75% {
		-webkit-transform: scale(0.7) translateX(-8px);
		transform: scale(0.7) translateX(-8px);
	}
	90% {
		-webkit-transform: scale(0.8) translateX(-6px);
		transform: scale(0.8) translateX(-6px);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
.drift-zoom-pane {
	position: absolute;
	background: #fff;
	top: 0;
	left: 0;
	height: 520px;
	width: 100%;
	max-width: 520px;
	z-index: 2;
	border: 1px solid #00badb;
	border-radius: 3px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}
.drift-zoom-pane.drift-opening {
	-webkit-animation: drift-fadeZoomIn 180ms ease-out;
	animation: drift-fadeZoomIn 180ms ease-out;
}
.drift-zoom-pane.drift-closing {
	-webkit-animation: drift-fadeZoomOut 210ms ease-in;
	animation: drift-fadeZoomOut 210ms ease-in;
}
.drift-zoom-pane.drift-inline {
	position: absolute;
	width: 150px;
	height: 150px;
	border-radius: 50%;
	box-shadow: 0 6px 18px rgba(0, 0, 0, 0.3);
}
.drift-loading .drift-zoom-pane-loader {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 66px;
	height: 20px;
	-webkit-animation: drift-loader-rotate 1800ms infinite linear;
	animation: drift-loader-rotate 1800ms infinite linear;
}
.drift-zoom-pane-loader:before,
.drift-zoom-pane-loader:after {
	content: "";
	display: block;
	width: 20px;
	height: 20px;
	position: absolute;
	top: 50%;
	margin-top: -10px;
	border-radius: 20px;
	background: rgba(103, 114, 121, 0.4);
}
.drift-zoom-pane-loader:before {
	left: 0;
	-webkit-animation: drift-loader-before 1800ms infinite linear;
	animation: drift-loader-before 1800ms infinite linear;
}
.drift-zoom-pane-loader:after {
	right: 0;
	-webkit-animation: drift-loader-after 1800ms infinite linear;
	animation: drift-loader-after 1800ms infinite linear;
	-webkit-animation-delay: -900ms;
	animation-delay: -900ms;
}
.drift-bounding-box {
	background-color: rgba(0, 186, 219, 0.05);
	border: 1px solid #00badb;
	border-radius: 3px;
}
@media screen and (min-width: 641px) {
	.drift-zoom-pane.drift-inline {
		width: 240px;
		height: 240px;
	}
}
html {
	font-family: "DIN Next", sans-serif;
	font-weight: 400;
	font-style: normal;
	font-size: 15px;
	line-height: 1.87;
	color: #677279;
	background: #f3f5f6;
}
@media screen and (min-width: 641px) {
	html {
		font-size: 16px;
	}
}
.heading {
	font-family: "DIN Next", sans-serif;
	font-weight: 500;
	font-style: normal;
	color: #1e2d7d;
}
.h1,
.rte h1 {
	margin-bottom: 20px;
	font-size: 25px;
	line-height: 1.45;
}
.h2,
.rte h2 {
	font-size: 23px;
	line-height: 1.55;
}
.h3,
.rte h3 {
	margin-bottom: 14px;
	font-size: 21px;
	line-height: 1.6;
}
.h4,
.rte h4 {
	font-size: 17px;
	line-height: 1.75;
}
.h5,
.rte h5 {
	font-size: 15px;
	line-height: 1.85;
	text-transform: uppercase;
}
.h6,
.rte h6 {
	font-size: 13px;
	line-height: 1.85;
	text-transform: uppercase;
}
@media screen and (min-width: 641px) {
	.h1,
	.rte h1 {
		font-size: 29px;
		line-height: 1.43;
	}
	.h2,
	.rte h2 {
		font-size: 26px;
		line-height: 1.5;
	}
	.h3,
	.rte h3 {
		font-size: 22px;
		line-height: 1.5;
	}
	.h4,
	.rte h4 {
		font-size: 19px;
		line-height: 1.7;
	}
	.h5,
	.rte h5 {
		font-size: 17px;
		line-height: 1.75;
	}
	.h6,
	.rte h6 {
		font-size: 14px;
		line-height: 1.7;
	}
}
.text--strong,
.highlight {
	color: #1e2d7d;
	font-weight: 500;
}
.text--pull {
	margin-top: -0.435em;
}
.link {
	transition: color 0.2s ease-in-out;
}
.link:hover,
.link:focus {
	color: #00badb;
}
.link--secondary:hover,
.link--secondary:focus {
	color: #1e2d7d;
}
.link--strong {
	font-weight: 500;
}
.link--accented {
	color: #00badb;
}
.link--accented:hover {
	text-decoration: underline;
}
.link--underline {
	text-decoration: underline;
}
@-webkit-keyframes spinnerRotation {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes spinnerRotation {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
.icon {
	display: inline-block;
	height: 1em;
	width: 1em;
	fill: currentColor;
	vertical-align: middle;
	background: none;
	pointer-events: none;
	overflow: visible;
}
.icon--search-loader {
	-webkit-animation: spinnerRotation 0.7s infinite linear;
	animation: spinnerRotation 0.7s infinite linear;
}
@media (-moz-touch-enabled: 1), (hover: none) {
	.touch-area {
		position: relative;
		background: transparent;
	}
	.touch-area::before {
		position: absolute;
		content: "";
		top: -8px;
		right: -8px;
		left: -8px;
		bottom: -8px;
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
	}
}
.table-wrapper {
	overflow: auto;
	white-space: nowrap;
	-webkit-overflow-scrolling: touch;
}
.table,
.rte table {
	width: 100%;
	text-align: left;
	font-size: 15px;
}
.table th,
.rte table th {
	font-weight: normal;
}
.table th,
.rte table th,
.table td,
.rte table td {
	padding: 16px 20px;
}
.table th:first-child,
.rte table th:first-child,
.table td:first-child,
.rte table td:first-child {
	padding-left: 20px;
}
.table th:last-child,
.rte table th:last-child,
.table td:last-child,
.rte table td:last-child {
	padding-right: 20px;
}
.table tbody tr,
.rte table tbody tr {
	border-top: 1px solid #e1e3e4;
}
.table .table__cell--right,
.rte table .table__cell--right {
	text-align: right;
}
.table .table__cell--center,
.rte table .table__cell--center {
	text-align: center;
}
.table--loose tbody td {
	padding-top: 26px;
	padding-bottom: 26px;
}
@media screen and (max-width: 640px) {
	.card .table,
	.card .rte table,
	.rte .card table {
		margin-left: 20px;
	}
	.card .table th:first-child,
	.card .rte table th:first-child,
	.rte .card table th:first-child,
	.card .table td:first-child,
	.card .rte table td:first-child,
	.rte .card table td:first-child {
		padding-left: 0;
	}
}
@media screen and (min-width: 641px) {
	.table th,
	.rte table th,
	.table td,
	.rte table td {
		padding: 15px 30px;
	}
	.table th:first-child,
	.rte table th:first-child,
	.table td:first-child,
	.rte table td:first-child {
		padding-left: 30px;
	}
	.table th:last-child,
	.rte table th:last-child,
	.table td:last-child,
	.rte table td:last-child {
		padding-right: 30px;
	}
}
@media screen and (min-width: 1000px) {
	.table-wrapper {
		white-space: normal;
		overflow: visible;
	}
}
html {
	overflow-x: hidden;
}
body:not(.is-tabbing) [tabindex]:focus,
body:not(.is-tabbing) label:focus,
body:not(.is-tabbing) button:focus,
body:not(.is-tabbing) input:focus,
body:not(.is-tabbing) select:focus,
body:not(.is-tabbing) textarea:focus {
	outline: none;
}
.is-locked {
	overflow-y: hidden;
}
.container {
	max-width: 1480px;
	margin-left: auto;
	margin-right: auto;
	padding: 0 20px;
}
.container--medium {
	max-width: 1150px;
}
.container--narrow {
	max-width: 800px;
}
.container--extra-narrow {
	max-width: 630px;
}
.container--giga-narrow {
	max-width: 520px;
}
.anchor {
	display: block;
	position: relative;
	top: -75px;
	visibility: hidden;
}
@supports (--css: variables) {
	.anchor {
		top: calc(-1 * var(--header-height));
	}
}
.js .no-js {
	display: none !important;
}
@media screen and (max-width: 640px) {
	.container--flush {
		padding: 0;
	}
	.container--unflush {
		padding: 0 20px;
	}
}
@media screen and (min-width: 641px) {
	.container {
		padding: 0 40px;
	}
}
.aspect-ratio {
	position: relative;
	margin-left: auto;
	margin-right: auto;
}
.aspect-ratio img,
.aspect-ratio video {
	position: absolute;
	height: 100%;
	width: 100%;
	max-width: 100%;
	max-height: 100%;
	top: 0;
	left: 0;
}
.aspect-ratio--square {
	padding-bottom: 100% !important;
}
.aspect-ratio--short {
	padding-bottom: 75% !important;
}
.aspect-ratio--tall {
	padding-bottom: 150% !important;
}
.aspect-ratio--square img,
.aspect-ratio--short img,
.aspect-ratio--tall img {
	position: absolute;
	width: auto;
	height: auto;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
@supports (-o-object-fit: contain) or (object-fit: contain) {
	.aspect-ratio--square img,
	.aspect-ratio--short img,
	.aspect-ratio--tall img {
		width: 100%;
		height: 100%;
		-o-object-fit: contain;
		object-fit: contain;
	}
}
@-webkit-keyframes lazyLoader {
	0%,
	100% {
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
	}
	50% {
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
	}
}
@keyframes lazyLoader {
	0%,
	100% {
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
	}
	50% {
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
	}
}
img.lazyload[data-sizes="auto"] {
	width: 100%;
}
.image--fade-in {
	/* opacity: 0; */
	transition: opacity 0.3s ease-in-out;
}
.image--blur-up {
	-webkit-filter: blur(1px);
	filter: blur(1px);
}
.lazyloaded.image--fade-in,
.no-js [data-bgset],
.no-js [data-bg] {
	opacity: 1;
}
.lazyloaded.image--blur-up {
	-webkit-filter: none;
	filter: none;
}
.lazyload__loader {
	position: absolute;
	display: block;
	height: 2px;
	width: 40px;
	left: 0;
	bottom: 0;
	right: 0;
	top: 0;
	opacity: 0;
	visibility: hidden;
	margin: auto;
	pointer-events: none;
	background-color: #e1e3e4;
	z-index: -1;
	transition: all 0.2s ease-in-out;
	overflow: hidden;
}
.lazyload__loader::after {
	position: absolute;
	content: "";
	bottom: 0;
	right: 0;
	top: 0;
	height: 100%;
	width: 200%;
	background-color: #677279;
}
.lazyloading ~ .lazyload__loader {
	opacity: 1;
	visibility: visible;
	z-index: 1;
}
.lazyloading ~ .lazyload__loader::after {
	-webkit-animation: lazyLoader 3s infinite;
	animation: lazyLoader 3s infinite;
	-webkit-animation-timing-function: cubic-bezier(0.43, 0.43, 0.25, 0.99);
	animation-timing-function: cubic-bezier(0.43, 0.43, 0.25, 0.99);
}
.placeholder-background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.placeholder-svg {
	display: block;
	fill: #677279;
	background-color: rgba(103, 114, 121, 0.1);
	width: 100%;
	height: 100%;
	max-width: 100%;
	max-height: 100%;
}
.placeholder-svg--inverted {
	fill: #f3f5f6;
	background-color: #677279;
	fill-opacity: 0.5;
}
.grid {
	display: block;
	list-style: none;
	padding: 0;
	margin: 0 0 -18px -18px;
	font-size: 0;
}
.grid__cell {
	box-sizing: border-box;
	display: inline-block;
	width: 100%;
	padding: 0 0 18px 18px;
	margin: 0;
	vertical-align: top;
	font-size: 1rem;
}
@media screen and (min-width: 641px) {
	.grid {
		margin: 0 0 -30px -30px;
	}
	.grid__cell {
		padding: 0 0 30px 30px;
	}
}
.\31\/1 {
	width: 100%;
}
.\31\/2 {
	width: 50%;
}
.\31\/3 {
	width: 33.333333%;
}
.\31\/4 {
	width: 25%;
}
.\31\/5 {
	width: 20%;
}
.\31\/6 {
	width: 16.666667%;
}
@media screen and (max-width: 640px) {
	.hidden-phone {
		display: none !important;
	}
	.\31\/1--phone {
		width: 100%;
	}
	.\31\/2--phone {
		width: 50%;
	}
	.\31\/3--phone {
		width: 33.333333%;
	}
	.\31\/4--phone {
		width: 25%;
	}
	.\31\/5--phone {
		width: 20%;
	}
	.\31\/6--phone {
		width: 16.666667%;
	}
}
@media screen and (min-width: 641px) and (max-width: 999px) {
	.hidden-tablet {
		display: none !important;
	}
	.\31\/1--tablet {
		width: 100%;
	}
	.\31\/2--tablet {
		width: 50%;
	}
	.\31\/3--tablet {
		width: 33.333333%;
	}
	.\31\/4--tablet {
		width: 25%;
	}
	.\31\/5--tablet {
		width: 20%;
	}
	.\31\/6--tablet {
		width: 16.666667%;
	}
}
@media screen and (min-width: 641px) {
	.hidden-tablet-and-up {
		display: none !important;
	}
	.\31\/1--tablet-and-up {
		width: 100%;
	}
	.\31\/2--tablet-and-up {
		width: 50%;
	}
	.\31\/3--tablet-and-up {
		width: 33.333333%;
	}
	.\31\/4--tablet-and-up {
		width: 25%;
	}
	.\31\/5--tablet-and-up {
		width: 20%;
	}
	.\31\/6--tablet-and-up {
		width: 16.666667%;
	}
}
@media screen and (max-width: 999px) {
	.hidden-pocket {
		display: none !important;
	}
	.\31\/1--pocket {
		width: 100%;
	}
	.\31\/2--pocket {
		width: 50%;
	}
	.\31\/3--pocket {
		width: 33.333333%;
	}
	.\31\/4--pocket {
		width: 25%;
	}
	.\31\/5--pocket {
		width: 20%;
	}
	.\31\/6--pocket {
		width: 16.666667%;
	}
}
@media screen and (min-width: 1000px) and (max-width: 1279px) {
	.hidden-lap {
		display: none !important;
	}
	.\31\/1--lap {
		width: 100%;
	}
	.\31\/2--lap {
		width: 50%;
	}
	.\31\/3--lap {
		width: 33.333333%;
	}
	.\31\/4--lap {
		width: 25%;
	}
	.\31\/5--lap {
		width: 20%;
	}
	.\31\/6--lap {
		width: 16.666667%;
	}
}
@media screen and (min-width: 1000px) {
	.hidden-lap-and-up {
		display: none !important;
	}
	.\31\/1--lap-and-up {
		width: 100%;
	}
	.\31\/2--lap-and-up {
		width: 50%;
	}
	.\31\/3--lap-and-up {
		width: 33.333333%;
	}
	.\31\/4--lap-and-up {
		width: 25%;
	}
	.\31\/5--lap-and-up {
		width: 20%;
	}
	.\31\/6--lap-and-up {
		width: 16.666667%;
	}
}
@media screen and (min-width: 1280px) {
	.hidden-desk {
		display: none !important;
	}
	.\31\/1--desk {
		width: 100%;
	}
	.\31\/2--desk {
		width: 50%;
	}
	.\31\/3--desk {
		width: 33.333333%;
	}
	.\31\/4--desk {
		width: 25%;
	}
	.\31\/5--desk {
		width: 20%;
	}
	.\31\/6--desk {
		width: 16.666667%;
	}
}
@media screen and (min-width: 1440px) {
	.hidden-wide {
		display: none !important;
	}
	.\31\/1--wide {
		width: 100%;
	}
	.\31\/2--wide {
		width: 50%;
	}
	.\31\/3--wide {
		width: 33.333333%;
	}
	.\31\/4--wide {
		width: 25%;
	}
	.\31\/5--wide {
		width: 20%;
	}
	.\31\/6--wide {
		width: 16.666667%;
	}
}
.block-list {
	display: flex;
	flex-flow: column nowrap;
	margin: 0 -7px -20px -7px;
}
.block-list--no-flush.block-list--no-flush {
	margin-bottom: 0;
}
.block-list__item {
	display: flex;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.block-list__item {
		display: block;
	}
}
.block-list__item > :first-child {
	margin: 0 7px 20px 7px;
	flex: 1 0 0%;
}
@media screen and (max-width: 999px) {
	.scroller .block-list {
		white-space: nowrap;
		flex-flow: row nowrap;
	}
	.scroller .block-list::before {
		content: "";
		flex: 0 0 20px;
	}
	.scroller .block-list::after {
		content: "";
		flex: 0 0 13px;
	}
	.scroller .block-list__item {
		flex: 0 0 auto;
		white-space: normal;
		scroll-snap-align: center;
	}
}
@media screen and (max-width: 640px) {
	.scroller .block-list__item {
		width: 81%;
	}
}
@media screen and (min-width: 641px) and (max-width: 999px) {
	.scroller .block-list__item {
		width: 56%;
	}
	.scroller .block-list::before {
		flex: 0 0 40px;
	}
	.scroller .block-list::after {
		flex: 0 0 25px;
	}
}
@media screen and (min-width: 641px) {
	.block-list {
		flex-direction: row;
		flex-wrap: wrap;
		margin: 0 -15px -30px -15px;
	}
	.block-list__item > :first-child {
		margin: 0 15px 30px 15px;
	}
}
@media screen and (min-width: 1000px) {
	.block-list {
		white-space: normal;
	}
	.block-list::before,
	.block-list::after {
		display: none;
	}
	.block-list__item--grow {
		flex-grow: 1;
	}
}
.block-list--loose {
	margin-bottom: -45px;
}
.block-list--loose .block-list__item > :first-child {
	margin-bottom: 45px;
}
@media screen and (min-width: 641px) {
	.block-list--loose {
		margin-bottom: -60px;
	}
	.block-list--loose .block-list__item > :first-child {
		margin-bottom: 60px;
	}
}
.article-item__image-container {
	display: block;
	margin-bottom: 15px;
	border-radius: 3px;
	overflow: hidden;
}
.article-item__image-container--placeholder {
	height: 200px;
}
.article-item__image {
	-o-object-fit: cover;
	object-fit: cover;
	-o-object-position: center;
	object-position: center;
	font-family: "object-fit: cover; object-position: center";
	-webkit-transform: scale(1.01);
	transform: scale(1.01);
	transition: opacity 0.2s ease-in-out,
		-webkit-transform 0.95s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: opacity 0.2s ease-in-out,
		transform 0.95s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: opacity 0.2s ease-in-out,
		transform 0.95s cubic-bezier(0.25, 0.46, 0.45, 0.94),
		-webkit-transform 0.95s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
@media (-moz-touch-enabled: 0), (hover: hover) {
	.article-item:hover .article-item__image {
		-webkit-transform: scale(1.07);
		transform: scale(1.07);
	}
	.article-item:hover .article-item__title {
		color: #00badb;
	}
}
.article-item__meta {
	font-size: 13px;
}
.article-item__meta-item:not(:last-child)::after {
	display: inline-block;
	content: "";
	width: 5px;
	height: 5px;
	margin: -1px 10px 0 10px;
	border-radius: 100%;
	background: rgba(103, 114, 121, 0.4);
	vertical-align: middle;
}
.article-item__title {
	margin-bottom: 4px;
}
.article-item__excerpt {
	margin-top: 12px;
}
@media screen and (max-width: 999px) {
	.article-item:not(:last-child) {
		padding-bottom: 20px;
	}
	.scroller .article-item:not(:last-child) {
		padding-bottom: 0;
	}
}
@media screen and (min-width: 641px) {
	.article-item__meta {
		font-size: 14px;
	}
}
@media screen and (min-width: 1000px) {
	.blog-container--without-sidebar .article-item--featured .aspect-ratio {
		padding-bottom: 40% !important;
	}
}
@media screen and (min-width: 1280px) {
	.article-item__meta-item + .article-item__meta-item::before {
		margin: 0 14px;
	}
}
.article__image-wrapper {
	position: relative;
	width: calc(100% + (20px * 2));
	left: -20px;
	margin-top: 4px;
	z-index: 1;
}
.article__toolbar,
.article__toolbar-item {
	display: flex;
	align-items: center;
}
.article__toolbar {
	justify-content: space-between;
}
.article__toolbar-item .icon--bi-comment {
	width: 24px;
	height: 23px;
	margin-right: 15px;
}
.article__share-label {
	margin-right: 20px;
}
.article__comments-count {
	color: #1e2d7d;
}
.article__inner {
	max-width: 680px;
	margin: 40px auto 100px auto;
}
.article__content {
	margin-bottom: 2.8em;
}
.article__aside {
	margin-bottom: 50px;
}
.article__aside-item {
	display: flex;
	align-items: center;
}
.article__aside-item + .article__aside-item {
	margin-top: 20px;
}
.article__navigation {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 41px 0;
	border-top: 1px solid #e1e3e4;
	border-bottom: 1px solid #e1e3e4;
	font-size: 15px;
}
.article__navigation svg {
	width: 8px;
	height: 12px;
	vertical-align: -1px;
}
.article__navigation--align-right {
	justify-content: flex-end;
}
.article__navigation-item--prev svg {
	margin-right: 12px;
}
.article__navigation-item--next svg {
	margin-left: 12px;
}
.article__comment-list {
	margin-top: 50px;
}
.article__comment-list-heading {
	margin-bottom: 30px;
}
.article-comment {
	display: flex;
	align-items: flex-start;
	margin-bottom: 32px;
}
.article-comment:first-child {
	margin-top: 30px;
}
.article-comment:last-child {
	margin-bottom: 0;
}
.article-comment__gravatar {
	border-radius: 100%;
	max-width: 50px;
	margin: 5px 22px 0 0;
}
.article-comment__author {
	margin-bottom: 0;
	font-size: 15px;
}
.article-comment__date {
	display: block;
	margin-bottom: 12px;
	font-style: italic;
	font-size: 15px;
}
.article__comment-form {
	margin-top: 50px;
}
.article__comment-list + .article__comment-form {
	margin-top: 70px;
}
.article__comment-form-title {
	margin-bottom: 12px;
}
.article__moderated-note {
	font-style: italic;
}
.article__comment-form-wrapper {
	margin-top: 32px;
}
@media screen and (min-width: 641px) {
	.article__image-wrapper {
		position: relative;
		width: 100%;
		left: 0;
		border-radius: 3px 3px 0 0;
		margin: 0 0 -1px 0;
		overflow: hidden;
	}
	.article__image-wrapper + .card {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
	.article__inner {
		margin-top: 60px;
	}
	.article__content {
		font-size: 17px;
	}
	.article-comment {
		margin-bottom: 42px;
	}
	.article-comment__author {
		margin-bottom: 2px;
		font-size: 17px;
	}
	.article-comment__date {
		margin-bottom: 12px;
	}
}
.shopify-section__article {
	max-width: 980px;
}
.blog-sidebar__item {
	margin-bottom: 45px;
}
.blog-sidebar__item--products,
.blog-sidebar__item--newsletter {
	margin-bottom: 50px;
}
.blog-sidebar__item:first-child:not(.blog-sidebar__item--newsletter) {
	margin-top: -0.435em;
}
.blog-sidebar__block-title {
	margin-bottom: 20px;
}
@media screen and (min-width: 1000px) {
	.blog-container {
		display: flex;
		align-items: flex-start;
		justify-content: center;
	}
	.shopify-section__blog-posts,
	.shopify-section__article {
		flex-grow: 1;
		flex-shrink: 0;
		flex-basis: 0;
	}
	.blog-sidebar {
		width: 300px;
		margin-left: 60px;
	}
}
@media screen and (min-width: 641px) and (max-width: 999px) {
	.blog-sidebar {
		width: 350px;
		margin-left: auto;
		margin-right: auto;
	}
}
@media screen and (min-width: 1280px) {
	.blog-sidebar {
		width: 350px;
		margin-left: 70px;
	}
}
.blog-sidebar__item--newsletter {
	padding: 30px 20px 30px 20px;
	border-radius: 3px;
	text-align: center;
}
.blog-sidebar__item--newsletter .heading {
	color: inherit;
}
@media screen and (max-width: 640px) {
	.blog-sidebar__item--newsletter {
		border-radius: 0;
		margin-left: -20px;
		margin-right: -20px;
	}
}
.blog-sidebar__post-list {
	list-style: none;
}
.blog-sidebar__post-item {
	display: flex;
	align-items: flex-start;
	font-size: 15px;
}
.blog-sidebar__post-item:hover .blog-sidebar__post-image {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}
.blog-sidebar__post-item:hover .blog-sidebar__post-title {
	color: #00badb;
}
.blog-sidebar__post-item + .blog-sidebar__post-item {
	margin-top: 30px;
}
.blog-sidebar__post-image-wrapper {
	position: relative;
	display: block;
	flex-shrink: 0;
	width: 100px;
	margin-right: 20px;
}
.blog-sidebar__post-image,
.blog-sidebar__post-placeholder {
	min-height: 80px;
	transition: -webkit-transform 0.65s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: transform 0.65s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: transform 0.65s cubic-bezier(0.25, 0.46, 0.45, 0.94),
		-webkit-transform 0.65s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.blog-sidebar__post-image-overflow {
	overflow: hidden;
	border-radius: 3px;
}
.blog-sidebar__post-title {
	position: relative;
	display: block;
	display: -webkit-box;
	margin-top: 2px;
	line-height: 1.55;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.blog-sidebar__post-meta {
	margin-top: 4px;
	font-size: 14px;
}
.blog-sidebar__post-meta-item:not(:last-child)::after {
	display: inline-block;
	content: "";
	width: 5px;
	height: 5px;
	margin: 0 10px;
	border-radius: 100%;
	background: rgba(103, 114, 121, 0.4);
	vertical-align: middle;
}
.blog-sidebar__post-list--ranked {
	counter-reset: post-ranking 0;
}
.blog-sidebar__post-list--ranked .blog-sidebar__post-image-wrapper::before {
	position: absolute;
	content: counter(post-ranking);
	top: calc(50% - 16px);
	left: -16px;
	border: 2px solid #f3f5f6;
	height: 32px;
	width: 32px;
	line-height: 28px;
	text-align: center;
	border-radius: 100%;
	background: #00badb;
	color: #fff;
	font-weight: 500;
	font-size: 15px;
	counter-increment: post-ranking;
	z-index: 1;
}
.blog-sidebar__item--products .product-item {
	width: 100%;
}
.blog-sidebar__item--products .product-item__image-wrapper {
	width: 75px !important;
}
.blog-sidebar__item--products .product-item__price-list > .price {
	font-size: 17px;
}
@media screen and (max-width: 640px) {
	.blog-sidebar__item--products .product-list--horizontal {
		border: 1px solid #e1e3e4;
		border-radius: 3px;
	}
}
.blog-sidebar__linklist {
	list-style: none;
	font-size: 15px;
}
.blog-sidebar__link-item {
	padding: 14px 0;
	border-bottom: 1px solid #d4d6d8;
	line-height: 1.5;
}
.blog-sidebar__link-item:first-child {
	padding-top: 5px;
}
.blog-sidebar__link-item:last-child {
	border-bottom: none;
	padding-bottom: 0;
}
.blog-sidebar__link-item > a {
	display: block;
}
.button,
.shopify-payment-button__button--unbranded,
#shopify-product-reviews .spr-summary-actions-newreview,
#shopify-product-reviews .spr-button {
	position: relative;
	display: inline-block;
	padding: 0 30px;
	line-height: 48px;
	border-radius: 2px;
	text-align: center;
	font-weight: 500;
	font-size: 16px;
	cursor: pointer;
	transition: background 0.25s ease-in-out, color 0.25s ease-in-out,
		box-shadow 0.25s ease-in-out;
}
[disabled].button,
[disabled].shopify-payment-button__button--unbranded,
#shopify-product-reviews [disabled].spr-summary-actions-newreview,
#shopify-product-reviews [disabled].spr-button {
	cursor: not-allowed;
}
.button--extra-small {
	padding: 0 15px;
	font-size: 15px;
	line-height: 34px;
}
.button--small {
	font-size: 15px;
	line-height: 42px;
}
.button--large {
	font-size: 17px;
	line-height: 55px;
}
.button--extra-large {
	font-size: 19px;
	line-height: 60px;
}
.button--primary,
#shopify-product-reviews .spr-summary-actions-newreview,
#shopify-product-reviews .spr-button {
	background: #00badb;
	color: #fff;
}
.button--primary:hover,
#shopify-product-reviews .spr-summary-actions-newreview:hover,
#shopify-product-reviews .spr-button:hover {
	background: rgba(0, 186, 219, 0.8);
}
.button--secondary {
	background: #1e2d7d;
	color: #fff;
}
.button--secondary:hover {
	background: rgba(30, 45, 125, 0.8);
}
.button--ternary {
	color: #00badb;
	box-shadow: 0 0 0 1px #e1e3e4 inset;
}
.button--ternary:hover {
	background: rgba(225, 227, 228, 0.5);
	color: #677279;
}
.button--transparent {
	color: #677279;
	box-shadow: 0 0 0 1px #e1e3e4 inset;
}
.button--transparent:hover {
	background: #00badb;
	color: #fff;
	box-shadow: 0 0 0 1px #00badb inset;
}
.button--disabled {
	background: #8a9297;
	box-shadow: 0 0 0 1px #8a9297 inset;
	color: #fff;
	color: #ffffff;
}
.button--full {
	width: 100%;
}
.button--min-width {
	min-width: 200px;
}
.button--floating {
	transition: box-shadow 0.2s ease-in-out;
}
.button--floating:hover {
	box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.06);
}
.button-wrapper {
	text-align: center;
}
.button-stack {
	display: flex;
	flex-direction: column;
}
.button-stack > .button + .button {
	margin-top: 10px;
}
.button-group {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: -5px;
}
.button-group > * {
	margin: 5px;
}
.button-group--fit > * {
	flex: 1 0 0;
	padding-left: 15px;
	padding-right: 15px;
}
.button-group--loose {
	margin: -10px;
}
.button-group--loose > * {
	margin: 10px;
}
.card {
	position: relative;
	margin-bottom: 18px;
	background: #fff;
	border-top: 1px solid #e1e3e4;
	border-bottom: 1px solid #e1e3e4;
}
.card__header {
	position: relative;
	padding: 20px 20px 0 20px;
}
.card__header--flex {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.card__title {
	margin-bottom: 0;
}
.card__subtitle {
	margin-bottom: 14px;
	font-size: 13px;
}
.card__subtitle:first-child {
	margin-top: -0.435em;
}
.card__title--small {
	margin-bottom: 10px;
	margin-top: -2px;
	font-size: 21px;
}
.card__section,
.card__collapsible-button {
	position: relative;
	padding: 20px;
}
.card__collapsible-button {
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-align: left;
	width: 100%;
	cursor: pointer;
}
.card__collapsible-button .plus-button {
	margin-left: 10px;
	color: #1e2d7d;
}
.card__section + .card__section::before {
	position: absolute;
	display: block;
	content: "";
	width: calc(100% - 20px);
	top: 0;
	right: 0;
	height: 1px;
	background: #e1e3e4;
}
.card__section--no-padding {
	padding: 0 !important;
}
.card__collapsible {
	height: 0;
	overflow: hidden;
	transition: 0.3s ease-in-out;
}
.card__collapsible-content {
	padding: 0 20px 20px 20px;
}
.card__separator {
	width: calc(100% + 20px);
	margin: 20px 0;
	border: none;
	border-top: 1px solid #e1e3e4;
}
.card__navigation {
	display: flex;
	justify-content: space-between;
	margin-bottom: 12px;
	margin-top: -3px;
	font-size: 15px;
}
.card__navigation-breadcrumb svg {
	margin-right: 12px;
	width: 8px;
	height: 12px;
	vertical-align: -1px;
}
.card__linklist {
	list-style: none;
}
.card__linklist-item {
	display: block;
	padding: 2px 0;
	text-align: left;
}
@media screen and (max-width: 640px) {
	.card {
		border-radius: 0;
	}
	.card__section--flex .button {
		margin-top: 20px;
		width: 100%;
	}
}
@media screen and (max-width: 999px) {
	.card--collapsed {
		margin-top: -18px !important;
		border-top: none !important;
		box-shadow: 0 -1px #fff;
	}
	.card--collapsed .card__header,
	.card--collapsed .card__section {
		padding-top: 0;
	}
}
@media screen and (min-width: 641px) and (max-width: 999px) {
	.card--collapsed {
		margin-top: -30px !important;
		border-radius: 0 0 3px 3px;
	}
}
@media screen and (min-width: 641px) {
	.card {
		margin-bottom: 30px;
		border-radius: 3px;
		border: 1px solid #e1e3e4;
	}
	.card__header {
		padding: 30px 30px 0 30px;
	}
	.card__section,
	.card__collapsible-button {
		padding: 30px;
	}
	.card__section + .card__section::before {
		width: 100%;
	}
	.card__section--flex {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.card__subtitle {
		font-size: 14px;
	}
	.card__collapsible-content {
		padding: 0 30px 30px 30px;
	}
	.card__separator {
		width: 100%;
		margin: 24px 0;
	}
	.card__header--tight {
		padding-top: 20px;
	}
	.card__section--tight {
		padding: 20px 25px;
	}
	.card__section--tight .card__separator {
		margin: 20px 0;
	}
	.card__navigation {
		margin-bottom: 15px;
	}
}
@media screen and (min-width: 1000px) {
	.card--sticky {
		position: sticky;
		top: 175px;
		margin-bottom: 0;
	}
}
.card__section .rte .button:last-child {
	margin-bottom: 15px;
}
.mini-cart {
	position: absolute;
	width: 100vw;
	height: 100vh;
	left: 0;
	top: 100%;
	max-height: 0;
	background: #fff;
	color: #677279;
	z-index: 1;
	visibility: hidden;
	opacity: 0;
	-webkit-transform: scale(0.9);
	transform: scale(0.9);
	transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out,
		max-height 0s linear 0.25s, -webkit-transform 0.25s ease-in-out;
	transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out,
		visibility 0.25s ease-in-out, max-height 0s linear 0.25s;
	transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out,
		visibility 0.25s ease-in-out, max-height 0s linear 0.25s,
		-webkit-transform 0.25s ease-in-out;
	will-change: transform;
}
.mini-cart[aria-hidden="false"] {
	visibility: visible;
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
	transition: opacity 0.4s cubic-bezier(0, 1, 0.4, 1), visibility 0.4s linear,
		-webkit-transform 0.4s cubic-bezier(0.18, 1.25, 0.4, 1);
	transition: opacity 0.4s cubic-bezier(0, 1, 0.4, 1),
		transform 0.4s cubic-bezier(0.18, 1.25, 0.4, 1), visibility 0.4s linear;
	transition: opacity 0.4s cubic-bezier(0, 1, 0.4, 1),
		transform 0.4s cubic-bezier(0.18, 1.25, 0.4, 1), visibility 0.4s linear,
		-webkit-transform 0.4s cubic-bezier(0.18, 1.25, 0.4, 1);
}
.mini-cart .icon--nav-triangle-borderless {
	position: absolute;
	width: 18px;
	height: 8px;
	right: 6px;
	bottom: 100%;
	z-index: 2;
	-webkit-filter: drop-shadow(0 -2px 2px rgba(0, 0, 0, 0.12));
	filter: drop-shadow(0 -2px 2px rgba(0, 0, 0, 0.12));
}
.mini-cart__alert-wrapper {
	padding-top: 20px;
}
.mini-cart .alert {
	margin-bottom: 0;
	flex-shrink: 0;
	font-size: 15px;
}
.mini-cart__content:not(.mini-cart__content--empty) > * {
	padding-left: 20px;
	padding-right: 20px;
}
.mini-cart__content--empty {
	padding: 20px 25px 25px 25px;
}
.mini-cart__empty-state {
	padding: 70px 0 60px 0;
	text-align: center;
}
.mini-cart__empty-state svg {
	margin-bottom: 10px;
}
.mini-cart__line-item-list {
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	-ms-scroll-chaining: none;
	overscroll-behavior: none;
}
.mini-cart__line-item {
	display: flex;
	align-items: flex-start;
	padding: 20px 0;
}
.mini-cart__line-item + .mini-cart__line-item {
	border-top: 1px solid #e1e3e4;
}
.mini-cart__image-wrapper {
	min-width: 80px;
	width: 80px;
	margin-right: 20px;
}
.mini-cart__product-info {
	margin-bottom: 12px;
}
.mini-cart__product-vendor {
	display: block;
	margin-bottom: 7px;
	line-height: 1.55;
	font-size: 13px;
	text-transform: uppercase;
}
.mini-cart__product-title {
	display: block;
	margin-bottom: 4px;
	font-size: 15px;
	line-height: 1.5;
}
.mini-cart__property-list {
	line-height: 1.5;
	margin: 6px 0;
}
.mini-cart__price-list > .price {
	font-size: 14px;
	font-weight: 500;
}
.mini-cart__price-info {
	margin-top: -2px;
	font-size: 14px;
}
.mini-cart__discount-list {
	list-style: none;
	margin-top: 2px;
}
.mini-cart__discount {
	display: inline-block;
	font-weight: 500;
	font-size: 14px;
	background: rgba(238, 0, 0, 0.08);
	color: #e00;
	border-radius: 2px;
	padding: 0 11px;
}
.mini-cart__discount svg {
	margin-right: 6px;
	vertical-align: text-bottom;
}
.mini-cart__discount + .mini-cart__discount {
	margin-top: 5px;
}
.mini-cart__quantity-remove {
	display: inline-block;
	margin-left: 10px;
	font-size: 14px;
	line-height: 1;
}
.mini-cart__recap {
	padding: 15px 20px 20px 20px;
	border-top: 1px solid #e1e3e4;
}
.mini-cart__recap-price-line {
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #1e2d7d;
	font-weight: 500;
}
.mini-cart__recap-price-line--highlight {
	color: #e00;
}
.mini-cart__recap-price-line + .mini-cart__recap-price-line {
	margin-top: 4px;
}
.mini-cart__amount-saved {
	color: #e00;
	font-weight: 500;
}
.mini-cart__button-container {
	margin-top: 16px;
}
@media screen and (max-width: 640px) {
	.mini-cart .icon--nav-triangle-borderless {
		right: 24px;
	}
	.mini-cart__content {
		display: flex;
		flex-direction: column;
	}
	.mini-cart__inner,
	.mini-cart__content--empty {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: calc(100% - 20px);
	}
}
@media screen and (min-width: 641px) {
	.mini-cart {
		left: auto;
		right: 0;
		top: calc(100% + 14px);
		max-height: none;
		width: 470px;
		height: auto;
		border-radius: 3px;
		box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.1);
	}
	.mini-cart__content > * {
		padding-left: 25px;
		padding-right: 25px;
	}
	.mini-cart__content--empty {
		padding-bottom: 25px;
	}
	.mini-cart__line-item-list {
		max-height: 300px;
	}
	.mini-cart__item-wrapper {
		display: flex;
		flex-grow: 1;
		align-items: flex-start;
		justify-content: space-between;
	}
	.mini-cart__quantity-remove {
		display: block;
		width: -webkit-max-content;
		width: -moz-max-content;
		width: max-content;
		margin: 10px auto 0 auto;
	}
	.mini-cart__product-info {
		margin: 0 20px 0 0;
	}
	.mini-cart__recap {
		padding: 15px 25px 25px 25px;
	}
}
@media screen and (min-height: 700px) and (min-width: 641px) {
	.mini-cart__line-item-list {
		max-height: 330px;
	}
}
@media screen and (min-width: 1280px) {
	.mini-cart .icon--nav-triangle-borderless {
		right: 58px;
	}
}
.cart-recap__secure-payment-list {
	max-width: 300px;
	margin: -4px auto !important;
}
@media screen and (max-width: 999px) {
	.cart-wrapper {
		min-height: 0 !important;
	}
}
@media screen and (min-width: 1000px) {
	.cart-wrapper {
		max-width: 1480px;
		margin-left: auto;
		margin-right: auto;
	}
	.cart-wrapper__inner {
		position: relative;
	}
	.cart-wrapper__inner-inner {
		width: calc(100% - 380px);
	}
	.cart-recap {
		position: absolute;
		right: 40px;
		top: 0;
		width: 350px;
		height: 100%;
	}
	.cart-recap__scroller {
		position: -webkit-sticky;
		position: sticky;
		top: 0;
	}
	@supports (--css: variables) {
		.cart-recap__scroller {
			top: calc(var(--header-height) + 30px);
		}
	}
}
@media screen and (min-width: 1280px) {
	.cart-wrapper__inner-inner {
		width: calc(100% - 430px);
	}
	.cart-recap {
		width: 400px;
	}
}
.gift-wrap {
	padding: 20px 10px 20px 20px;
	font-size: 15px;
}
.gift-wrap__left {
	display: flex;
	align-items: center;
	margin-bottom: 18px;
}
.gift-wrap__icon svg {
	display: block;
	margin-top: -4px;
	margin-right: 25px;
	width: 24px;
	height: 24px;
}
.gift-wrap__text > span:first-child {
	margin-right: 10px;
}
@media screen and (min-width: 641px) {
	.gift-wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 10px 10px 30px;
	}
	.gift-wrap__left {
		margin-bottom: 0;
	}
}
.estimate-shipping {
	font-size: 15px;
}
.estimate-shipping__toggle {
	display: flex;
	align-items: center;
	padding: 20px;
	width: 100%;
	color: #1e2d7d;
	font-weight: 500;
}
.estimate-shipping__icon svg {
	display: block;
	margin-right: 25px;
	width: 24px;
	height: 24px;
}
.estimate-shipping__arrow {
	margin-left: auto;
}
.estimate-shipping__arrow svg {
	width: 12px;
	height: 8px;
	transition: -webkit-transform 0.2s ease-in-out;
	transition: transform 0.2s ease-in-out;
	transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
.estimate-shipping__toggle[aria-expanded="true"] .estimate-shipping__arrow svg {
	-webkit-transform: rotateZ(180deg);
	transform: rotateZ(180deg);
}
.estimate-shipping__collapsible .shipping-estimator {
	padding: 0 20px 20px 20px;
}
@media screen and (min-width: 641px) {
	.estimate-shipping__toggle {
		padding: 20px 30px;
	}
	.estimate-shipping__collapsible .shipping-estimator {
		padding-right: 30px;
		padding-left: 30px;
	}
}
.cart-recap__price-line {
	display: flex;
	justify-content: space-between;
	margin-bottom: 4px;
	font-size: 16px;
	font-weight: 500;
	color: #1e2d7d;
}
.cart-recap__price-line + .cart-recap__price-line {
	margin-top: 4px;
}
.cart-recap__price-line--highlight {
	color: #e00;
}
.cart-recap__amount-saved {
	color: #e00;
	font-weight: 500;
	font-size: 16px;
}
.cart-recap__note {
	margin-top: 14px;
	border-top: 1px solid #e1e3e4;
	border-bottom: 1px solid #e1e3e4;
}
.cart-recap__note-inner {
	padding-bottom: 20px;
}
.cart-recap__note-button {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 0;
	width: 100%;
}
.cart-recap__note-button svg {
	width: 12px;
	height: 8px;
	transition: -webkit-transform 0.2s ease-in-out;
	transition: transform 0.2s ease-in-out;
	transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
.cart-recap__note-button[aria-expanded="true"] svg {
	-webkit-transform: rotateZ(180deg);
	transform: rotateZ(180deg);
}
.cart-recap__note-edit {
	margin-right: 14px;
	color: #00badb;
	opacity: 0;
	transition: opacity 0.2s ease-in-out;
}
.cart-recap__note-edit.is-visible {
	opacity: 1;
}
.cart-recap__notices {
	margin: 24px 0;
}
.cart-recap__secure-payment-title {
	margin-bottom: 10px;
	text-align: center;
	font-weight: 500;
}
.cart-recap__secure-payment-title svg {
	margin-right: 8px;
	vertical-align: text-top;
}
@media screen and (min-width: 641px) {
	.cart-recap__price-line {
		font-size: 19px;
	}
	.cart-recap__amount-saved {
		font-size: 17px;
	}
	.cart-recap__note {
		margin-top: 20px;
	}
}
.collection__image-wrapper {
	position: relative;
	height: 180px;
	margin-bottom: -1px;
	z-index: 1;
	overflow: hidden;
}
.collection__image-wrapper--small {
	height: 140px;
}
.collection__image-wrapper--large {
	height: 230px;
}
.collection__image-wrapper--preserve-ratio {
	height: auto !important;
}
.collection__image-wrapper + .card {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.collection__image {
	height: 100%;
	background-size: cover;
	background-position: center;
}
.collection__title {
	margin-bottom: 5px;
}
.collection__meta {
	margin-bottom: 12px;
}
.collection__description {
	margin-top: 20px;
}
.collection__brand-logo-wrapper {
	margin-right: 20px;
	padding: 10px;
	width: 70px;
	height: 70px;
	border: 1px solid #e1e3e4;
	border-radius: 3px;
}
.collection__brand-logo-image {
	height: 100%;
	width: 100%;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}
.collection__products-count {
	margin-bottom: 18px;
	font-size: 14px;
}
.collection__toolbar {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 22px;
	padding: 0 20px;
	box-shadow: 0 1px #e1e3e4, 0 -1px #e1e3e4;
	background: #fff;
	z-index: 3;
}
.collection__toolbar-item {
	display: flex;
	align-items: center;
	height: 48px;
}
.collection__toolbar-item--filter svg {
	margin-right: 12px;
	width: 19px;
	height: 20px;
}
.collection__layout-label {
	margin-right: 20px;
}
.collection__layout-button {
	opacity: 0.7;
	transition: all 0.2s ease-in-out;
}
.collection__layout-button:last-child {
	margin-left: 15px;
}
.collection__layout-button:hover {
	opacity: 1;
}
.collection__layout-button.is-selected {
	opacity: 1;
	color: #1e2d7d;
}
.collection__layout-button svg {
	display: block;
	width: 18px;
	height: 18px;
}
.collection__header .expandable-content--expandable {
	margin-bottom: -22px;
}
.collection__header--brand .collection__meta {
	display: flex;
	align-items: center;
}
.collection__header--brand .collection__description {
	margin-top: 0;
}
@media screen and (max-width: 999px) {
	.collection__toolbar {
		position: -webkit-sticky;
		position: sticky;
		top: calc(var(--header-height) * var(--header-is-sticky, 0));
	}
}
@media screen and (min-width: 641px) {
	.collection__image-wrapper {
		height: 230px;
		border-radius: 3px 3px 0 0;
	}
	.collection__image-wrapper--small {
		height: 200px;
	}
	.collection__image-wrapper--large {
		height: 310px;
	}
	.collection__brand-logo-wrapper {
		width: 90px;
		height: 90px;
		margin-right: 30px;
	}
	.collection__products-count {
		font-size: 15px;
	}
	.collection__toolbar {
		padding: 0 30px;
		font-size: 15px;
	}
	.collection__toolbar--bordered {
		margin-top: 30px;
		box-shadow: 0 1px #e1e3e4, 0 -1px #e1e3e4;
	}
	.card__header--tight + .collection__toolbar--bordered {
		margin-top: 25px;
	}
	.collection__toolbar-item {
		height: 58px;
	}
	.collection__toolbar-item .value-picker-button {
		margin-left: 4px;
	}
}
@media screen and (max-width: 640px) {
	.collection__toolbar-item--count + .collection__toolbar-item--layout {
		margin-left: auto;
	}
}
@media screen and (min-width: 1000px) {
	.collection__toolbar:not(.collection__toolbar--bordered) {
		margin-top: -12px;
		box-shadow: 0 1px #e1e3e4;
	}
	.collection__brand-logo-wrapper {
		width: 110px;
		height: 110px;
		flex: none;
	}
	.collection__header--brand {
		display: flex;
		align-items: flex-start;
	}
	.collection__header--brand .collection__meta {
		display: block;
	}
}
@media screen and (min-width: 1280px) {
	.collection__title {
		margin-bottom: 12px;
	}
	.collection__description {
		margin-top: 10px;
	}
	.collection__showing-count,
	.collection__toolbar-item--sort {
		margin-right: 45px;
	}
	.collection__toolbar-item--sort {
		margin-left: auto;
	}
	.collection__header--brand .collection__meta {
		margin-bottom: 0;
	}
}
@media screen and (min-width: 1440px) {
	.collection__showing-count,
	.collection__toolbar-item--sort {
		margin-right: 80px;
	}
}
.collection__filter-group-list--ordered {
	display: flex;
	flex-direction: column;
}
.collection__filter-group {
	padding: 13px 20px 14px 20px;
	border-bottom: 1px solid #e1e3e4;
}
.collection__filter-group-name {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	text-align: left;
}
.collection__filter-group-name svg {
	width: 12px;
	height: 8px;
	margin-left: 10px;
	transition: -webkit-transform 0.25s ease-in-out;
	transition: transform 0.25s ease-in-out;
	transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
}
.collection__filter-group-name[aria-expanded="true"] svg {
	-webkit-transform: rotateZ(180deg);
	transform: rotateZ(180deg);
}
.collection__filter-collapsible {
	height: 0;
	overflow: hidden;
	visibility: hidden;
	transition: 0.2s ease-in-out;
}
.collection__filter-collapsible[aria-hidden="false"] {
	visibility: visible;
}
.collection__filter-item-active {
	display: block;
	margin-top: -5px;
	color: #00badb;
	font-size: 13px;
}
.collection__filter-linklist {
	list-style: none;
	padding: 4px 0 8px 0;
}
.collection__filter-link {
	display: block;
	width: 100%;
	padding: 3px 0 4px 0;
	text-align: left;
}
.collection__filter-link svg {
	width: 12px;
	height: 8px;
	margin-left: 8px;
	transition: -webkit-transform 0.25s ease-in-out;
	transition: transform 0.25s ease-in-out;
	transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
}
.collection__filter-link[aria-expanded="true"] svg {
	-webkit-transform: rotateZ(180deg);
	transform: rotateZ(180deg);
}
.collection__filter-link.is-active {
	font-weight: 500;
	color: #00badb;
}
.collection__filter-linklist .collection__filter-linklist {
	padding: 0 0 0 10px;
	font-size: 15px;
}
.collection__filter-color-list {
	padding: 7px 0 5px 6px;
}
.collection__filter-checkbox-list {
	list-style: none;
}
.collection__filter-collapsible .collection__filter-checkbox-list {
	padding: 8px 0 7px 10px;
}
.collection__filter-checkbox {
	display: flex;
	align-items: center;
	padding: 2px 0 3px 0;
}
.collection__filter-checkbox label {
	margin-left: 4px;
	line-height: 1.5;
}
.collection__filter-icon--active {
	position: relative;
}
.collection__filter-icon--active::after {
	content: "";
	position: absolute;
	top: 3px;
	left: 13px;
	width: 8px;
	height: 8px;
	border-radius: 100%;
	background: #00badb;
}
.collection__active-filters {
	margin: -5px 0 16px 0;
}
.collection__active-filters:empty {
	display: none;
}
.collection__active-filter-item {
	display: flex;
	width: 100%;
	padding: 4px 0;
	align-items: center;
	line-height: 1.5;
	font-weight: 500;
	color: #00badb;
}
.collection__active-filter-cross {
	position: relative;
	display: inline-block;
	margin-right: 12px;
	background: #00badb;
	color: #fff;
	height: 20px;
	width: 20px;
	border-radius: 3px;
	box-shadow: 0 1px rgba(225, 227, 228, 0.4);
}
.collection__active-filter-cross svg {
	position: absolute;
	top: 6px;
	left: 6px;
	width: 8px;
	height: 8px;
	stroke-width: 2px;
	stroke: white;
}
.collection__clear-filter {
	margin: 13px 0 9px 0;
}
@media screen and (min-width: 1000px) {
	.collection__filter-group {
		padding: 6px 0;
		border-bottom: 0;
	}
	.collection__filter-group-name {
		justify-content: flex-start;
	}
	.collection__filter-linklist {
		padding: 0 0 9px 0;
	}
	.collection__filter-linklist .collection__filter-linklist {
		padding-left: 15px;
	}
	.collection__filter-link {
		padding: 2px 0;
	}
	.collection__filter-color-list {
		padding: 12px 0 10px 0;
	}
	.collection__filter-collapsible .collection__filter-checkbox-list {
		padding-bottom: 12px;
	}
	.collection__filter-group:last-child .collection__filter-checkbox-list {
		padding-bottom: 0;
	}
	.collection__filter-checkbox {
		padding: 0;
	}
	.collection__filter-checkbox .checkbox-wrapper {
		margin: 8px 0;
	}
}
@media screen and (min-width: 1280px) {
	.collection__sidebar {
		flex-basis: 290px;
	}
}
.collection__mobile-filters-recap {
	background: #f3f5f6;
	border-bottom: 1px solid #e1e3e4;
}
.collection__mobile-active-filters {
	margin: -5px;
	font-size: 13px;
}
.collection__mobile-active-filter-item {
	padding: 4px 12px;
	background: #00badb;
	border-radius: 3px;
	box-shadow: 0 1px rgba(0, 186, 219, 0.15);
	color: #fff;
	font-weight: 500;
}
.collection__mobile-active-filter-item,
.collection__mobile-active-clear {
	margin: 5px;
}
.collection__mobile-active-filter-cross {
	margin-right: 7px;
}
.collection__mobile-active-filter-cross svg {
	width: 7px;
	height: 7px;
	stroke: currentColor;
	stroke-width: 3px;
}
.collection__mobile-active-clear {
	position: relative;
	margin-left: 15px;
}
.collection__mobile-active-clear::after {
	content: "";
	position: absolute;
	left: 0;
	bottom: 3px;
	width: 100%;
	height: 1px;
	background: currentColor;
}
.collection__mobile-active-filters-results {
	display: block;
	margin-top: 25px;
	margin-bottom: -5px;
}
.collection-drawer {
	display: flex;
	flex-direction: column;
	height: 100%;
}
.collection-drawer__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex: 1 0 auto;
	max-height: 64px;
	padding: 15px 15px 15px 25px;
	border-bottom: 1px solid #e1e3e4;
	z-index: 1;
}
.collection-drawer__header > div {
	display: flex;
	align-items: center;
}
.collection-drawer__close {
	margin-right: 16px;
	color: #1e2d7d;
}
.collection-drawer__close svg {
	display: block;
	width: 19px;
	height: 19px;
	margin-top: -1px;
}
.collection-drawer__title {
	margin-bottom: 0;
}
.collection-drawer__inner {
	flex: 1 1 auto;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	font-size: 16px;
}
.collection-drawer__section-title {
	margin: 0;
	padding: 4px 15px;
	text-transform: uppercase;
	font-size: 13px;
	font-weight: 500;
	background: #f3f5f6;
	border-bottom: 1px solid #e1e3e4;
}
.collection-drawer__footer {
	padding: 15px;
	box-shadow: 0 -2px 2px rgba(225, 227, 228, 0.65);
}
.collection-drawer__footer .button {
	font-size: 15px;
}
.product-list {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	overflow: hidden;
	width: 100%;
	border-top: 1px solid #e1e3e4;
	border-bottom: 1px solid #e1e3e4;
	z-index: 1;
}
.product-list .flickity-viewport {
	border-radius: 3px;
}
.product-list--scrollable {
	flex-wrap: nowrap;
}
.product-list--collection::before {
	display: none;
}
.product-item--vertical,
.product-item--vertical .product-item__info {
	display: flex;
	flex-direction: column;
}
.product-item--vertical .product-item__info {
	justify-content: space-between;
	flex: 1 0 auto;
}
.product-list__column {
	width: 100%;
}
@media screen and (max-width: 640px) {
	.product-list--scrollable::before,
	.product-list--scrollable::after {
		content: "";
		flex: 0 0 20px;
	}
	.product-list--scrollable .product-item {
		width: 62%;
	}
	.product-list--collage {
		border-width: 1px 0;
	}
	.product-list--collection .product-item--vertical {
		width: 50%;
	}
}
@media screen and (min-width: 641px) and (max-width: 999px) {
	.product-list--scrollable::before,
	.product-list--scrollable::after {
		content: "";
		flex: 0 0 40px;
	}
	.product-list--scrollable .product-item {
		width: 36%;
	}
}
@media screen and (min-width: 641px) {
	.product-list {
		background: #fff;
		border-radius: 3px;
		border: none;
	}
	.product-list--collage::before {
		position: absolute;
		content: "";
		width: 100%;
		height: calc(100% + 0px);
		border: 1px solid #e1e3e4;
		z-index: 1;
		pointer-events: none;
		border-radius: 3px;
	}
	.product-list__column {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		flex: 0 0 33.333333%;
	}
	.product-list__column > .product-item {
		flex: 1 0 auto;
	}
	.product-list__column--highlight .product-item__title {
		font-size: 1rem;
	}
	@supports (display: grid) {
		.product-list--collage {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(295px, 1fr));
		}
		.product-list__column {
			display: grid;
			grid-auto-rows: 1fr;
		}
		.product-list__column--shrink {
			grid-template-rows: minmax(50%, -webkit-min-content);
			grid-template-rows: minmax(50%, min-content);
		}
	}
}
@media screen and (max-width: 999px) {
	.product-list--scrollable {
		overflow: visible;
		border: none;
		background: transparent;
	}
	.product-list--scrollable .product-item {
		border: 1px solid #e1e3e4;
	}
	.product-list--scrollable .product-item + .product-item {
		border-left: none;
	}
	.product-list--scrollable .product-item:first-child {
		border-radius: 3px 0 0 3px;
	}
	.product-list--scrollable .product-item:last-child {
		border-radius: 0 3px 3px 0;
	}
	.product-list--collection {
		border: none;
	}
}
@media screen and (min-width: 1000px) {
	.sidenav {
		width: 41%;
		position: sticky;
		z-index: 1;
		top: 15%;
		left: 10px;
		background: white;
		padding: 8px 0;
		float: left;
	}

	.sidenav a:hover {
		color: #064579;
	}

	.product-sticky {
		margin-left: 41%; /* Same width as the sidebar + left position in px */
		padding: 0px 10px;
	}

	.product-sticky .card_separator {
		margin: 2px !important;
	}

	.product-sticky .table tbody tr {
		border: 0px !important;
	}

	.product-sticky .table tbody td {
		border: 0px !important;
	}

	.mb-0 {
		margin-bottom: 0px;
	}

	.mb-1 {
		margin-bottom: 10px;
	}

	.mb-2 {
		margin-bottom: 20px;
	}

	.mb-3 {
		margin-bottom: 30px;
	}

	.mb-4 {
		margin-bottom: 40px;
	}

	.rating-badge-normal {
		background-color: #1b9704;
		padding: 5px;
		margin-right: 5px;
		border-radius: 5px;
		font-size: 15px;
		color: #fff;
		font-weight: 100;
		height: 30px;
	}

	.rating-badge-low {
		background-color: #df2d05;
		padding: 5px;
		margin-right: 5px;
		border-radius: 5px;
		font-size: 15px;
		color: #fff;
		font-weight: 100;
		height: 30px;
	}

	.rating-badge-normal i,
	.rating-badge-low i {
		font-size: 10px;
		padding-top: -10px;
		font-weight: 100;
	}

	.pb-0 {
		padding-bottom: 0px !important;
	}

	.pb-1 {
		padding-bottom: 10px !important;
	}

	.pb-2 {
		padding-bottom: 20px !important;
	}

	.pb-3 {
		padding-bottom: 30px !important;
	}

	.pt-0 {
		padding-top: 0px;
	}
	.pt-1 {
		padding-top: 10px;
	}

	.border-t-0 {
		border-top: 0px !important;
	}
	.product-list::before {
		position: absolute;
		content: "";
		width: 100%;
		height: calc(100% + 0px);
		border: 1px solid #e1e3e4;
		z-index: 1;
		pointer-events: none;
		border-radius: 3px;
	}
	.product-list--scrollable::after {
		content: "flickity";
		display: none;
	}
	.product-list--scrollable .product-item {
		min-height: 100%;
	}
	.product-list--stackable {
		flex-wrap: wrap;
	}
	.product-list__column {
		flex-basis: 25%;
	}
}
@media screen and (min-width: 1440px) {
	@supports (display: grid) {
		.product-list--collage {
			grid-template-columns: repeat(2, 1fr 1.05fr);
		}
	}
}
.featured-collection {
	padding-top: 25px;
	background-repeat: no-repeat;
	background-position: bottom 0 left -15px;
	background-size: 500px auto;
}
.featured-collection__header {
	padding: 0 20px 40px 20px;
}
.featured-collection__title {
	margin-bottom: 8px;
	color: inherit;
}
.featured-collection__cta {
	margin-top: 8px;
}
.featured-collection__image-wrapper {
	margin-top: 25px;
}
.featured-collection .product-list {
	margin-bottom: 20px;
	border-radius: 2px;
}
.featured-collection .product-item {
	border: none;
	border-right: 1px solid #e1e3e4;
}
.featured-collection .product-item::after {
	display: none;
}
.featured-collection .product-item:last-child {
	border-right: 0;
}
@media screen and (max-width: 640px) {
	.featured-collection,
	.featured-collection__header {
		background-image: none !important;
	}
}
@media screen and (min-width: 641px) and (max-width: 999px) {
	.featured-collection {
		margin: 0 -40px;
		background-image: none !important;
	}
	.featured-collection__header {
		padding-left: 40px;
		padding-right: 40px;
		background-repeat: no-repeat;
		background-position: bottom 0 right -15px;
		background-size: 430px auto;
	}
	.featured-collection__image-wrapper {
		max-width: 400px;
		margin-left: auto;
		margin-right: auto;
	}
	.featured-collection .product-list {
		margin-bottom: 40px;
	}
}
@media screen and (min-width: 1000px) {
	.featured-collection {
		display: table;
		table-layout: fixed;
		width: 100%;
		padding: 6px 6px 6px 0;
		border-radius: 3px;
	}
	.featured-collection__header,
	.featured-collection__content {
		display: table-cell;
		vertical-align: top;
	}
	.featured-collection__header {
		width: 455px;
		padding: 20px 30px;
		background-image: none !important;
	}
	.featured-collection .product-list {
		margin-bottom: 0;
	}
	.featured-collection .product-list::before {
		display: none;
	}
	.featured-collection .flickity-viewport::after {
		content: "";
		position: absolute;
		height: 100%;
		width: 1px;
		background: #fff;
		right: 0;
	}
}
.collection-item {
	display: inline-block;
	width: 36vw;
	margin: 0 14px;
	vertical-align: top;
	white-space: normal;
}
.collection-item:first-child {
	margin-left: 20px;
}
.collection-item:last-child {
	margin-right: 20px;
}
.collection-item__image-wrapper {
	margin-bottom: 15px;
	overflow: hidden;
}
.collection-item__image-wrapper--rounded {
	position: relative;
	z-index: 0;
	overflow: hidden;
	border-radius: 100%;
}
.collection-item__image-wrapper img {
	-o-object-fit: cover;
	object-fit: cover;
	-o-object-position: center;
	object-position: center;
	-webkit-transform: scale(1.01);
	transform: scale(1.01);
	font-family: "object-fit: cover; object-position: center;";
	transition: opacity 0.2s ease-in-out,
		-webkit-transform 0.95s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: opacity 0.2s ease-in-out,
		transform 0.95s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: opacity 0.2s ease-in-out,
		transform 0.95s cubic-bezier(0.25, 0.46, 0.45, 0.94),
		-webkit-transform 0.95s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.collection-item__title {
	display: block;
	line-height: 1.4;
	text-align: center;
	transition: color 0.2s ease-in-out;
}
.collection-item__title svg {
	display: none;
}
@media screen and (min-width: 641px) and (max-width: 999px) {
	.collection-item {
		width: 190px;
	}
	.collection-item:first-child {
		margin-left: 40px;
	}
	.collection-item:last-child {
		margin-right: 40px;
	}
}
@media screen and (min-width: 641px) {
	.collection-item__title {
		-webkit-transform: translateX(14px);
		transform: translateX(14px);
		transition: -webkit-transform 0.35s cubic-bezier(0.645, 0.045, 0.355, 1);
		transition: transform 0.35s cubic-bezier(0.645, 0.045, 0.355, 1);
		transition: transform 0.35s cubic-bezier(0.645, 0.045, 0.355, 1),
			-webkit-transform 0.35s cubic-bezier(0.645, 0.045, 0.355, 1);
	}
	.collection-item__title svg {
		position: relative;
		display: inline-block;
		height: 14px;
		width: 14px;
		vertical-align: -2px;
		margin-left: 8px;
		right: -5px;
		opacity: 0;
		transition: all 0.35s cubic-bezier(0.645, 0.045, 0.355, 1);
	}
}
@media screen and (min-width: 1000px) {
	.collection-list {
		margin: 30px -14px 0 -14px;
		white-space: nowrap;
		overflow: hidden;
	}
	.collection-list::after {
		display: none;
		content: "flickity";
	}
	.collection-item {
		width: 20%;
		margin: 0 !important;
		padding: 0 14px;
	}
	.collection-list .flickity-prev-next-button {
		top: calc(50% - 25px - 12px - 0.5em);
	}
	.collection-list .flickity-prev-next-button.previous {
		left: -10px;
	}
	.collection-list .flickity-prev-next-button.next {
		right: -10px;
	}
}
@media screen and (min-width: 1280px) {
	.collection-item {
		width: 16.6666667%;
	}
}
@media (-moz-touch-enabled: 0), (hover: hover) {
	.collection-item:hover .collection-item__title {
		color: #00badb;
	}
	.collection-item:hover img {
		-webkit-transform: scale(1.07);
		transform: scale(1.07);
	}
	.collection-item:hover .collection-item__title {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
	.collection-item:hover .collection-item__title svg {
		opacity: 1;
		right: 0;
	}
}
.collection-list__section {
	margin-bottom: 40px;
}
@media screen and (min-width: 641px) {
	.collection-list__section {
		margin-bottom: 60px;
	}
}
.collection-list__item-wrapper {
	display: block;
	position: relative;
	border-radius: 3px;
	margin-bottom: 20px;
	overflow: hidden;
}
.collection-list__item-wrapper--overlay::before {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.2);
	z-index: 1;
}
.collection-list__item-image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center;
	transition: opacity 0.3s ease-in-out, -webkit-transform 8s linear !important;
	transition: transform 8s linear, opacity 0.3s ease-in-out !important;
	transition: transform 8s linear, opacity 0.3s ease-in-out,
		-webkit-transform 8s linear !important;
}
.collection-list__item-title {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	padding: 0 20px;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	text-align: center;
	color: #ffffff;
	z-index: 1;
	text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
}
@media screen and (min-width: 641px) {
	.collection-list__item-list {
		display: flex;
		flex-wrap: wrap;
		margin: -15px;
	}
	.collection-list__item-wrapper {
		width: calc(50% - 30px);
		margin: 15px;
	}
}
@media screen and (min-width: 1280px) {
	.collection-list__item-wrapper {
		width: calc(33.333333% - 30px);
	}
}
@media (-moz-touch-enabled: 0), (hover: hover) {
	.collection-list__item-wrapper:hover .collection-list__item-image {
		-webkit-transform: scale(1.4);
		transform: scale(1.4);
	}
}
.form__field {
	display: block;
	padding: 12px 12px;
	border-radius: 2px;
	border: 1px solid #d4d6d8;
	width: 100%;
	line-height: normal;
	height: 48px;
	color: #1e2d7d;
	-webkit-appearance: none;
	resize: none;
	font-size: 1rem;
	box-shadow: 0 1px rgba(212, 214, 216, 0.25) inset;
	transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
		color 0.2s ease-in-out;
}
.form__field::-webkit-input-placeholder {
	color: #677279;
}
.form__field::-moz-placeholder {
	color: #677279;
}
.form__field:-ms-input-placeholder {
	color: #677279;
}
.form__field::-ms-input-placeholder {
	color: #677279;
}
.form__field::placeholder {
	color: #677279;
}
.form__field:focus {
	border-color: #00badb;
	box-shadow: 0 0 0 1px #00badb;
	color: #1e2d7d;
	outline: none;
}
.form__field--small {
	height: 44px;
}
.form__field--large {
	height: 60px;
	padding: 20px 18px;
}
.form__field--textarea {
	height: auto;
	line-height: inherit;
	min-height: 48px;
	padding-top: 6px;
	padding-bottom: 6px;
}
.form__field--borderless {
	border: none;
}
.form__field--borderless:focus {
	box-shadow: none;
}
.form__label {
	display: block;
	margin-bottom: 6px;
	color: #1e2d7d;
	font-weight: 500;
}
.form__label--light {
	color: #677279;
}
.form__submit {
	display: block;
	margin-top: 20px;
}
.form__submit--tight {
	margin-top: 12px;
}
@media screen and (min-width: 641px) {
	.form__submit--centered {
		margin-left: auto;
		margin-right: auto;
	}
}
.form__input-wrapper {
	position: relative;
	width: 100%;
	margin-bottom: 12px;
}
.form__floating-label {
	position: absolute;
	left: 13px;
	top: 0;
	line-height: 34px;
	font-size: 1rem;
	color: #677279;
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transform-origin: left top;
	transform-origin: left top;
	transition: -webkit-transform 0.2s ease-in-out;
	transition: transform 0.2s ease-in-out;
	transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
	pointer-events: none;
}
.form__field:focus + .form__floating-label,
.form__field.is-filled + .form__floating-label,
.select-wrapper.is-filled + .form__floating-label {
	-webkit-transform: translateY(-6px) scale(0.8);
	transform: translateY(-5px) scale(0.8);
}
.form__input-wrapper--labelled .form__field {
	padding-top: 20px;
	padding-bottom: 3px;
}
.form__field--large + .form__floating-label {
	left: 18px;
	line-height: 60px;
}
.form__input-wrapper--labelled .form__field--large {
	padding-top: 20px;
	padding-bottom: 3px;
	line-height: 48px;
}
.form__input-row {
	display: flex;
	flex-direction: column;
}
.form__input-row .form__submit {
	margin-top: 0;
}
@media screen and (min-width: 641px) {
	.form__input-row {
		flex-direction: row;
		align-items: flex-end;
		margin: 0 -6px;
	}
	.form__input-row:not(:last-child) {
		margin-bottom: 12px;
	}
	.form__input-row > .form__input-wrapper {
		margin-bottom: 0;
	}
	.form__input-row > * {
		margin-left: 6px;
		margin-right: 6px;
	}
	.form__input-row > .form__submit {
		margin-top: 0;
	}
	.form__input-row > .form__submit {
		flex: 1 0 auto;
	}
}
.form__connected-item {
	display: flex;
	align-items: center;
	margin-bottom: 12px;
}
.form__connected-item .form__input-wrapper {
	margin-bottom: 0;
}
.form__connected-item .form__field {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-right: none;
}
.form__connected-item .form__field:focus {
	box-shadow: 0 0 0 1px #00badb inset;
}
.form__connected-item .form__connection {
	padding-left: 14px;
	padding-right: 14px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.form__connected-item .form__connection svg {
	width: 23px;
	height: 23px;
}
.form--main {
	max-width: 350px;
	margin: 60px auto;
	text-align: center;
}
.form__header,
.form__legend {
	margin-bottom: 30px;
}
.form__title {
	margin-bottom: 15px;
}
.form__secondary-action {
	margin-top: 32px;
	font-size: 14px;
}
.form__secondary-action > * {
	margin-bottom: 0;
}
@media screen and (min-width: 641px) {
	.form--main {
		margin-top: 110px;
		margin-bottom: 150px;
	}
}
select::-ms-expand {
	display: none;
}
.select-wrapper {
	position: relative;
	color: currentColor;
	line-height: inherit;
	vertical-align: middle;
}
.select-wrapper svg {
	position: absolute;
	pointer-events: none;
	vertical-align: baseline;
	fill: currentColor;
}
.select-wrapper select {
	-webkit-appearance: none;
	-moz-appearance: none;
	display: inline-block;
	color: inherit;
	cursor: pointer;
	border-radius: 0;
}
.select-wrapper select:focus::-ms-value {
	background: transparent;
	color: #677279;
}
.select-wrapper option {
	background: white;
	color: black;
}
.select-wrapper--transparent select {
	padding-right: 22px;
	background: transparent;
	border: none;
	font-size: inherit;
}
.select-wrapper--transparent svg {
	top: calc(50% - 4px);
	right: 4px;
	width: 12px;
	height: 8px;
}
.select-wrapper--primary {
	position: relative;
	background: #fff;
}
.select-wrapper--primary select {
	width: 100%;
	padding: 0 36px 0 12px;
	height: 48px;
	border: 1px solid #d4d6d8;
	border-radius: 2px;
	background: transparent;
	box-shadow: 0 -1px 1px rgba(212, 214, 216, 0.3) inset;
	font-size: 1rem;
	transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
.select-wrapper--primary select:focus {
	border-color: #00badb;
	box-shadow: 0 0 0 1px #00badb;
	outline: none;
}
.select-wrapper--primary select:valid {
	color: #1e2d7d;
}
.select-wrapper--primary svg {
	width: 12px;
	height: 14px;
	top: calc(50% - 7px);
	right: 16px;
	opacity: 0.7;
}
.select-wrapper--primary.select-wrapper--small select {
	height: 44px;
	padding-left: 12px;
	padding-bottom: 1px;
}
.form__input-wrapper--labelled .select-wrapper--primary.is-filled select {
	padding-top: 16px;
}
.checkbox-wrapper {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	margin: 8px 0;
}
.checkbox-wrapper svg {
	position: absolute;
	width: 12px;
	height: 12px;
	left: 2px;
	top: calc(50% - 6px);
	-webkit-transform: scale(0);
	transform: scale(0);
	transition: -webkit-transform 0.2s ease-in-out;
	transition: transform 0.2s ease-in-out;
	transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
	color: #fff;
}
.checkbox-wrapper ~ label {
	vertical-align: middle;
	cursor: pointer;
}
.checkbox {
	display: block;
	margin-right: 10px;
	width: 16px;
	height: 16px;
	border: 1px solid #d4d6d8;
	background: #fff;
	box-shadow: 0 1px rgba(212, 214, 216, 0.4);
	border-radius: 2px;
	-webkit-appearance: none;
	transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
		background 0.2s ease-in-out;
}
.checkbox:focus {
	border-color: #00badb;
	box-shadow: 0 0 0 1px #00badb;
	outline: none;
}
.checkbox:checked {
	background: #00badb;
	border-color: #00badb;
	box-shadow: none;
}
.checkbox:checked + svg {
	-webkit-transform: scale(1);
	transform: scale(1);
}
.checkbox.is-selected ~ label {
	color: #00badb;
	font-weight: 500;
}
.checkbox,
.checkbox ~ label {
	vertical-align: middle;
	cursor: pointer;
}
@media screen and (min-width: 1000px) {
	.checkbox-wrapper {
		margin: 12px 0;
	}
}
.gift-card__inner {
	padding: 30px 0;
}
.gift-card__main {
	position: relative;
	padding-top: 85px;
	text-align: center;
}
.gift-card__image {
	position: relative;
	width: 170px;
	height: 170px;
	top: -105px;
	border-radius: 100%;
	overflow: hidden;
	margin: 0 auto -75px auto;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}
.gift-card__title {
	margin-bottom: 0;
}
.gift-card__amount {
	margin-bottom: 18px;
	font-size: 23px;
}
.gift-card__alert {
	margin-top: 20px;
}
.gift-card__code-container {
	max-width: 255px;
	margin: 0 auto 28px auto;
}
.gift-card__code {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 6px 15px;
	width: 100%;
	border: 1px solid #e1e3e4;
	text-transform: uppercase;
	margin-bottom: 15px;
	border-radius: 3px;
	text-align: center;
}
.gift-card__expires-on {
	display: block;
	color: #008a00;
	font-weight: 500;
	font-size: 16px;
}
.gift-card__expires-on::before {
	display: inline-block;
	content: "";
	width: 8px;
	height: 8px;
	margin-right: 0.7em;
	border-radius: 100%;
	background: currentColor;
	vertical-align: baseline;
}
.gift-card__share {
	padding-top: 15px;
	padding-bottom: 20px;
}
.gift-card__wallet {
	display: block;
	margin-bottom: 30px;
	text-align: center;
}
.gift-card__qr img {
	margin: 0 auto;
}
.gift-card__shop {
	margin-top: 15px;
	min-width: 300px;
}
@media screen and (max-width: 640px) {
	.gift-card__print {
		width: 100%;
	}
	.gift-card__qr img {
		max-width: 120px;
	}
}
@media screen and (min-width: 641px) {
	.template-gift-card .header {
		padding-top: 30px;
		padding-bottom: 30px;
	}
	.gift-card__inner {
		display: flex;
		max-width: 920px;
		margin: 0 auto;
		padding: 70px 0 90px 0;
	}
	.gift-card__main {
		flex: 1 0 auto;
	}
	.gift-card__aside {
		flex: 0 0 255px;
		margin-top: 85px;
		margin-left: 30px;
	}
	.gift-card__image {
		width: 210px;
		height: 210px;
		top: -135px;
		margin-bottom: -80px;
	}
	.gift-card__amount {
		font-size: 25px;
	}
	.gift-card__code-container {
		display: flex;
		justify-content: center;
		max-width: none;
	}
	.gift-card__code {
		margin-bottom: 0;
		margin-right: 15px;
		max-width: 250px;
	}
	.gift-card__print {
		flex-basis: 150px;
	}
}
@media print {
	.gift-card__aside {
		display: none;
	}
}
.footer {
	padding: 10px 0 30px 0;
	border-top: 1px solid #e1e3e4;
}
.footer__block-list {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
}
.footer__title {
	position: relative;
	display: block;
	width: 100%;
	margin-bottom: 0.85em;
	text-align: left;
}
.footer__title[disabled] {
	cursor: text;
	-webkit-user-select: text;
	-moz-user-select: text;
	-ms-user-select: text;
	user-select: text;
}
.footer__linklist {
	margin-top: -8px;
	margin-bottom: -5px;
	line-height: 1.4;
}
.footer__link-item {
	display: block;
	padding: 5px 0;
}
.footer__newsletter-form {
	margin-top: 20px;
}
.footer__aside {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
	font-size: 13px;
}
.footer__aside-item {
	margin-top: 34px;
}
.footer__aside-item--copyright {
	margin-top: 45px;
	order: 1;
}
.footer__aside-title {
	margin-bottom: 0.8em;
}
@media screen and (max-width: 640px) {
	.footer__block-item {
		margin-right: -20px;
		padding-right: 20px;
		border-bottom: 1px solid #e1e3e4;
	}
	.footer__block-item--newsletter {
		border-bottom: none;
		order: 1;
	}
	.footer__title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 0;
		padding: 19px 0;
	}
	.footer__collapsible {
		height: 0;
		overflow: hidden;
		transition: 0.3s ease-in-out;
	}
	.footer__collapsible-content {
		padding: 0 20px 25px 0;
	}
	.footer__block-item .rte {
		margin-top: -0.425em;
	}
}
@media screen and (min-width: 641px) {
	.footer {
		padding: 0;
		border-top: none;
	}
	.footer__wrapper {
		padding: 50px 0;
		border-top: 1px solid #e1e3e4;
	}
	.footer__block-list {
		flex-direction: row;
		margin: -20px -35px;
	}
	.footer__block-item {
		flex: 0 0 50%;
		padding: 20px 35px;
	}
	.footer__block-item--newsletter {
		max-width: 350px;
	}
	.footer__block-item .plus-button {
		display: none;
	}
	.footer__linklist {
		margin-top: -2px;
		margin-bottom: 0;
	}
	.footer__aside {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: 75px;
		font-size: 14px;
	}
	.footer__aside-item {
		margin-top: 0;
	}
	.footer__aside-item--copyright {
		width: 100%;
		text-align: center;
	}
	.footer__aside-item--copyright:not(:only-child) {
		margin-top: 75px;
	}
}
@media screen and (min-width: 1000px) {
	.footer__block-item {
		flex: 1 0 auto;
	}
	.footer__block-item--text {
		flex-basis: 300px;
	}
	.footer__block-item--newsletter {
		flex-basis: 350px;
	}
	.footer__block-item--links {
		max-width: 300px;
	}
	.footer__aside {
		align-items: flex-start;
		margin-top: 60px;
	}
	.footer__aside-item--copyright {
		order: 0;
		align-self: flex-end;
		margin-top: 0 !important;
		margin-bottom: -8px;
		width: auto;
		text-align: left;
	}
}
#shopify-section-header::after {
	content: "";
	display: table;
	clear: both;
}
.header {
	position: relative;
	padding: 13px 0;
	background: #1e2d7d;
	color: #fff;
	transition: margin-bottom 0.25s ease-in-out;
}
.header__inner {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}
.header__inner--centered {
	justify-content: center;
}
.header__logo-link {
	display: block;
}
.header__logo {
	display: inline-block;
	vertical-align: middle;
	margin-bottom: 0;
}
.header__logo-image {
	display: block;
	/* vertical-align: middle; */
	max-height: 130px;
}
.header__mobile-nav {
	display: inline-block;
	margin-right: 20px;
}
.header__desktop-nav {
	position: relative;
	margin-right: 22px;
}
.header__mobile-nav-toggle {
	top: -1px;
}
.header__desktop-nav-toggle {
	height: 44px;
	padding: 0 15px;
	border: 1px solid rgba(163, 175, 239, 0.3);
	border-radius: 3px;
	font-weight: 500;
}
.header__desktop-nav-toggle > span {
	display: flex;
	align-items: center;
}
.header__desktop-nav-text {
	position: relative;
	top: 1px;
}
.header__search-bar-wrapper {
	flex: 1 0 auto;
}
.header .icon--account,
.header .icon--hamburger,
.header .icon--hamburger-mobile,
.header .icon--search,
.header .icon--cart,
.header .icon--close {
	display: block;
}
.header .icon--account {
	width: 20px;
	height: 22px;
}
.header .icon--hamburger {
	width: 20px;
	height: 14px;
}
.header .icon--hamburger-mobile {
	width: 20px;
	height: 16px;
}
.header .icon--cart {
	width: 27px;
	height: 24px;
}
.header .icon--search {
	width: 22px;
	height: 22px;
	margin: 0 auto;
}
.header .icon--close {
	width: 19px;
	height: 19px;
}
@media screen and (max-width: 640px) {
	.header {
		display: flex;
		align-items: center;
		min-height: 64px;
	}
	.header > .container {
		width: 100%;
	}
	.header--search-expanded {
		margin-bottom: 59px;
	}
}
@media screen and (min-width: 641px) {
	.header {
		padding: 20px 0;
	}
	.header__inner {
		flex-wrap: nowrap;
	}
	.header__logo {
		margin-right: 35px;
	}
}
@media screen and (min-width: 1000px) {
	.icon--hamburger {
		margin-right: 15px;
	}
}
@media screen and (min-width: 1280px) {
	.header__logo {
		margin-right: 40px;
	}
	.header__desktop-nav {
		margin-right: 30px;
	}
	.header__desktop-nav-toggle {
		font-size: 17px;
	}
}
.header__action-list {
	display: flex;
	align-items: center;
	margin-left: auto;
}
.header__action-item {
	padding: 0 9px;
}
.header__action-item:last-child {
	padding-right: 4px;
}
.header__action-item-link {
	display: block;
	font-weight: 700;
	font-size: 17px;
	text-transform: capitalize;
}

.mega-menu__linklist .mega-menu__item a:hover {
	color: #000;
}
.header__action-item-link--small {
	font-size: 14px;
	font-weight: normal;
}
.header__action-item-title {
	display: block;
	color: #a3afef;
	font-size: 16px;
	text-transform: capitalize;
	font-weight: 700;
}
.header__action-item--currency,
.header__action-item--locale {
	margin-right: 25px;
	padding: 0 24px;
}
.header__currency-selector {
	font-weight: 500;
	font-size: 17px;
}
.header__action-item .icon--arrow-bottom {
	width: 12px;
	height: 8px;
	margin-left: 4px;
}
.header__action-item .icon--lock-2 {
	width: 12px;
	height: 15px;
}
.header__action-item .icon--lock-2 + span {
	margin-left: 14px;
}
.header__cart-icon {
	margin-left: -2px;
}
.header__cart-count {
	display: inline-flex;

	position: absolute;
	right: -14px;
	top: -14px;
	align-items: center;
	justify-content: center;
	height: 25px;
	min-width: 25px;
	padding: 4px;
	font-size: 15px;
	font-weight: 800;
	text-align: center;
	background: #00badb;
	color: #fff;
	border-radius: 1.75em;
	transition: transform 0.35s ease-in-out;
	will-change: transform;
	transform: scale(1.01);
	z-index: 1;
}
.header__cart-toggle[aria-expanded="true"] .header__cart-count,
.header__cart-toggle:hover .header__cart-count {
	-webkit-transform: scale(1.2);
	transform: scale(1.2);
}
@media screen and (max-width: 640px) {
	.header__cart-icon .icon-state__secondary.icon-state__secondary {
		left: 8px;
	}
	.header__action-item .icon--lock-2 {
		display: block;
	}
	.header__search-bar-wrapper {
		visibility: hidden;
		position: absolute;
		padding: 0 20px 0 20px;
		width: 100%;
		bottom: 0;
		left: 0;
		background: #1e2d7d;
		z-index: -1;
		transition: visibility 0.25s ease-in-out, margin-bottom 0.25s ease-in-out;
	}
	.header__search-bar-wrapper.is-visible {
		visibility: visible;
		margin-bottom: -44px;
	}
	.header__search-bar-wrapper.is-fixed {
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		z-index: 10;
	}
}
@media screen and (min-width: 641px) {
	.header__action-list {
		padding-left: 28px !important;
	}
	.header__action-item,
	.header__action-item-content {
		position: relative;
	}
	.header__action-item-content {
		max-width: -webkit-max-content;
		max-width: -moz-max-content;
		max-width: max-content;
	}
	.header__action-item .icon--lock-2 {
		vertical-align: text-top;
	}
}
@media screen and (min-width: 1000px) {
	.header__action-list {
		padding-left: 5px;
	}
	.header__action-item:not(:last-child)::after {
		position: absolute;
		content: "";
		right: 0;
		top: calc(50% - 17px);
		background: rgba(163, 175, 239, 0.3);
		height: 34px;
		width: 1px;
	}
	.header__action-item--currency {
		margin-right: 20px;
	}
}
@media screen and (min-width: 1000px) and (max-width: 1279px) {
	.header__action-item:not(.header__action-item--currency):not(.header__action-item--locale)::after {
		display: none;
	}
	.header__action-item--currency + .header__action-item--locale {
		padding-left: 9px;
	}
	.header__action-item.hidden-tablet-and-up:first-child + .header__action-item {
		padding-left: 35px;
	}
}
@media screen and (min-width: 1280px) {
	.header__action-item {
		padding: 0 35px;
	}
	.header__action-item:last-child {
		padding-right: 0;
	}
	.header__action-item--currency,
	.header__action-item--locale {
		margin-right: 0;
	}
	.header__cart-icon {
		display: inline-block;
		margin-right: 22px;
		top: -1px;
	}
}
.search-bar {
	position: relative;
	min-width: 100%;
	padding-bottom: 15px;
	z-index: 1;
	font-size: 16px;
}
.search-bar__top {
	position: relative;
	display: flex;
	height: 44px;
	align-items: center;
	border-radius: 3px;
	background: #fff;
	color: #677279;
	z-index: 1;
}
@media screen and (min-width: 300px) {
	.search-bar__input-wrapper {
		width: 35%;
	}
	.search-bar__filter-label {
		width: 100%;
		margin-left: -15px;
	}
	.header__action-item {
		/* float: right; */
		margin-left: -40px;
	}
	.signup {
		display: block;
		width: 25px;
		font-size: 30px;
	}
	.header__action-item-title {
		display: none;
	}
	.dropdown-menu {
		width: 80px;
		position: absolute;

		/* left: 0px; */
		transform: translate3d(58px, 8px, 0px);
	}
}
@media screen and (min-width: 1024px) {
	.signup {
		display: none !important;
	}
	.header__action-item-title {
		display: block;
	}
}

.search-bar__input-wrapper {
	position: relative;
	flex: 1 0 auto;
	height: 100%;
}
.search-bar__input {
	height: 100%;
	width: 100%;
	padding: 5px 15px 1px 15px;
	border: none;
	background: transparent;
	line-height: normal;
	color: #1e2d7d;
	-webkit-appearance: none;
}
.search-bar__input::-webkit-input-placeholder {
	color: #677279;
	opacity: 1;
}
.search-bar__input::-moz-placeholder {
	color: #677279;
	opacity: 1;
}
.search-bar__input:-ms-input-placeholder {
	color: #677279;
	opacity: 1;
}
.search-bar__input::-ms-input-placeholder {
	color: #677279;
	opacity: 1;
}
.search-bar__input::placeholder {
	color: #677279;
	opacity: 1;
}
.search-bar__input::-ms-clear {
	display: none;
}
.search-bar__input:focus {
	outline: none;
}
.search-bar__input-clear {
	position: absolute;
	right: 15px;
	top: calc(50% - 9px);
	display: none;
	opacity: 0.5;
}
.search-bar__input-clear svg {
	display: block;
	width: 17px;
	height: 17px;
}
.search-bar__input.is-filled + .search-bar__input-clear {
	display: block;
}
.search-bar__filter {
	display: none;
	border-left: 1px solid rgba(163, 175, 239, 0.3);
}
.search-bar__filter-label {
	display: block;
	height: 44px;
	padding: 3px 10px 0 25px;
	line-height: 43px;
}
.search-bar__filter-label i {
	font-size: 13px;
}

.search-bar__filter-label svg {
	margin-left: 11px;
	width: 12px;
	height: 8px;
}
.search-bar__submit {
	flex: none;
	width: 50px;
	height: 100%;
	color: #fff;
	background: #00badb;
	border-radius: 0 3px 3px 0;
}
.search-bar__submit svg {
	position: relative;
}
.search-bar__submit .icon--search {
	width: 21px;
	height: 21px;
}
.search-bar__submit .icon--search-loader {
	display: none;
	width: 24px;
	height: 24px;
	margin: 0 auto;
}
.search-bar__inner {
	position: absolute;
	visibility: hidden;
	left: -20px;
	top: calc(100% + 8px);
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	color: #677279;
	background: #fff;
}
@supports (--css: variables) {
	.search-bar__inner {
		height: calc(100vh - var(--header-height) - 25px);
	}
}
.search-bar__inner-animation {
	height: 100%;
}
.search-bar__results,
.search-bar__menu-wrapper {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	background: #fff;
	box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.1);
	visibility: hidden;
	transition: visibility 0.1s ease-in-out;
}
.search-bar__results[aria-hidden="false"],
.search-bar__menu-wrapper[aria-hidden="false"] {
	visibility: visible;
}
.search-bar.is-expanded .search-bar__inner-animation {
	visibility: visible;
}
.search-bar__empty-state {
	text-align: center;
	padding: 50px 0;
}
.search-bar__result-products ~ .search-bar__empty-state {
	display: none;
}
.search-bar__menu-title {
	margin-bottom: 4px;
	font-size: 17px;
}
.search-bar__menu-wrapper {
	padding: 18px 0 12px 0;
	height: 100%;
	overflow: auto;
	-ms-scroll-chaining: none;
	overscroll-behavior: none;
}
.search-bar__menu-title,
.search-bar__menu-link {
	padding-left: 20px;
	padding-right: 20px;
}
.search-bar__menu-link {
	display: block;
	padding-top: 6px;
	padding-bottom: 6px;
	transition: color 0.15s ease-in-out, background 0.15s ease-in-out;
}
.search-bar__menu-link:hover,
.search-bar__menu-link:focus {
	background: rgba(0, 186, 219, 0.08);
	color: #00badb;
	outline: none;
}
.search-bar__results .skeleton-container {
	display: none;
}
.search-bar__results {
	font-size: 15px;
	height: 100%;
	overflow: auto;
	-ms-scroll-chaining: none;
	overscroll-behavior: none;
}
.search-bar__result-category {
	margin: 0;
	padding: 4px 20px;
	text-transform: uppercase;
	font-size: 13px;
	font-weight: 500;
	background: #f3f5f6;
	box-shadow: 0 1px #e1e3e4, 0 -1px #e1e3e4;
}
.search-bar__result-item {
	display: flex;
	align-items: center;
	padding: 15px 20px;
	transition: background 0.2s ease-in-out;
}
.search-bar__result-item:not(.search-bar__result-item--skeleton):hover,
.search-bar__result-item:not(.search-bar__result-item--skeleton):focus {
	background: rgba(0, 186, 219, 0.08);
	outline: none;
}
.search-bar__result-item--skeleton {
	max-width: 300px;
}
.search-bar__image-container {
	flex: none;
	width: 60px;
	margin-right: 20px;
}
.search-bar__image-container img {
	color: transparent;
}
.search-bar__item-info {
	flex: 1 0 0;
}
.search-bar__item-title {
	margin-bottom: 0;
	line-height: 1.4;
}
.search-bar__item-price {
	color: #00badb;
}
.search-bar__result-link {
	display: block;
	padding: 5px 20px;
	transition: color 0.2s ease-in-out, background 0.2s ease-in-out;
}
.search-bar__result-link:hover,
.search-bar__result-link:focus {
	color: #00badb;
	background: rgba(0, 186, 219, 0.08);
	outline: none;
}
.search-bar__view-all {
	display: block;
	padding: 10px;
	text-align: center;
	font-weight: 500;
	color: #00badb;
	transition: background 0.2s ease-in-out;
}
.search-bar__view-all:hover,
.search-bar__view-all:focus {
	background: rgba(0, 186, 219, 0.08);
	outline: none;
}
.search-bar__view-all svg {
	width: 6px;
	height: 10px;
	margin-left: 12px;
}
.search-bar__view-all path {
	stroke-width: 2.5px;
}
.search-bar.is-loading .search-bar__results-inner,
.search-bar.is-loading .search-bar__submit .icon--search {
	display: none;
}
.search-bar.is-loading .skeleton-container {
	display: block;
}
.search-bar.is-loading .search-bar__submit .icon--search-loader {
	display: block;
}
@media screen and (max-width: 640px) {
	.search-bar {
		opacity: 0;
		transition: opacity 0.2s ease-in-out;
	}
	.search-bar__inner {
		max-height: 0;
		height: calc(100vh - 44px - 18px);
		top: 100%;
	}
	.search-bar__top-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.search-bar__top {
		flex-grow: 1;
	}
	.search-bar__close-button {
		width: 0;
		overflow: hidden;
		font-size: 17px;
		opacity: 0;
		transition: width 0.25s ease-in-out, opacity 0.25s ease-in-out;
	}
	.search-bar__close-text {
		padding-left: 22px;
	}
	.header__search-bar-wrapper.is-visible .search-bar {
		opacity: 1;
		transition-delay: 0.15s;
	}
	.header__search-bar-wrapper.is-fixed .search-bar {
		padding: 9px 0;
	}
	.header__search-bar-wrapper.is-fixed .search-bar__inner {
		max-height: none;
		visibility: visible;
	}
	.header__search-bar-wrapper.is-fixed .search-bar__inner-animation {
		transition: none;
	}
	.header__search-bar-wrapper.is-fixed .search-bar__close-button {
		opacity: 1;
	}
}
@media screen and (min-width: 641px) {
	.search-bar {
		margin-top: 0;
		padding-bottom: 0;
		min-width: 0;
	}
	.search-bar__inner {
		width: calc(100% - 40px);
		top: 100%;
		left: -5px;
	}
	@supports (--css: variables) {
		.search-bar__inner {
			height: calc(100vh - var(--header-height) - 40px);
		}
	}
	.search-bar__submit .icon--search {
		top: 1px;
	}
	.search-bar__result-category {
		padding-left: 15px;
	}
	.search-bar__results {
		max-height: 310px;
		height: auto;
	}
	@supports (--css: variables) {
		.search-bar__results {
			max-height: calc(100vh - var(--header-height) - 40px);
		}
	}
	.search-bar__menu-wrapper {
		max-height: 310px;
		height: auto;
	}
	.search-bar__results,
	.search-bar__menu-wrapper {
		left: 5px;
		width: calc(100% - 10px);
		border-radius: 0 0 3px 3px;
	}
	.search-bar__menu-title,
	.search-bar__menu-link {
		padding-left: 25px;
		padding-right: 25px;
	}
	.search-bar.is-expanded .search-bar__top {
		border-bottom-left-radius: 0;
		box-shadow: 0 -1px #e1e3e4 inset;
	}
}
@media screen and (min-width: 1280px) {
	.search-bar__filter {
		position: relative;
		display: block;
		height: 100%;
	}
	.search-bar__filter select {
		position: absolute;
		opacity: 0;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		-webkit-appearance: none;
	}
}
.instagram-wrapper {
	margin: 25px 0 35px 0;
}
.instafeed {
	margin: -9px -20px;
}
.instafeed::after {
	display: none;
	content: "flickity";
}
.instagram-item {
	position: relative;
	display: block;
	width: 56%;
	margin: 9px;
}
.instagram-item__image {
	background-size: cover;
	background-position: center;
}
.instagram-item__overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 18px 28px;
	background: #1e2d7d;
	color: #fff;
	text-align: left;
	opacity: 0;
	transition: opacity 0.2s ease-in-out;
}
.instagram-item__like-count {
	font-size: 14px;
	font-weight: 500;
}
.instagram-item__like-count svg {
	margin-right: 8px;
	width: 17px;
	height: 15px;
	vertical-align: sub;
}
.instagram-item__caption {
	position: relative;
	height: 5.625em;
	margin: 25px 0;
	overflow: hidden;
	word-break: break-word;
	font-size: 15px;
}
.instagram-item__caption::after {
	position: absolute;
	content: "";
	right: 0;
	bottom: 0;
	width: 70%;
	height: 1.6em;
	text-align: right;
	background: linear-gradient(to right, rgba(30, 45, 125, 0), #1e2d7d 50%);
}
.instagram-item__date {
	position: absolute;
	bottom: 18px;
	color: inherit;
	font-size: 14px;
}
@media (-moz-touch-enabled: 0), (hover: hover) {
	.instagram-item:hover .instagram-item__overlay {
		opacity: 1;
	}
}
@media screen and (min-width: 641px) {
	.instagram-wrapper {
		margin: 30px 0 40px 0;
	}
	.instafeed {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin: -15px;
	}
	.instafeed::after {
		content: "";
	}
	.instagram-item {
		margin: 15px;
		flex: 0 0 calc(33.33333% - 30px);
		width: auto;
	}
}
@media screen and (min-width: 1280px) {
	.instagram-item {
		flex-basis: calc(20% - 30px);
	}
	.instagram-item:nth-child(6) {
		display: none;
	}
}
@media screen and (min-width: 1440px) {
	.instagram-item__caption {
		height: 7.5em;
	}
}
.map {
	padding: 0 20px;
	background: #fff;
	border-radius: 3px;
	border: 1px solid #e1e3e4;
}
.map__store-item:not(:last-child) {
	border-bottom: 1px solid #e1e3e4;
}
.map__store-name {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	padding: 15px 0;
}
.map__store-name svg {
	width: 12px;
	height: 8px;
}
.map__icon-container {
	-webkit-transform: rotateZ(0deg);
	transform: rotateZ(0deg);
	transition: -webkit-transform 0.2s ease-in-out;
	transition: transform 0.2s ease-in-out;
	transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
.map__store-name[aria-expanded="true"] .map__icon-container {
	-webkit-transform: rotateZ(180deg);
	transform: rotateZ(180deg);
}
.map__store-collapsible {
	display: block;
	height: 0;
	overflow: hidden;
	transition: height 0.25s ease-in-out;
}
.map__store-inner {
	margin-top: -0.425em;
}
.map__store-address {
	margin-bottom: 15px;
}
.map__store-address > p {
	margin-bottom: 0 !important;
}
.map__store-hours {
	margin-bottom: 22px;
	font-size: 14px;
}
.map__direction-link,
.map__map-container {
	margin-bottom: 30px;
}
.map__map-container {
	position: relative;
	height: 160px;
	background-size: cover;
	background-position: center;
	overflow: hidden;
}
.map__gmap {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}
@media screen and (min-width: 641px) {
	.map__map-container {
		height: 300px;
	}
}
@media screen and (min-width: 1000px) {
	.map {
		display: flex;
		height: 405px;
		padding: 0;
	}
	.map__store-list {
		width: 50%;
		max-width: 580px;
		border-right: 1px solid #e1e3e4;
	}
	.map__map-container {
		height: 100%;
		flex: 1 0 auto;
		margin: 0;
	}
	.map__store-list {
		padding: 0 40px 0 30px;
		overflow: auto;
	}
	.map__store-name {
		padding: 20px 0;
	}
	.map__store-address {
		margin-bottom: 22px;
	}
}
.plyr.plyr--full-ui.plyr--video {
	color: #677279;
	background-color: transparent;
}
.plyr.plyr--full-ui.plyr--video .plyr__video-wrapper {
	background-color: transparent;
}
.plyr.plyr--full-ui.plyr--video:-webkit-full-screen
	.plyr__video-wrapper
	.plyr.plyr--full-ui.plyr--video:-webkit-full-screen
	.plyr__poster {
	background-color: #000;
}
.plyr.plyr--full-ui.plyr--video:-ms-fullscreen
	.plyr__video-wrapper
	.plyr.plyr--full-ui.plyr--video:-ms-fullscreen
	.plyr__poster {
	background-color: #000;
}
.plyr.plyr--full-ui.plyr--video:fullscreen
	.plyr__video-wrapper
	.plyr.plyr--full-ui.plyr--video:fullscreen
	.plyr__poster {
	background-color: #000;
}
.plyr.plyr--full-ui.plyr--video:-webkit-full-screen .plyr__video-wrapper,
.plyr.plyr--full-ui.plyr--video:-webkit-full-screen .plyr__poster {
	background-color: #000;
}
.plyr.plyr--full-ui.plyr--video:-moz-full-screen .plyr__video-wrapper,
.plyr.plyr--full-ui.plyr--video:-moz-full-screen .plyr__poster {
	background-color: #000;
}
.plyr.plyr--full-ui.plyr--video:-ms-fullscreen .plyr__video-wrapper,
.plyr.plyr--full-ui.plyr--video:-ms-fullscreen .plyr__poster {
	background-color: #000;
}
.plyr.plyr--full-ui.plyr--video .plyr--fullscreen-fallback .plyr__video-wrapper,
.plyr.plyr--full-ui.plyr--video .plyr--fullscreen-fallback .plyr__poster {
	background-color: #000;
}
.plyr.plyr--full-ui.plyr--video .plyr__controls {
	background-color: #fff;
	border-color: rgba(103, 114, 121, 0.05);
}
.plyr.plyr--full-ui.plyr--video .plyr__control.plyr__control--overlaid {
	background-color: #fff;
	border-color: rgba(103, 114, 121, 0.05);
}
.plyr.plyr--full-ui.plyr--video
	.plyr__control.plyr__control--overlaid.plyr__tab-focus,
.plyr.plyr--full-ui.plyr--video .plyr__control.plyr__control--overlaid:hover {
	color: rgba(103, 114, 121, 0.55);
}
.plyr.plyr--full-ui.plyr--video
	.plyr__progress
	input[type="range"]::-moz-range-thumb {
	box-shadow: 2px 0 0 0 #fff;
}
.plyr.plyr--full-ui.plyr--video .plyr__progress input[type="range"]::-ms-thumb {
	box-shadow: 2px 0 0 0 #fff;
}
.plyr.plyr--full-ui.plyr--video
	.plyr__progress
	input[type="range"]::-webkit-slider-thumb {
	box-shadow: 2px 0 0 0 #fff;
}
.plyr.plyr--full-ui.plyr--video
	.plyr__progress
	input[type="range"]::-webkit-slider-runnable-track {
	background-image: linear-gradient(
		to right,
		currentColor var(--value, 0),
		rgba(103, 114, 121, 0.6) var(--value, 0)
	);
}
.plyr.plyr--full-ui.plyr--video
	.plyr__progress
	input[type="range"]::-moz-range-track {
	background-color: rgba(103, 114, 121, 0.6);
}
.plyr.plyr--full-ui.plyr--video
	.plyr__progress
	input[type="range"]::-ms-fill-upper {
	background-color: rgba(103, 114, 121, 0.6);
}
.plyr.plyr--full-ui.plyr--video
	.plyr__progress
	input[type="range"].plyr__tab-focus::-webkit-slider-runnable-track {
	box-shadow: 0 0 0 4px rgba(103, 114, 121, 0.25);
}
.plyr.plyr--full-ui.plyr--video
	.plyr__progress
	input[type="range"].plyr__tab-focus::-moz-range-track {
	box-shadow: 0 0 0 4px rgba(103, 114, 121, 0.25);
}
.plyr.plyr--full-ui.plyr--video
	.plyr__progress
	input[type="range"].plyr__tab-focus::-ms-track {
	box-shadow: 0 0 0 4px rgba(103, 114, 121, 0.25);
}
.plyr.plyr--full-ui.plyr--video
	.plyr__progress
	input[type="range"]:active::-moz-range-thumb {
	box-shadow: 0 0 0 3px rgba(103, 114, 121, 0.25);
}
.plyr.plyr--full-ui.plyr--video
	.plyr__progress
	input[type="range"]:active::-ms-thumb {
	box-shadow: 0 0 0 3px rgba(103, 114, 121, 0.25);
}
.plyr.plyr--full-ui.plyr--video
	.plyr__progress
	input[type="range"]:active::-webkit-slider-thumb {
	box-shadow: 0 0 0 3px rgba(103, 114, 121, 0.25);
}
.plyr.plyr--full-ui.plyr--video .plyr__progress .plyr__tooltip {
	background-color: #677279;
	color: #fff;
}
.plyr.plyr--full-ui.plyr--video .plyr__progress .plyr__tooltip::before {
	border-top-color: #677279;
}
.plyr.plyr--full-ui.plyr--video.plyr--loading .plyr__progress__buffer {
	background-image: linear-gradient(
		-45deg,
		rgba(103, 114, 121, 0.6) 25%,
		transparent 25%,
		transparent 50%,
		rgba(103, 114, 121, 0.6) 50%,
		rgba(103, 114, 121, 0.6) 75%,
		transparent 75%,
		transparent
	);
}
.plyr.plyr--full-ui.plyr--video .plyr__volume input[type="range"] {
	color: #fff;
}
.plyr.plyr--full-ui.plyr--video
	.plyr__volume
	input[type="range"]::-moz-range-thumb {
	box-shadow: 2px 0 0 0 #677279;
}
.plyr.plyr--full-ui.plyr--video .plyr__volume input[type="range"]::-ms-thumb {
	box-shadow: 2px 0 0 0 #677279;
}
.plyr.plyr--full-ui.plyr--video
	.plyr__volume
	input[type="range"]::-webkit-slider-thumb {
	box-shadow: 2px 0 0 0 #677279;
}
.plyr.plyr--full-ui.plyr--video
	.plyr__volume
	input[type="range"]::-webkit-slider-runnable-track {
	background-image: linear-gradient(
		to right,
		currentColor var(--value, 0),
		rgba(255, 255, 255, 0.6) var(--value, 0)
	);
}
.plyr.plyr--full-ui.plyr--video
	.plyr__volume
	input[type="range"]::-moz-range-track,
.plyr.plyr--full-ui.plyr--video
	.plyr__volume
	input[type="range"]::-ms-fill-upper {
	background-color: rgba(255, 255, 255, 0.6);
}
.plyr.plyr--full-ui.plyr--video
	.plyr__volume
	input[type="range"].plyr__tab-focus::-webkit-slider-runnable-track {
	box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.25);
}
.plyr.plyr--full-ui.plyr--video
	.plyr__volume
	input[type="range"].plyr__tab-focus::-moz-range-track {
	box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.25);
}
.plyr.plyr--full-ui.plyr--video
	.plyr__volume
	input[type="range"].plyr__tab-focus::-ms-track {
	box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.25);
}
.plyr.plyr--full-ui.plyr--video
	.plyr__volume
	input[type="range"]:active::-moz-range-thumb {
	box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
}
.plyr.plyr--full-ui.plyr--video
	.plyr__volume
	input[type="range"]:active::-ms-thumb {
	box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
}
.plyr.plyr--full-ui.plyr--video
	.plyr__volume
	input[type="range"]:active::-webkit-slider-thumb {
	box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
}
.shopify-model-viewer-ui.shopify-model-viewer-ui
	.shopify-model-viewer-ui__controls-area {
	background: #fff;
	border-color: rgba(103, 114, 121, 0.05);
}
.shopify-model-viewer-ui.shopify-model-viewer-ui
	.shopify-model-viewer-ui__button {
	color: #677279;
}
.shopify-model-viewer-ui.shopify-model-viewer-ui
	.shopify-model-viewer-ui__button--control:hover {
	color: rgba(103, 114, 121, 0.55);
}
.shopify-model-viewer-ui.shopify-model-viewer-ui
	.shopify-model-viewer-ui__button--control:active,
.shopify-model-viewer-ui.shopify-model-viewer-ui
	.shopify-model-viewer-ui__button--control.focus-visible:focus {
	color: rgba(103, 114, 121, 0.55);
	background: rgba(103, 114, 121, 0.05);
}
.shopify-model-viewer-ui.shopify-model-viewer-ui
	.shopify-model-viewer-ui__button--control:not(:last-child):after {
	border-color: rgba(103, 114, 121, 0.05);
}
.shopify-model-viewer-ui.shopify-model-viewer-ui
	.shopify-model-viewer-ui__button--poster {
	background: #fff;
	border-color: rgba(103, 114, 121, 0.05);
}
.shopify-model-viewer-ui.shopify-model-viewer-ui
	.shopify-model-viewer-ui__button--poster:hover,
.shopify-model-viewer-ui.shopify-model-viewer-ui
	.shopify-model-viewer-ui__button--poster:focus {
	color: rgba(103, 114, 121, 0.55);
}
.video-wrapper {
	position: relative;
	cursor: pointer;
}
.video-wrapper::after {
	content: "";
	display: block;
	padding-bottom: 56.25%;
}
.video-wrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.video-wrapper--native::after {
	display: none;
}
.video-wrapper--native .plyr,
.video-wrapper--native video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.video-wrapper--native .plyr__video-wrapper,
.video-wrapper--native video {
	height: 100%;
}
.video-wrapper__image-wrapper {
	opacity: 1;
	visibility: visible;
	transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
}
.video-wrapper__image-wrapper,
.video-wrapper__image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.video-wrapper__image {
	-o-object-fit: cover;
	object-fit: cover;
	-o-object-position: center;
	object-position: center;
	font-family: "object-fit: cover; object-position: center";
}
.icon--play {
	-webkit-filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.4));
	filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.4));
}
.video-wrapper .icon--play {
	position: absolute;
	left: calc(50% - 40px);
	top: calc(50% - 40px);
	height: 80px;
	width: 80px;
	opacity: 1;
	transition: opacity 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
	transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
	transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out,
		-webkit-transform 0.2s ease-in-out;
}
@media (-moz-touch-enabled: 0), (hover: hover) {
	.video-wrapper:hover .icon--play {
		-webkit-transform: scale(1.15);
		transform: scale(1.15);
	}
}
.video-list {
	margin: 20px 0;
}
.video-list__item {
	max-width: 855px;
	margin: 0 auto;
}
.video-list__item[aria-hidden="true"] {
	display: none;
}
.video-list__item .video-wrapper iframe {
	visibility: hidden;
	height: 0;
	width: 0;
}
.video-list__item.is-playing .video-wrapper__image-wrapper {
	visibility: hidden;
	opacity: 0;
}
.video-list__item.is-playing iframe {
	visibility: visible;
	height: 100%;
	width: 100%;
}
.video-preview-list {
	padding: 5px 0;
}
.video-preview-list .icon--play {
	left: calc(50% - 20px);
	top: calc(50% - 20px);
	height: 40px;
	width: 40px;
}
.video-preview-list__item {
	display: inline-block;
	position: relative;
	width: 120px;
	vertical-align: top;
	cursor: pointer;
	margin: 0 10px;
}
.video-preview-list__item:first-child {
	margin-left: 20px;
}
.video-preview-list__item:last-child {
	margin-right: 20px;
}
.video-preview-list__item .video-wrapper {
	margin: 0;
}
.video-preview-list__item::after {
	position: absolute;
	content: "";
	width: calc(100% + 8px);
	height: calc(100% + 8px);
	top: -4px;
	left: -4px;
	border-radius: 3px;
	border: 2px solid transparent;
	pointer-events: none;
	transition: border 0.2s ease-in-out;
}
.video-preview-list__item.is-selected::after {
	border-color: #00badb;
}
.video-preview-list__item.is-selected .icon--play {
	opacity: 0;
}
@media screen and (min-width: 641px) {
	.video-preview-list__item:first-child {
		margin-left: 40px;
	}
	.video-preview-list__item:last-child {
		margin-right: 40px;
	}
}
@media screen and (min-width: 1000px) {
	.video-preview-list__item {
		width: 165px;
	}
}
.model-wrapper {
	position: relative;
	padding-bottom: 100%;
}
.model-wrapper .shopify-model-viewer-ui,
.model-wrapper model-viewer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.mosaic {
	margin: 0 -10px -20px -10px;
}
.mosaic__item {
	padding: 0 10px 20px 10px;
}
.mosaic__item > :first-child {
	min-height: 240px;
}
.mosaic--large .mosaic__item > :first-child {
	min-height: 280px;
}
@media screen and (max-width: 999px) {
	.mosaic--two-columns .mosaic__column:first-child .mosaic__item > :first-child,
	.mosaic--three-columns
		.mosaic__column:nth-child(2)
		.mosaic__item
		> :first-child {
		min-height: 340px;
	}
}
@media screen and (min-width: 641px) {
	.mosaic {
		margin: 0 -15px -30px -15px;
	}
	.mosaic__item {
		padding: 0 15px 30px 15px;
	}
}
@media screen and (min-width: 1000px) {
	.mosaic {
		display: flex;
	}
	.mosaic__column {
		display: flex;
		flex-direction: column;
		flex: 1 0 0%;
	}
	.mosaic__item {
		display: flex;
		flex-direction: column;
		flex: 1 0 auto;
	}
	.mosaic__item > :first-child {
		flex: 1 0 auto;
		height: 100%;
		min-height: 260px;
	}
	.mosaic--small .mosaic__item > :first-child {
		min-height: 225px;
	}
	.mosaic--large .mosaic__item > :first-child {
		min-height: 300px;
	}
	.mosaic--two-columns .mosaic__column:first-child {
		flex-grow: 1.38;
	}
	.mosaic--three-columns .mosaic__column:nth-child(2) {
		flex-grow: 1.38;
	}
}
.nav-dropdown {
	position: absolute;
	top: 100%;
	padding: 16px 0;
	list-style: none;
	background: #fff;
	color: #677279;
	border-radius: 0 0 3px 3px;
	border: 1px solid #e1e3e4;
	white-space: nowrap;
	visibility: hidden;
	opacity: 0;
	z-index: 1;
	transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
}
.nav-dropdown:not(.nav-dropdown--floating) {
	left: -15px;
}
.nav-dropdown--floating {
	margin-top: 7px;
	border: none;
	border-radius: 3px;
}
.nav-dropdown--floating::before {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-shadow: 0 5px 5px -2px rgba(0, 0, 0, 0.1),
		5px 0 5px -2px rgba(0, 0, 0, 0.1), 0 -5px 5px -2px rgba(0, 0, 0, 0.1);
	z-index: -1;
}
.nav-dropdown--glued:not(.nav-dropdown--inverse) {
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}
.nav-dropdown--glued.nav-dropdown--inverse {
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}
.nav-dropdown--fixed {
	min-height: 420px;
}
.nav-dropdown--restrict {
	max-height: calc(
		100vh - var(--header-height, 0px) - var(--announcement-bar-height, 0px) -
			40px
	);
	overflow: auto;
	-ms-scroll-chaining: none;
	overscroll-behavior: contain;
}
@supports (--css: variables) {
	.nav-bar .nav-dropdown .nav-dropdown {
		max-height: calc(100vh - var(--distance-to-top, 0px) - 40px);
		overflow: auto;
		-ms-scroll-chaining: none;
		overscroll-behavior: contain;
	}
}
.nav-dropdown[aria-hidden="false"] {
	visibility: visible;
	opacity: 1;
}
.nav-dropdown .icon--nav-triangle-borderless {
	position: absolute;
	bottom: 100%;
	left: 16px;
	width: 20px;
	height: 9px;
	z-index: 1;
}
.nav-dropdown .icon--nav-triangle-left {
	position: absolute;
	top: 23px;
	right: 100%;
	height: 20px;
	width: 9px;
	z-index: 1;
}
.nav-dropdown__item:not(.has-mega-menu) {
	position: relative;
}
.nav-dropdown__link {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 2px 60px 2px 20px;
	cursor: pointer;
}
.nav-dropdown__link svg {
	position: relative;
	width: 8px;
	height: 12px;
	top: 1px;
	margin: 0 -40px 0 65px;
}
.nav-dropdown__link[aria-expanded="true"] {
	color: #00badb;
}
.nav-dropdown .nav-dropdown {
	left: 100%;
	top: -17px;
	margin-top: 0;
	min-width: 0;
	border-radius: 0 3px 3px 0;
}
.nav-dropdown--inverse .nav-dropdown {
	right: 100%;
	left: auto;
	border-radius: 3px 0 0 3px;
}
.nav-dropdown--inverse .nav-dropdown .icon--nav-triangle-left {
	right: auto;
	left: 100%;
	-webkit-transform: rotateZ(180deg);
	transform: rotateZ(180deg);
}
.nav-dropdown .nav-dropdown--floating {
	top: -16px;
	border-left: 1px solid #e1e3e4;
}
/* .mega-menu {
	position: absolute;
	width: 100vw;
	left: 0;
	top: 100%;
	background: #fff;
	border-radius: 0 0 3px 3px;
	border-top: 1px solid #e1e3e4;
	border-bottom: 1px solid #e1e3e4;

	z-index: 9999;
	opacity: 100;
	transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
} */
.mega-menu {
	position: absolute;
	width: 100vw;
	left: 80px !important;
	right: 80px !important;
	top: 100%;
	background: #fff;
	border-radius: 0 0 3px 3px;
	border-top: 1px solid #e1e3e4;
	border-bottom: 1px solid #e1e3e4;

	z-index: 9999;
	opacity: 100;
	transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
}
.mega-menu--floating {
	max-width: 0;
}
.mega-menu[aria-hidden="false"] {
	visibility: visible;
	opacity: 1;
}
.mega-menu__inner {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding: 25px 0 0 0;
	max-height: 100%;
	min-height: 100%;
	overflow: auto;
	-ms-scroll-chaining: none;
	overscroll-behavior: none;

	width: auto;
	height: 65%;
}
/* .mega-menu__inner--center {
	justify-content: center;
} */
.mega-menu__promo {
	display: block;
	flex: 0 1 230px;
	width: 230px;
	min-width: 200px;
	margin: 0 0 30px 50px;
	white-space: normal;
	text-align: center;
}
.mega-menu__promo + .mega-menu__promo {
	margin-left: 20px;
}
.mega-menu__column-list {
	display: flex;
	flex: 1 1 auto;
	flex-wrap: wrap;
	margin: 0 -40px;
	min-width: 0;
}
@supports (display: grid) {
	.mega-menu__column-list {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
	}
}
.mega-menu__column {
	display: block;
	flex: 0 1 auto;
	margin: 0 30px 30px 30px;
}
.mega-menu:not(.mega-menu--floating) .mega-menu__column:first-child {
	margin-left: 0 !important;
}
.mega-menu:not(.mega-menu--floating) .mega-menu__column:last-child {
	margin-right: 0 !important;
}
.mega-menu__title {
	display: block;
	margin-bottom: 10px;
}
.mega-menu__linklist {
	list-style: none;
}
.mega-menu__link {
	display: block;
	padding: 5px 0 6px 0;
	line-height: 1.4;
}
.mega-menu__image-wrapper {
	margin: 5px 0 22px 0;
	border-radius: 3px;
	overflow: hidden;
}
.mega-menu__promo .aspect-ratio {
	-webkit-transform: scale(1.01);
	transform: scale(1.01);
	transition: -webkit-transform 0.6s ease-in-out;
	transition: transform 0.6s ease-in-out;
	transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}
.mega-menu__promo:hover .aspect-ratio {
	-webkit-transform: scale(1.06);
	transform: scale(1.06);
}
.mega-menu__promo:hover .mega-menu__image-heading {
	color: #00badb;
}
.mega-menu__image-heading {
	display: block;
	margin-bottom: 7px;
	transition: color 0.2s ease-in-out;
}
.mega-menu__image-text {
	line-height: 1.5;
}
.nav-bar .mega-menu {
	max-height: calc(
		100vh - var(--header-height, 0px) - var(--announcement-bar-height, 0px) -
			40px
	);
	overflow: auto;
	-ms-scroll-chaining: none;
	overscroll-behavior: contain;
}
@media screen and (min-width: 1280px) {
	.mega-menu__column {
		margin-left: 40px;
		margin-right: 40px;
	}
	.mega-menu__inner--large .mega-menu__column {
		margin-left: 50px;
		margin-right: 50px;
	}
	.mega-menu__promo {
		flex-basis: 290px;
		width: 290px;
	}
}
@media screen and (min-width: 1440px) {
	.mega-menu__inner--center .mega-menu__column {
		margin-left: 45px;
		margin-right: 45px;
	}
	/* .mega-menu__inner--large .mega-menu__column {
		margin-left: 60px;
		margin-right: 60px; 
		 width: 180px !important;
	} */
	.mega-menu__promo {
		min-width: 240px;
	}
}
.mega-menu--floating {
	width: 100vw;
	height: 100%;
	left: 100%;
	top: 0;
	border: none;
	border-radius: 0 3px 3px 0;
	border-left: 1px solid #e1e3e4;
}
.mega-menu--floating::before {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-shadow: 0 5px 5px -2px rgba(0, 0, 0, 0.1),
		5px 0 5px -2px rgba(0, 0, 0, 0.1), 0 -5px 5px -2px rgba(0, 0, 0, 0.1);
	z-index: -1;
}
.mega-menu--floating .mega-menu__inner {
	margin: 0;
	padding-right: 30px;
	padding-left: 25px;
}
.mega-menu--floating .mega-menu__title {
	margin-bottom: 5px;
}
.mega-menu--floating .mega-menu__column {
	display: block;
	flex: 0 0 170px;
	margin-right: 30px;
	margin-left: 30px;
	white-space: normal;
}
.mega-menu--floating .mega-menu__column-list {
	margin: 0 -30px;
}
.nav-bar {
	display: none;
}
@media screen and (min-width: 1000px) {
	.nav-bar {
		display: block;
		position: relative;
		left: 0;
		width: 100%;
		border-bottom: 1px solid #e1e3e4;
		background: #fff;
	}
	.nav-bar::after {
		content: "";
		position: absolute;
		height: 100%;
		width: 40px;
		right: 0;
		top: 0;
		background: linear-gradient(
			to right,
			rgba(255, 255, 255, 0.1),
			rgba(255, 255, 255, 0.6) 35%,
			#fff
		);
	}
	.nav-bar__item {
		/* position: relative; */
		display: inline-block;
		margin-right: 35px;
		padding: 17px 0;
	}
	.nav-bar__item--static {
		position: static;
	}
	.nav-bar__item > .nav-dropdown {
		min-width: calc(100% + 20px);
	}
	.nav-bar__link {
		display: block;
		cursor: pointer;
		color: #666 !important;
	}
	.nav-bar__link .icon--arrow-bottom {
		width: 12px;
		height: 8px;
		margin-left: 10px;
	}
	.nav-bar__link .icon--nav-triangle {
		opacity: 0;
		position: absolute;
		bottom: -1px;
		width: 20px;
		height: 9px;
		margin-left: -16px;
		z-index: 2;
		transition: 0.2s ease-in-out;
	}
	.nav-bar__link[aria-expanded="true"] {
		color: #00badb;
	}
	.nav-bar__link[aria-expanded="true"] .icon--nav-triangle {
		opacity: 1;
	}
}
.mobile-menu {
	position: absolute;
	width: 100vw;
	height: 100vh;
	left: 0;
	top: 100%;
	visibility: hidden;
	max-height: 0;
	z-index: 1;
	opacity: 0;
	color: #677279;
	font-size: 17px;
	-webkit-transform: scale(0.9);
	transform: scale(0.9);
	transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out,
		max-height 0s linear 0.25s, -webkit-transform 0.25s ease-in-out;
	transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out,
		visibility 0.25s ease-in-out, max-height 0s linear 0.25s;
	transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out,
		visibility 0.25s ease-in-out, max-height 0s linear 0.25s,
		-webkit-transform 0.25s ease-in-out;
	will-change: transform;
}
.mobile-menu .icon--arrow-right,
.mobile-menu .icon--arrow-left {
	width: 8px;
	height: 12px;
}
.mobile-menu[aria-hidden="false"] {
	opacity: 1;
	visibility: visible;
	-webkit-transform: scale(1);
	transform: scale(1);
	transition: opacity 0.4s cubic-bezier(0, 1, 0.4, 1), visibility 0.4s linear,
		-webkit-transform 0.4s cubic-bezier(0.18, 1.25, 0.4, 1);
	transition: opacity 0.4s cubic-bezier(0, 1, 0.4, 1),
		transform 0.4s cubic-bezier(0.18, 1.25, 0.4, 1), visibility 0.4s linear;
	transition: opacity 0.4s cubic-bezier(0, 1, 0.4, 1),
		transform 0.4s cubic-bezier(0.18, 1.25, 0.4, 1), visibility 0.4s linear,
		-webkit-transform 0.4s cubic-bezier(0.18, 1.25, 0.4, 1);
}
.mobile-menu__inner {
	position: relative;
	max-width: 100vw;
	height: 100%;
	overflow: hidden;
}
.mobile-menu .icon--nav-triangle-borderless {
	position: absolute;
	bottom: 100%;
	left: 20px;
	width: 18px;
	height: 8px;
	z-index: 1;
}
.mobile-menu__panel {
	position: relative;
	height: 100%;
	background: #fff;
	overflow-y: auto;
	overflow-x: hidden;
	-ms-scroll-chaining: none;
	overscroll-behavior: none;
	-webkit-overflow-scrolling: touch;
	transition: visibility 500ms cubic-bezier(0.23, 1, 0.32, 1),
		-webkit-transform 500ms cubic-bezier(0.23, 1, 0.32, 1);
	transition: transform 500ms cubic-bezier(0.23, 1, 0.32, 1),
		visibility 500ms cubic-bezier(0.23, 1, 0.32, 1);
	transition: transform 500ms cubic-bezier(0.23, 1, 0.32, 1),
		visibility 500ms cubic-bezier(0.23, 1, 0.32, 1),
		-webkit-transform 500ms cubic-bezier(0.23, 1, 0.32, 1);
}
.mobile-menu__panel.is-nested {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	visibility: hidden;
	z-index: 1;
	-webkit-transform: translateX(100%);
	transform: translateX(100%);
}
.mobile-menu__section {
	padding: 16px 20px;
}
.mobile-menu__section--loose {
	padding-top: 18px;
	padding-bottom: 18px;
}
.mobile-menu__section:not(:last-child) {
	border-bottom: 1px solid #e1e3e4;
}
.mobile-menu__section.is-sticky {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	background: #fff;
	z-index: 1;
}
.mobile-menu__nav {
	list-style: none;
}
.mobile-menu__nav-link {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 8px 0 7px 0;
}
.mobile-menu__back-button {
	display: block;
	width: 100%;
	text-align: left;
}
.mobile-menu__back-button svg {
	margin-right: 8px;
	vertical-align: -1px;
}
.mobile-menu__nav-list {
	margin-top: 2px;
}
.mobile-menu__nav-list-toggle {
	width: 100%;
	text-align: left;
	padding: 4px 0;
}
.mobile-menu__nav-list-toggle svg {
	width: 12px;
	height: 8px;
	margin-left: 12px;
	transition: -webkit-transform 0.3s ease-in-out;
	transition: transform 0.3s ease-in-out;
	transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}
.mobile-menu__nav-list-toggle[aria-expanded="true"] svg {
	-webkit-transform: rotateZ(180deg);
	transform: rotateZ(180deg);
}
.mobile-menu__nav-list-item {
	margin-bottom: 22px;
}
.mobile-menu__nav-collapsible {
	height: 0;
	overflow: hidden;
	transition: 0.3s ease-in-out;
}
.mobile-menu__nav-collapsible-content {
	margin-top: 4px;
}
.mobile-menu__promo {
	display: block;
	padding: 20px 0 25px 0;
	text-align: center;
}
.mobile-menu__image-wrapper {
	margin-bottom: 22px;
	border-radius: 3px;
	overflow: hidden;
}
.mobile-menu__image-heading {
	display: block;
	margin-bottom: 0;
	font-size: 19px;
}
.mobile-menu__image-text {
	font-size: 16px;
}
.mobile-menu__promo-list {
	display: flex;
	flex-flow: row nowrap;
	margin-left: -10px;
	margin-right: -10px;
}
.mobile-menu__promo-list .mobile-menu__promo-item {
	flex: 0 0 auto;
	width: 81%;
	white-space: normal;
	scroll-snap-align: center;
}
.mobile-menu__promo-list .mobile-menu__promo {
	margin-left: 10px;
	margin-right: 10px;
}
.scroller .mobile-menu__promo-list::before {
	content: "";
	flex: 0 0 20px;
}
.scroller .mobile-menu__promo-list::after {
	content: "";
	flex: 0 0 13px;
}
@media screen and (min-width: 641px) {
	.mobile-menu .icon--nav-triangle-borderless {
		left: 40px;
	}
}
.mobile-menu[aria-hidden="false"] .mobile-menu__panel.is-open {
	-webkit-transform: translateX(0);
	transform: translateX(0);
	visibility: visible;
}
.mobile-menu__help-wrapper {
	display: flex;
	align-items: center;
}
.mobile-menu__help-wrapper .icon--bi-phone {
	margin-right: 16px;
	width: 24px;
	height: 24px;
}
.mobile-menu__help-wrapper .icon--bi-email {
	margin-right: 18px;
	width: 22px;
	height: 22px;
}
.mobile-menu__help-wrapper + .mobile-menu__help-wrapper {
	margin-top: 18px;
}
.mobile-menu__currency-selector,
.mobile-menu__locale-selector {
	display: flex;
	align-items: center;
}
.mobile-menu__currency-selector .select-wrapper,
.mobile-menu__locale-selector .select-wrapper {
	flex: 1;
	margin-left: 5px;
}
.mobile-menu__currency-selector .currency-selector,
.mobile-menu__locale-selector .locale-selector {
	display: block;
	width: 100%;
}
.line-item__product-info-wrapper {
	display: flex;
	align-items: flex-start;
}
.line-item__image-wrapper {
	width: 60px;
	min-width: 60px;
	margin-right: 20px;
}
.line-item__meta {
	font-size: 15px;
}
.line-item__vendor {
	display: block;
	margin-bottom: 7px;
	line-height: 1.55;
	font-size: 13px;
	text-transform: uppercase;
}
.line-item__title {
	display: block;
	margin-bottom: 4px;
	line-height: 1.5;
}
.line-item__property-list {
	line-height: 1.5;
	margin: 6px 0;
}
.line-item__price {
	display: inline-block;
	font-weight: 500;
	color: #00badb;
}
.line-item__price--compare {
	color: #677279;
}
.line-item__price--highlight {
	color: #e00;
}
.line-item__price--compare {
	position: relative;
	margin-left: 20px;
}
.line-item__price--compare::before {
	position: absolute;
	content: "";
	top: 50%;
	left: -5px;
	width: calc(100% + 10px);
	height: 1px;
	background: rgba(103, 114, 121, 0.7);
}
.line-item__price-info {
	margin-top: -2px;
	font-size: 14px;
}
.line-item__discount-list {
	list-style: none;
	margin-top: 2px;
}
.line-item__discount {
	display: inline-block;
	font-weight: 500;
	font-size: 14px;
	background: rgba(238, 0, 0, 0.08);
	color: #e00;
	border-radius: 2px;
	padding: 0 11px;
}
.line-item__discount svg {
	margin-right: 6px;
	vertical-align: text-bottom;
}
.line-item__quantity-remove {
	display: inline-block;
	margin-left: 10px;
	font-size: 14px;
	line-height: 1;
}
@media screen and (max-width: 640px) {
	.line-item-table,
	.line-item-table tbody {
		display: block;
		width: 100%;
	}
	.line-item--stack {
		display: block;
		white-space: normal;
	}
	.line-item--stack:first-child {
		border-top: none !important;
	}
	.line-item--stack .line-item__image-wrapper {
		width: 80px;
		min-width: 80px;
	}
	.line-item--stack .line-item__product-info {
		display: block;
		padding-top: 20px;
		padding-bottom: 20px;
	}
	.line-item--stack .line-item__title {
		font-size: 15px;
	}
	.line-item--stack .line-item__price {
		font-size: 14px;
	}
	.line-item--stack .line-item__quantity {
		margin-top: 12px;
	}
}
@media screen and (min-width: 641px) {
	.line-item__product-info-wrapper {
		align-items: center;
	}
	.line-item__title {
		white-space: normal;
	}
	.line-item__image-wrapper {
		width: 90px;
		min-width: 90px;
	}
	.line-item__quantity-remove {
		display: block;
		width: -webkit-max-content;
		width: -moz-max-content;
		width: max-content;
		margin: 10px auto 0 auto;
	}
}
.fulfillment-item {
	border-top: none !important;
}
.fulfillment-item td {
	padding-top: 0 !important;
}
.order-foot {
	border-top: 1px solid #e1e3e4;
}
.order-foot__item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px 20px 16px 0;
	font-size: 15px;
}
.order-foot__item + .order-foot__item {
	border-top: 1px solid #e1e3e4;
}
.order-foot__item--highlight,
.order-foot__item--strong {
	font-size: 16px;
	font-weight: 500;
}
.order-foot__item--highlight {
	color: #e00;
}
.order-foot__item--strong {
	color: #1e2d7d;
}
@media screen and (max-width: 640px) {
	.card .order-foot {
		margin-left: 20px;
	}
}
@media screen and (min-width: 641px) {
	.order-foot__inner {
		max-width: 470px;
		margin-left: auto;
	}
	.order-foot__item {
		padding-right: 30px;
		padding-left: 20px;
	}
}
.order-summary__alert {
	margin: 21px 0 5px 0;
}
@media screen and (min-width: 641px) {
	.order-summary__alert {
		margin: 24px 0 12px 0;
	}
}
.address-list,
.address-list__item + .address-list__item {
	border-top: 1px solid #e1e3e4;
}
.address-list__item {
	padding: 24px 20px;
}
.address-list__action-list {
	margin-top: 14px;
	font-size: 14px;
}
.address-list__action-list > :last-child {
	margin-left: 18px;
}
@media screen and (max-width: 640px) {
	.address-list,
	.address-list__item + .address-list__item {
		display: block;
		position: relative;
		border-top: none;
	}
	.address-list::before,
	.address-list__item + .address-list__item::before {
		position: absolute;
		content: "";
		height: 1px;
		width: calc(100% - 20px);
		top: 0;
		left: 20px;
		background: #e1e3e4;
	}
}
@media screen and (min-width: 641px) {
	.template-addresses .card__section--tight {
		padding-right: 20px;
	}
	.address-list {
		display: flex;
		flex-wrap: wrap;
	}
	.address-list__item {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		flex: 0 0 50%;
		padding-left: 30px;
		padding-right: 30px;
		box-shadow: 0 1px #e1e3e4, 1px 0 #e1e3e4;
		border-top: none !important;
	}
}
@media screen and (min-width: 1280px) {
	.address-list__item {
		flex-basis: 33.333333%;
	}
}
.page__header {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin: 28px 0;
}
.page__header--stack {
	flex-direction: column;
	align-items: flex-start;
}
.page__header--centered {
	justify-content: center;
	text-align: center;
}
.page__header--stack.page__header--centered {
	align-items: center;
}
.page__header--image {
	position: relative;
	justify-content: center;
	background-size: cover;
	background-position: center;
	min-height: 250px;
	padding: 25px;
	border-radius: 3px;
	text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
	overflow: hidden;
}
.page__header--image.page__header--overlay::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
}
.page__header--image.page__header--overlay > * {
	z-index: 1;
}
.page__header--edge2edge {
	border-radius: 0;
	margin-top: 0 !important;
}
.page__title {
	margin-bottom: 0;
}
.page__sub-header {
	display: none;
	margin: 23px 0;
	font-size: 14px;
}
.page__description {
	margin: 10px 0 0 0;
}
.page__button-action {
	margin-top: 20px;
}
.page__tag-list {
	margin: 12px -20px -10px -20px;
	list-style: none;
	font-size: 15px;
}
.page__tag-item {
	position: relative;
	display: inline-block;
	margin: 0 20px 10px 20px;
}
.page__tag-item.is-selected {
	color: #00badb;
}
.page__tag-item.is-selected::after {
	position: absolute;
	content: "";
	left: 0;
	bottom: 0;
	width: 100%;
	height: 1px;
	background: currentColor;
}
.page__meta {
	margin-top: 8px;
	font-size: 13px;
}
.page__meta-item:not(:last-child)::after {
	display: inline-block;
	content: "";
	width: 5px;
	height: 5px;
	margin: 0 10px;
	border-radius: 100%;
	background: rgba(103, 114, 121, 0.4);
	vertical-align: middle;
}
.page__navigation-item svg {
	width: 6px;
	height: 9px;
}
.page__navigation-item svg path {
	stroke-width: 1.5px;
}
.page__navigation-item--prev svg {
	margin-right: 9px;
}
.page__navigation-item--next svg {
	margin-left: 9px;
}
.page__navigation-item--prev + .page__navigation-item--next::before {
	display: inline-block;
	content: "";
	width: 1px;
	height: 12px;
	margin: 0 15px;
	background: #677279;
	vertical-align: -2px;
}
.page__content {
	margin-bottom: 40px;
}
@media screen and (min-width: 641px) {
	.page__header {
		margin: 35px 0;
	}
	.page__header--image {
		min-height: 430px;
	}
	.page__sub-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.page__sub-header + .page__header {
		margin-top: 20px;
	}
	.page__meta {
		margin-top: 10px;
		font-size: 1rem;
	}
	.page__meta-item:not(:last-child)::after {
		margin: 0 16px;
	}
	.page__content {
		margin-bottom: 90px;
		font-size: 17px;
	}
}
@media screen and (min-width: 1000px) {
	.page__header {
		flex-direction: column;
		align-items: flex-start;
	}
	.page__header--centered {
		align-items: center;
	}
}
.popover {
	position: absolute;
	width: 100vw;
	left: 0;
	top: 100%;
	background: #fff;
	color: #677279;
	z-index: 1;
	visibility: hidden;
	opacity: 0;
	-webkit-transform: scale(0.9);
	transform: scale(0.9);
	transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out,
		-webkit-transform 0.25s ease-in-out;
	transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out,
		visibility 0.25s ease-in-out;
	transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out,
		visibility 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
	will-change: transform;
}
.popover .icon--nav-triangle-borderless {
	right: 67px;
}
.popover--large {
	text-align: center;
}
.popover[aria-hidden="false"] {
	visibility: visible;
	opacity: 1;
	-webkit-transform: scale(1.001);
	transform: scale(1.001);
	transition: opacity 0.4s cubic-bezier(0, 1, 0.4, 1), visibility 0.4s linear,
		-webkit-transform 0.4s cubic-bezier(0.18, 1.25, 0.4, 1);
	transition: opacity 0.4s cubic-bezier(0, 1, 0.4, 1),
		transform 0.4s cubic-bezier(0.18, 1.25, 0.4, 1), visibility 0.4s linear;
	transition: opacity 0.4s cubic-bezier(0, 1, 0.4, 1),
		transform 0.4s cubic-bezier(0.18, 1.25, 0.4, 1), visibility 0.4s linear,
		-webkit-transform 0.4s cubic-bezier(0.18, 1.25, 0.4, 1);
}
/*.popover__inner {
	 padding: 15px 20px 20px 20px; 
}*/
.popover__inner--no-padding {
	padding-left: 0 !important;
	padding-right: 0 !important;
}
.popover .icon--nav-triangle-borderless {
	position: absolute;
	bottom: 100%;
	width: 18px;
	height: 8px;
	z-index: 2;
	-webkit-filter: drop-shadow(0 -2px 2px rgba(0, 0, 0, 0.12));
	filter: drop-shadow(0 -2px 2px rgba(0, 0, 0, 0.12));
}
.popover__header {
	padding: 12px 0 30px 0;
}
.popover__title {
	margin-bottom: 4px;
	font-size: 21px;
}
.popover__legend {
	margin-bottom: 16px;
}
.popover__secondary-action {
	margin-top: 16px;
	font-size: 14px;
}
.popover__secondary-action > p {
	margin-bottom: 0;
}
@media screen and (max-width: 640px) {
	.popover__panel-list,
	.popover__panel {
		height: 100% !important;
	}
	.popover__inner {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
	}
}
@media screen and (min-width: 641px) {
	.popover {
		width: auto;
		min-width: 160px;
		right: -2px;
		top: calc(100% + 15px);
		left: auto;
		border-radius: 3px;
		box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.1);
	}
	.popover .icon--nav-triangle-borderless {
		right: 27px;
	}
	.popover--large {
		min-width: 320px;
	}
	/*.popover__inner {
		 padding: 15px 25px 15px 25px; 
	}*/
	.popover__header {
		padding-bottom: 15px;
	}
	.popover__title {
		margin-bottom: 8px;
		font-size: 19px;
	}
}
@media screen and (min-width: 1280px) {
	.popover {
		top: calc(100% + 4px);
	}
}
.popover__linklist {
	white-space: nowrap;
}
.popover__link-item {
	display: block;
	padding: 2px 25px;
	cursor: pointer;
	width: 100%;
	transition: color 0.15s ease-in-out, background 0.15s ease-in-out;
}
.popover__link-item:hover {
	background: rgba(0, 186, 219, 0.08);
	color: #00badb;
}
.popover__panel-list {
	position: relative;
	overflow: hidden;
	transition: height 0.2s ease-in-out;
}
.popover__panel {
	width: 100%;
	visibility: hidden;
	transition: visibility 500ms cubic-bezier(0.23, 1, 0.32, 1),
		-webkit-transform 500ms cubic-bezier(0.23, 1, 0.32, 1);
	transition: transform 500ms cubic-bezier(0.23, 1, 0.32, 1),
		visibility 500ms cubic-bezier(0.23, 1, 0.32, 1);
	transition: transform 500ms cubic-bezier(0.23, 1, 0.32, 1),
		visibility 500ms cubic-bezier(0.23, 1, 0.32, 1),
		-webkit-transform 500ms cubic-bezier(0.23, 1, 0.32, 1);
}
.popover__panel--default {
	-webkit-transform: translateX(-100%);
	transform: translateX(-100%);
}
.popover__panel--sliding {
	position: absolute;
	top: 0;
	left: 0;
	-webkit-transform: translateX(100%);
	transform: translateX(100%);
}
.popover[aria-hidden="false"] .popover__panel.is-selected {
	visibility: visible;
}
.popover__panel.is-selected {
	-webkit-transform: translateX(0);
	transform: translateX(0);
}
.popover--password .icon--nav-triangle-borderless {
	right: 21px;
}
.popover--currency,
.popover--locale {
	min-width: 0;
}
@media screen and (min-width: 641px) {
	.popover--password .popover__inner {
		padding-bottom: 25px;
	}
	.popover--password .icon--nav-triangle-borderless {
		right: calc(50% - 13px);
	}
	.popover--currency {
		top: calc(100% + 3px);
		right: auto;
		left: -2px;
	}
	.popover--currency .icon--nav-triangle-borderless {
		right: 23px;
	}
	.popover--locale {
		top: calc(100% + 3px);
		right: -26px;
	}
	.popover--locale .icon--nav-triangle-borderless {
		right: 23px;
	}
	.popover--logged {
		right: -47px;
	}
	.popover--logged .icon--nav-triangle-borderless {
		right: 50px;
	}
	.popover--unlogged {
		right: -5px;
	}
	.popover--unlogged .icon--nav-triangle-borderless {
		right: 6px;
	}
}
@media screen and (min-width: 1280px) {
	.popover--password {
		top: calc(100% + 15px);
		right: -2px;
	}
	.popover--logged {
		right: -32px;
	}
	.popover--logged .icon--nav-triangle-borderless {
		right: 29px;
	}
	.popover--unlogged {
		right: 0;
		-webkit-transform: scale(0.9) translateX(calc(50% - 7px));
		transform: scale(0.9) translateX(calc(50% - 7px));
	}
	.popover--unlogged .icon--nav-triangle-borderless {
		left: calc(50% - 8px);
	}
	.popover--unlogged[aria-hidden="false"] {
		-webkit-transform: scale(1) translateX(calc(50% - 7px));
		transform: scale(1) translateX(calc(50% - 7px));
	}
}
.product-gallery {
	position: relative;
	margin-bottom: 12px;
}
.product-gallery__carousel-wrapper {
	position: relative;
	margin-bottom: 25px;
}
.product-gallery__carousel .flickity-viewport {
	transition: height 0.2s ease-in-out;
}
.product-gallery__carousel:not(.flickity-enabled)
	.product-gallery__carousel-item:not(.is-selected) {
	display: none;
}
.product-gallery__carousel-item {
	width: 100%;
	padding: 0 20px;
}
.product-gallery__carousel-item--hidden {
	visibility: hidden;
}
.product-gallery__carousel-item[aria-hidden="true"] .plyr__control {
	visibility: hidden;
}
.product-gallery__carousel-item.is-filtered {
	display: none;
}
.product-gallery__size-limiter {
	margin: 0 auto;
}
.product-gallery__view-in-space {
	background: rgba(103, 114, 121, 0.08);
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.product-gallery__view-in-space[data-shopify-xr-hidden] {
	visibility: hidden;
}
@media screen and (min-width: 1000px) {
	.product-gallery__view-in-space[data-shopify-xr-hidden] {
		display: none;
	}
}
.product-gallery__view-in-space svg {
	margin: -1px 8px 0 0;
	width: 16px;
	height: 16px;
	vertical-align: middle;
}
.product-gallery__zoom-notice {
	display: none;
}
@media screen and (max-width: 640px) {
	.product-gallery__carousel {
		margin-left: -20px;
		margin-right: -20px;
	}
}
@media screen and (min-width: 1000px) {
	.product-gallery__carousel {
		margin: -4px;
	}
	.product-gallery__carousel-item {
		padding: 4px;
	}
	.product-gallery--with-thumbnails {
		display: flex;
		flex-direction: row-reverse;
		align-items: flex-start;
		width: 100%;
	}
	.product-gallery--with-thumbnails .product-gallery__carousel-wrapper {
		flex: 1 0 auto;
		margin: 10px 5px 0 42px;
	}
}
@media screen and (min-width: 1000px) and (-moz-touch-enabled: 0),
	screen and (min-width: 1000px) and (hover: hover) {
	.product-gallery__carousel--zoomable ~ .product-gallery__zoom-notice {
		display: block;
		margin: 26px 0 6px 0;
		width: 100%;
		text-align: center;
		font-size: 15px;
	}
	.product-gallery__carousel--zoomable ~ .product-gallery__zoom-notice svg {
		width: 10px;
		height: 10px;
		margin-right: 14px;
		vertical-align: baseline;
	}
}
@media screen and (min-width: 1000px) {
	.product-gallery {
		margin-bottom: 0;
	}
}
.product-gallery__thumbnail {
	position: relative;
	display: block;
	width: 60px;
	padding: 2px;
	margin: 0 4px;
	border: 2px solid transparent;
	border-radius: 3px;
	transition: border 0.2s ease-in-out;
	cursor: pointer;
}
.product-gallery__thumbnail.is-nav-selected {
	border-color: #00badb;
}
.product-gallery__thumbnail.is-filtered {
	display: none;
}
.product-gallery__thumbnail-badge {
	position: absolute;
	top: 3px;
	right: 3px;
	width: 20px;
	height: 20px;
	z-index: 1;
}
@media screen and (max-width: 999px) {
	.product-gallery__thumbnail-list::after,
	.product-gallery__thumbnail-list::before {
		content: "";
		display: inline-block;
		vertical-align: middle;
		width: 16px;
	}
	.product-gallery__thumbnail {
		display: inline-block;
		vertical-align: top;
	}
}
@media screen and (min-width: 641px) and (max-width: 999px) {
	.product-gallery .scroller {
		margin: 0 -15px;
	}
}
@media screen and (min-width: 641px) {
	.product-gallery__thumbnail {
		width: 72px;
	}
}
@media screen and (min-width: 1000px) {
	.product-gallery__thumbnail-list {
		position: relative;
		z-index: 1;
		margin: -2px 0 -2px -3px;
		max-height: 450px;
		width: 96px;
		overflow: auto;
		-ms-overflow-style: none;
		scrollbar-width: none;
		-ms-scroll-chaining: none;
		overscroll-behavior: contain;
	}
	.product-gallery__thumbnail-list::-webkit-scrollbar {
		-webkit-appearance: none;
	}
	.product-gallery__thumbnail {
		margin: 8px 4px;
	}
}
.product-meta {
	position: relative;
	margin: -11px 0 5px 0;
}
.product-meta__title {
	margin-bottom: 14px;
}
.modal .product-meta__title {
	margin-right: 45px;
}
.product-meta__label-list {
	margin-bottom: 17px;
	font-size: 0;
}
.product-meta__label-list .product-label + .product-label {
	margin-left: 5px;
}
.product-meta__reference {
	margin-bottom: 3px;
	font-size: 14px;
}
.product-meta__reviews-badge {
	display: block;
}
.product-meta__reviews-badge .spr-badge {
	line-height: inherit;
}
.product-meta__reviews-badge .spr-badge .spr-badge-caption {
	margin-left: 12px;
}
.product-meta__vendor {
	text-transform: uppercase;
}
.product-meta__vendor + .product-meta__sku::before {
	display: inline-block;
	position: relative;
	content: "";
	margin: 0 14px;
	height: 13px;
	width: 1px;
	top: -1px;
	background: #e1e3e4;
	vertical-align: middle;
}
.product-meta__share-buttons {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 40px;
}
.product-meta__share-buttons .social-media__item svg {
	opacity: 0.35;
}
.card__separator + .product-meta__share-buttons {
	margin-top: 0;
}
.product-meta__description {
	margin-bottom: 25px;
}
.product-form + .product-meta__description {
	margin-top: 25px;
	margin-bottom: 0;
}
@media screen and (min-width: 641px) {
	.product-meta__share-buttons {
		margin-top: 0;
	}
	.product-meta__label-list + .product-meta__share-buttons {
		margin-top: 22px;
	}
	.product-meta__reference,
	.product-meta__reviews-badge {
		margin-bottom: -2px;
	}
	.product-meta__reviews-badge {
		margin-top: -4px;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
	}
	.product-meta__reference ~ .product-meta__share-buttons,
	.product-meta__reviews-badge ~ .product-meta__share-buttons {
		position: absolute;
		right: 0;
		bottom: 0;
	}
	.product-meta__reference + .product-meta__reviews-badge {
		margin-top: 5px;
	}
	.product-meta__reviews-badge .spr-badge .spr-badge-caption {
		font-size: 15px;
	}
}
.product-form__variants {
	margin-bottom: 24px;
}
.product-form__option {
	margin-bottom: 23px;
}
.product-form__option-name {
	display: block;
	margin-bottom: 2px;
}
.product-form__selected-value {
	padding-left: 2px;
	color: #677279;
}
.product-form__info-list {
	display: table;
	width: 100%;
	margin: -22px 0 6px 0;
	border-spacing: 0 22px;
}
.product-form__info-item {
	display: table-row;
}
.product-form__info-title {
	display: table-cell;
	padding-right: 12px;
	vertical-align: baseline;
	white-space: nowrap;
}
.product-form__info-item--quantity > * {
	padding-top: 6px;
	vertical-align: middle;
}
.product-form__info-content {
	display: table-cell;
	width: 100%;
}
.product-form__price-info {
	margin-top: 5px;
	font-size: 15px;
}
.product-form__price-info + .product-form__price-info {
	margin-top: 0;
}
.product-form .select-wrapper {
	display: inline-block;
	vertical-align: middle;
}
.product-form__quantity {
	display: inline-block;
	width: 65px;
	vertical-align: middle;
}
.product-form__inventory.inventory::before {
	margin-right: 14px;
}
.product-form__payment-container {
	display: flex;
	flex-direction: column;
}
.product-form__error {
	margin-top: 18px;
	height: 0;
	overflow: hidden;
	transition: height 0.25s ease-in-out;
}
.product-form .price--compare {
	top: -2px;
}
@media screen and (max-width: 640px) {
	.product-form__payment-container .shopify-payment-button {
		margin-top: 16px;
	}
}
@media screen and (min-width: 641px) {
	.product-form {
		margin-top: -8px;
	}
	.product-form__option-name {
		margin-bottom: 7px;
	}
	.product-form__variants {
		margin-bottom: 26px;
	}
	.product-form__payment-container {
		flex-direction: row;
		align-items: flex-start;
		margin: 0 -9px;
	}
	.product-form__payment-container > * {
		flex: none;
		width: calc(50% - 18px);
		margin: 0 9px;
	}
}
.shopify-payment-button__button--unbranded {
	padding: 0 30px !important;
	line-height: 48px !important;
	border-radius: 2px !important;
	background: #1e2d7d !important;
	color: #fff !important;
	font-weight: 500 !important;
}
.shopify-payment-button__button--unbranded:hover {
	background: rgba(30, 45, 125, 0.8) !important;
}
.shopify-payment-button__button--branded {
	border-radius: 3px;
	overflow: hidden;
	min-height: 48px;
}
.shopify-payment-button__more-options {
	margin-top: 8px;
	color: #00badb;
	transition: color 0.2s ease-in-out;
}
.shopify-payment-button__more-options:not([disabled]):hover {
	text-decoration: none;
	color: #00798e;
}
.shopify-payment-button__more-options[aria-hidden="true"] {
	display: none;
}
.product-block-list__item--trust .icon--lock {
	width: 18px;
	height: 24px;
	color: #1e2d7d;
}
.product-block-list__item--shipping .shipping-estimator {
	margin-bottom: 10px;
	margin-top: -0.435em;
}
.product-block-list__item--shipping .shipping-estimator__results {
	margin-top: 26px;
	margin-bottom: -10px;
}
.product__refund-policy-link {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}
.product__refund-policy-link svg {
	width: 8px;
	height: 12px;
}
@media screen and (max-width: 640px) {
	.product-block-list__item:first-child .card {
		border-top: none;
	}
}
@media screen and (max-width: 999px) {
	.product-block-list__wrapper {
		min-height: 0 !important;
	}
	.product-block-list__item--info > .card {
		top: 0 !important;
	}
}
@media screen and (min-width: 1000px) {
	.product-block-list {
		position: relative;
	}
	.product-block-list__wrapper {
		width: calc(50% - 15px);
		margin-left: 0;
	}
	.product-block-list__item--info {
		position: absolute;
		top: 0;
		right: 0;
		width: calc(50% - 15px);
		height: 100%;
	}
}
@media screen and (min-width: 1280px) {
	.product-block-list--medium .product-block-list__wrapper {
		width: calc(55% - 15px);
	}
	.product-block-list--medium .product-block-list__item--info {
		width: calc(45% - 15px);
	}
	.product-block-list--large .product-block-list__wrapper {
		width: calc(60% - 15px);
	}
	.product-block-list--large .product-block-list__item--info {
		width: calc(40% - 15px);
	}
}
.modal .featured-product .card {
	border: none;
}
.modal .featured-product .card:first-child {
	border-right: 1px solid #e1e3e4;
}
@media screen and (min-width: 1000px) {
	.featured-product {
		display: table;
		width: 100%;
		table-layout: fixed;
	}
	.featured-product > * {
		display: table-cell;
		vertical-align: top;
		width: 50%;
	}
	.featured-product > :first-child {
		border-radius: 3px 0 0 3px;
	}
	.featured-product > :last-child {
		border-radius: 0 3px 3px 0;
		border-left: none;
	}
}
.product-label {
	display: inline-block;
	padding: 4px 10px;
	color: #ffffff;
	border-radius: 3px;
	font-weight: 500;
	font-size: 14px;
	line-height: 1;
	vertical-align: top;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
}
.product-label--on-sale {
	background: #e00;
	color: #ffffff;
}
.product-label--custom1 {
	background: #008a00;
	color: #ffffff;
}
.product-label--custom2 {
	background: #0774d7;
	color: #ffffff;
}
.price-list {
	line-height: 1;
}
.price {
	font-size: 25px;
	color: #00badb;
}
.price--highlight {
	margin-right: 0.7em;
	color: #e00;
}
.price--compare {
	position: relative;
	color: #677279;
	font-size: 17px;
}
.price--compare::before {
	position: absolute;
	content: "";
	top: 50%;
	left: -3px;
	width: calc(100% + 6px);
	height: 1px;
	background: rgba(103, 114, 121, 0.7);
}
.inventory {
	position: relative;
	display: block;
	padding-left: 1.4em;
	font-weight: 500;
	font-size: 15px;
	line-height: 1.2;
}
.inventory::before {
	position: absolute;
	display: inline-block;
	content: "";
	width: 8px;
	height: 8px;
	left: 0;
	top: 0.45em;
	border-radius: 100%;
	background: currentColor;
}
.inventory--high {
	color: #008a00;
}
.inventory--low {
	color: red;
}
.inventory-bar {
	position: relative;
	display: block;
	margin: 16px 0 10px 0;
	width: 100%;
	height: 4px;
	border-radius: 3px;
	background: #e1e3e4;
	overflow: hidden;
}
.inventory-bar--hidden {
	display: none;
}
.inventory-bar__progress {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 0;
	display: block;
	transition: width 1.15s ease-in-out;
}
.inventory--high + .inventory-bar > .inventory-bar__progress {
	background: #008a00;
}
.inventory--low + .inventory-bar > .inventory-bar__progress {
	background: red;
}
@media screen and (min-width: 641px) {
	.inventory {
		font-size: 1rem;
	}
	.inventory-bar {
		margin-top: 23px;
	}
}
.color-swatch-list {
	margin: -2px -6px 0 -6px;
	padding-left: 4px;
}
.color-swatch {
	position: relative;
	display: inline-block;
	margin: 6px;
	vertical-align: middle;
}
.color-swatch__radio {
	position: absolute;
	height: 0;
	width: 0;
	opacity: 0;
}
.color-swatch__item {
	position: relative;
	display: block;
	width: 18px;
	height: 18px;
	cursor: pointer;
	background-size: cover;
}
.color-swatch__item svg {
	display: none;
}
.color-swatch__item-link {
	display: none;
	position: relative;
	padding-left: 8px;
	padding-right: 8px;
	height: 18px;
	background-color: #fff;
	box-shadow: 0 0 0 1px #d4d6d8 inset;
	border-radius: 10px;
	width: auto;
	line-height: 18px;
	font-size: 12px;
}
.color-swatch__button {
	vertical-align: middle;
}
.color-swatch__button--labelled {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.color-swatch__label {
	display: inline-block;
	vertical-align: middle;
	margin-left: 14px;
}
.color-swatch__radio:checked + .color-swatch__item::after,
.color-swatch__button.is-selected .color-swatch__item::after {
	position: absolute;
	content: "";
	width: calc(100% + 8px);
	height: calc(100% + 8px);
	top: -4px;
	left: -4px;
	border-radius: 3px;
	border: 2px solid #00badb;
}
.color-swatch__button.is-selected .color-swatch__label {
	font-weight: 500;
	color: #00badb;
}
.color-swatch--white .color-swatch__item {
	box-shadow: 0 0 0 1px #d4d6d8 inset;
}
.color-swatch--view-more ~ .color-swatch {
	display: none;
}
.color-swatch--view-more .color-swatch__item {
	display: none;
}
.color-swatch--view-more .color-swatch__item-link {
	display: block;
}
.color-swatch-list--large {
	margin: -4px -8px 0 -8px;
}
.color-swatch-list--large .color-swatch {
	margin: 8px;
}
.color-swatch-list--large .color-swatch__item {
	width: 36px;
	height: 36px;
}
.color-swatch-list--stack {
	margin: -3px -7px 0 -7px;
}
.color-swatch-list--stack .color-swatch__item {
	width: 16px;
	height: 16px;
}
.color-swatch-list--stack .color-swatch {
	display: block;
	margin: 7px;
}
.color-swatch--disabled .color-swatch__item::before {
	position: absolute;
	content: "";
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background: rgba(255, 255, 255, 0.5);
}
.color-swatch--disabled svg {
	display: block;
	position: absolute;
	z-index: 1;
	width: 14px;
	height: 14px;
	top: calc(50% - 7px);
	left: calc(50% - 7px);
	color: #4a4a4a;
}
.block-swatch-list {
	margin: -3px -4px 0 -4px;
	padding-left: 2px;
}
.block-swatch {
	display: inline-block;
	margin: 4px;
}
.block-swatch__radio {
	position: absolute;
	height: 0;
	width: 0;
	opacity: 0;
}
.block-swatch__item {
	display: block;
	padding: 6px 16px 7px 16px;
	text-align: center;
	border: 1px solid #d4d6d8;
	border-radius: 3px;
	/* vertical-align: middle; */
	cursor: pointer;
	box-shadow: 0 1px 1px rgba(212, 214, 216, 0.25);
}
.block-swatch__radio:checked + .block-swatch__item {
	box-shadow: 0 0 0 1px #00badb;
	border-color: #00badb;
	background: rgba(0, 186, 219, 0.06);
	color: #00badb;
}
.block-swatch--disabled .block-swatch__item-text {
	position: relative;
	opacity: 0.5;
}
.block-swatch--disabled .block-swatch__item-text::after {
	position: absolute;
	content: "";
	width: calc(100% + 6px);
	height: 1px;
	top: calc(50% + 1px);
	left: -3px;
	background: currentColor;
}
.variant-swatch-list {
	margin: -5px -5px -2px -4px;
}
.variant-swatch {
	display: inline-block;
	margin: 5px;
	vertical-align: middle;
}
.variant-swatch__radio {
	position: absolute;
	height: 0;
	width: 0;
	opacity: 0;
}
.variant-swatch__item {
	position: relative;
	display: block;
	width: 64px;
	padding: 2px;
	cursor: pointer;
	overflow: hidden;
	border: 2px solid transparent;
}
.variant-swatch__item svg {
	display: none;
}
.variant-swatch__radio:checked + .variant-swatch__item {
	border-color: #00badb;
	border-radius: 3px;
}
.variant-swatch--disabled img {
	opacity: 0.5;
}
.variant-swatch--disabled svg {
	display: block;
	position: absolute;
	z-index: 1;
	width: 14px;
	height: 14px;
	top: calc(50% - 7px);
	left: calc(50% - 7px);
	color: #4a4a4a;
}
.product-item {
	position: relative;
	padding: 20px;
	min-width: 0;
	flex-shrink: 0;
	background: #fff;
	white-space: normal;
	width: 350px;
}
.product-item::after {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	box-shadow: 1px 0 #e1e3e4, 0 1px #e1e3e4;
	z-index: 2;
	pointer-events: none;
}
.product-item__image-wrapper {
	display: block;
	margin-bottom: 20px;
}
.product-item__image-wrapper .placeholder-svg {
	height: auto;
}
.product-item__secondary-image {
	display: none;
}
.product-item__label-list {
	position: absolute;
	top: 11px;
	left: -1px;
	z-index: 2;
	font-size: 0;
}
.product-item__label-list > .product-label {
	display: block;
	margin-bottom: 5px;
	border-radius: 0 3px 3px 0;
}
.product-item__info {
	min-width: 0;
}
.product-item__price-list:first-child {
	margin-bottom: 10px;
}
.product-item__price-list {
	margin-top: -5px;
	margin-bottom: -5px;
}
.product-item__price-list .price {
	display: inline-block;
	margin-top: 5px;
	margin-bottom: 5px;
}
.product-item__vendor {
	display: block;
	margin-bottom: 6px;
	font-size: 13px;
	text-transform: uppercase;
}
.product-item__vendor,
.product-item__title {
	display: block;
	line-height: 1.55;
}
.product-item__title {
	margin-bottom: 12px;
}
.product-item__title + .product-item__vendor {
	margin-bottom: 12px;
	margin-top: -4px;
}
.product-item__swatch-list {
	margin: -2px 0 12px 0;
	white-space: nowrap;
	overflow: hidden;
}
.product-item__price-info {
	margin-top: 10px;
	font-size: 14px;
	line-height: 1;
}
.product-item .price {
	font-size: 19px;
}
.product-item .price--compare {
	font-size: 15px;
}
.product-item__reviews-badge {
	display: block;
	margin: 17px 0;
}
.product-item__inventory {
	margin-top: 14px;
}
.product-item__inventory::before {
	top: 0.3em;
}
.product-item__inventory {
	font-size: 14px;
}
@media (-moz-touch-enabled: 0), (hover: hover) {
	.product-item__image-wrapper--with-secondary .product-item__primary-image,
	.product-item__image-wrapper--with-secondary .product-item__secondary-image {
		transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
	}
	.product-item__image-wrapper--with-secondary .product-item__secondary-image {
		display: block;
		-o-object-fit: contain;
		object-fit: contain;
		-o-object-position: center;
		object-position: center;
		visibility: hidden;
		opacity: 0;
	}
	.product-item__image-wrapper--with-secondary:hover
		.product-item__primary-image {
		opacity: 0;
		visibility: hidden;
	}
	.product-item__image-wrapper--with-secondary:hover
		.product-item__secondary-image {
		visibility: visible;
	}
	.product-item__image-wrapper--with-secondary:hover
		.product-item__secondary-image.lazyloaded {
		opacity: 1;
	}
}
@media screen and (min-width: 641px) {
	.product-item__title {
		font-size: 15px;
	}
	.product-item--vertical .price:not(.price--compare),
	.product-item--list .price:not(.price--compare) {
		font-size: 21px;
	}
}
@media screen and (min-width: 1000px) {
	.product-item__label-list {
		left: 0;
	}
}
.product-item--vertical .product-item__action-list,
.product-item--horizontal .product-item__action-list {
	margin: 20px 0 5px 0;
}
.product-item--vertical .product-item__action-button,
.product-item--horizontal .product-item__action-button {
	padding-left: 10px;
	padding-right: 10px;
}
.product-item--vertical .product-item__action-list--list-view-only,
.product-item--vertical .product-item__action-button--list-view-only,
.product-item--horizontal .product-item__action-list--list-view-only,
.product-item--horizontal .product-item__action-button--list-view-only {
	display: none;
}
.product-item--vertical
	.product-item__action-button--list-view-only
	+ .product-item__action-button,
.product-item--horizontal
	.product-item__action-button--list-view-only
	+ .product-item__action-button {
	margin-top: 0;
}
@media screen and (min-width: 1000px) {
	.product-item--vertical .product-item__action-list {
		margin: 15px -5px -5px -5px;
	}
	.product-item--vertical .product-item__action-button {
		flex: 1 0 auto;
		margin: 5px !important;
	}
}
.product-item--horizontal,
.product-item--list {
	display: flex;
}
.product-item--horizontal .product-item__info,
.product-item--list .product-item__info {
	padding: 4px 0;
	flex: 1 0 0;
}
.product-item--horizontal .product-item__image-wrapper,
.product-item--list .product-item__image-wrapper {
	flex: none;
	width: 120px;
	margin: 0 20px 0 0;
}
.product-item--horizontal .product-item__action-list,
.product-item--list .product-item__action-list {
	align-self: center;
}
.product-item--horizontal .spr-starrating .spr-icon.spr-icon {
	font-size: 11px;
	width: 11px;
	height: 11px;
}
@media screen and (max-width: 640px) {
	.product-item--horizontal,
	.product-item--list {
		width: 100% !important;
	}
	.product-list--collection .product-item__label-list {
		left: 0;
	}
	.product-list--collection .spr-starrating .spr-icon.spr-icon {
		font-size: 11px;
		width: 11px;
		height: 11px;
	}
}
@media screen and (min-width: 641px) {
	.product-item--horizontal .product-item__image-wrapper {
		width: 110px;
		margin-right: 20px;
	}
	.product-item--horizontal .product-item__info {
		flex: 1 0 0;
	}
}
@media screen and (max-width: 640px) {
	.product-item__action-list {
		margin-top: 20px;
	}
}
@media screen and (min-width: 641px) {
	.product-item--list {
		width: 100% !important;
		align-items: center;
		padding-right: 25px;
	}
	.product-item--list .product-item__title {
		font-size: 1rem;
	}
	.product-item--list .product-item__image-wrapper {
		width: 180px;
		margin-right: 30px;
	}
	.product-item--list .product-item__info {
		display: flex;
		justify-content: space-between;
		flex: 1 0 0;
		margin: 15px 0 15px 0;
	}
	.product-item--list .product-item__action-list {
		display: flex;
		margin-left: 30px;
		min-width: 180px;
	}
}
.product-item--compact .product-item__info {
	padding-top: 0;
}
.product-item--compact .product-item__price-list:first-child {
	margin-bottom: 8px;
}
.product-item--compact .product-item__reviews-badge {
	margin: 15px 0;
}
.promo-block {
	position: relative;
	display: flex;
	min-height: 240px;
	padding: 20px 24px;
	border-radius: 3px;
	overflow: hidden;
}
.promo-block--small {
	min-height: 220px;
}
.promo-block--large {
	min-height: 260px;
}
.promo-block--top-left,
.promo-block--top-center,
.promo-block--top-right {
	align-items: flex-start;
}
.promo-block--middle-left,
.promo-block--middle-center,
.promo-block--middle-right {
	align-items: center;
}
.promo-block--bottom-left,
.promo-block--bottom-center,
.promo-block--bottom-right {
	align-items: flex-end;
}
.promo-block--top-right,
.promo-block--middle-right,
.promo-block--bottom-right {
	text-align: right;
	justify-content: flex-end;
}
.promo-block--top-center,
.promo-block--middle-center,
.promo-block--bottom-center {
	text-align: center;
	justify-content: center;
}
.promo-block--top-left,
.promo-block--middle-left,
.promo-block--bottom-left {
	text-align: left;
	justify-content: flex-start;
}
.promo-block__image-clip,
.promo-block__image-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.promo-block__image-clip {
	overflow: hidden;
}
.promo-block__image-wrapper {
	background-repeat: no-repeat;
	background-position: right calc(100% + 15px);
	background-size: auto 185px;
	transition: opacity 0.2s ease-in-out, background-position 0.35s ease-in-out;
}
.promo-block__image-wrapper--cover {
	background-size: cover !important;
	background-position: center !important;
	-webkit-transform: scale(1.01);
	transform: scale(1.01);
	transition: opacity 0.2s ease-in-out, -webkit-transform 1.2s ease-in-out;
	transition: opacity 0.2s ease-in-out, transform 1.2s ease-in-out;
	transition: opacity 0.2s ease-in-out, transform 1.2s ease-in-out,
		-webkit-transform 1.2s ease-in-out;
}
.promo-block__inner {
	position: relative;
	z-index: 1;
}
.promo-block__heading {
	margin-bottom: 3px;
	color: inherit;
}
.promo-block__cta {
	margin-top: 3px;
}
.promo-block--small .promo-block__image-wrapper {
	background-size: auto 155px;
}
.promo-block--overlay::before {
	position: absolute;
	content: "";
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	background: linear-gradient(to top, rgba(0, 0, 0, 0) 15%, rgba(0, 0, 0, 0.5));
	z-index: 1;
}
@media (-moz-touch-enabled: 0), (hover: hover) {
	.promo-block__image-wrapper {
		background-position: right calc(100% + 35px);
	}
	.promo-block:hover .promo-block__image-wrapper {
		background-position: right calc(100% + 15px);
	}
	.promo-block:hover .promo-block__image-wrapper--cover {
		-webkit-transform: scale(1.07);
		transform: scale(1.07);
	}
}
@media screen and (min-width: 641px) {
	.promo-block {
		min-height: 270px;
		padding: 26px 30px;
	}
	.promo-block--small {
		min-height: 240px;
	}
	.promo-block--large {
		min-height: 300px;
	}
}
@media screen and (min-width: 1000px) {
	.promo-block {
		min-height: 310px;
	}
	.promo-block--small {
		min-height: 280px;
	}
	.promo-block--large {
		min-height: 360px;
	}
	.promo-block__image-wrapper {
		background-size: auto 260px;
	}
	.promo-block--small .promo-block__image-wrapper {
		background-size: auto 220px;
	}
	.promo-block__heading {
		margin-bottom: 8px;
	}
	.promo-block__cta {
		margin-top: 8px;
	}
}
@font-face {
	font-family: "font-theme-star";
	src: url(//cdn.shopify.com/s/files/1/0065/3095/7363/t/23/assets/font-theme-star.eot?2990);
	src: url(//cdn.shopify.com/s/files/1/0065/3095/7363/t/23/assets/font-theme-star.eot?%23iefix&2990)
			format("embedded-opentype"),
		url(//cdn.shopify.com/s/files/1/0065/3095/7363/t/23/assets/font-theme-star.woff2?2990)
			format("woff2"),
		url(//cdn.shopify.com/s/files/1/0065/3095/7363/t/23/assets/font-theme-star.ttf?2990)
			format("truetype");
	font-weight: normal;
	font-style: normal;
	font-display: fallback;
}
.spr-starrating.spr-starrating,
.spr-starratings.spr-starratings {
	position: relative;
	display: inline-flex;
	align-items: center;
	margin: 0;
	height: 14px;
}
.spr-starrating .spr-icon.spr-icon,
.spr-starratings .spr-icon.spr-icon {
	display: inline-block;
	top: 0;
	height: 13px;
	width: 13px;
	font-size: 13px;
	opacity: 1;
}
.spr-icon.spr-icon::before {
	content: "\0041" !important;
	display: inline-block;
	font-family: "font-theme-star";
	font-size: inherit;
	font-style: normal;
	font-weight: normal;
	vertical-align: top;
	line-height: 1em;
}
.spr-icon {
	color: #ffbd00;
}
.spr-icon-star-empty.spr-icon-star-empty {
	color: rgba(103, 114, 121, 0.4) !important;
}
.spr-icon + .spr-icon {
	margin-left: 4px;
}
@media screen and (min-width: 641px) {
	.spr-summary-starrating .spr-icon.spr-icon,
	.spr-form-input .spr-icon.spr-icon {
		width: 17px;
		height: 17px;
		font-size: 17px;
	}
}
.spr-badge {
	display: flex !important;
	align-items: center;
	line-height: 1;
}
.spr-badge .spr-badge-caption {
	margin-left: 7px;
	font-size: 14px;
}
#shopify-product-reviews {
	margin: 0 !important;
	overflow: visible !important;
}
#shopify-product-reviews .spr-container {
	padding: 0;
	border: none;
}
#shopify-product-reviews .spr-header {
	position: relative;
}
#shopify-product-reviews .spr-header-title {
	font-family: "DIN Next", sans-serif;
	font-weight: 500;
	font-style: normal;
	color: #1e2d7d;
	font-size: 21px;
	line-height: 1.6;
	margin-bottom: 7px;
	text-align: left;
}
#shopify-product-reviews .spr-summary {
	display: flex;
	flex-wrap: wrap;
	text-align: left;
	align-items: center;
}
#shopify-product-reviews .spr-summary-caption {
	line-height: 1;
}
#shopify-product-reviews .spr-summary-actions {
	display: block;
	width: 100%;
}
#shopify-product-reviews .spr-summary-starrating {
	margin-right: 10px;
}
#shopify-product-reviews .spr-summary-actions-newreview,
#shopify-product-reviews .spr-button {
	float: none;
}
#shopify-product-reviews .spr-form,
#shopify-product-reviews .spr-reviews {
	margin-top: 44px;
	border-top: 1px solid #e1e3e4;
}
#shopify-product-reviews .spr-form-submitted + .spr-reviews {
	margin-top: 0;
	border-top: none;
}
#shopify-product-reviews .spr-review {
	position: relative;
	border-top: none;
	margin: 28px 0 0 0;
	padding: 0;
}
#shopify-product-reviews .spr-review + .spr-review {
	margin-top: 32px;
}
#shopify-product-reviews .spr-review-header-starratings {
	margin-bottom: 12px;
}
#shopify-product-reviews .spr-review-header-title {
	margin-bottom: 14px;
	color: #1e2d7d;
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.75;
}
#shopify-product-reviews .spr-review-header-byline {
	position: absolute;
	margin: 0;
	bottom: calc(1em + 12px);
	left: 0;
	opacity: 1;
	font-size: 14px;
}
#shopify-product-reviews .spr-review-header-byline strong {
	font-weight: normal;
}
#shopify-product-reviews .spr-review-content,
#shopify-product-reviews .spr-review-reply {
	margin-bottom: calc(1em + 22px);
}
#shopify-product-reviews .spr-review-reply {
	margin-top: -1.4em;
	padding: 0 0 0 12px;
	background: none;
	border-left: 2px solid #e1e3e4;
}
#shopify-product-reviews .spr-review-reply-shop {
	float: none;
}
#shopify-product-reviews .spr-review-content-body {
	line-height: 1.75;
	font-size: 1rem;
}
#shopify-product-reviews .spr-review-reportreview {
	color: #00badb;
	font-size: 13px;
	float: none;
	transition: color 0.2s ease-in-out;
}
#shopify-product-reviews .spr-review-reportreview:hover {
	text-decoration: underline;
}
#shopify-product-reviews .spr-form {
	padding: 0;
}
#shopify-product-reviews .spr-form > form {
	padding-top: 22px;
}
#shopify-product-reviews .spr-form-title {
	display: none !important;
}
#shopify-product-reviews .spr-form-label {
	display: block;
	margin-bottom: 8px;
	color: #1e2d7d;
	font-weight: 500;
	font-size: 1rem;
}
#shopify-product-reviews .spr-form-input-text,
#shopify-product-reviews .spr-form-input-textarea,
#shopify-product-reviews .spr-form-input-email {
	display: block;
	padding: 9px 14px;
	border-radius: 3px;
	border: 1px solid #e1e3e4;
	color: #1e2d7d;
	width: 100%;
	min-height: 48px;
	line-height: normal;
	-webkit-appearance: none;
	resize: none;
	box-shadow: 0 2px 1px rgba(225, 227, 228, 0.2) inset;
	transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
		color 0.2s ease-in-out;
}
#shopify-product-reviews .spr-form-input-textarea {
	line-height: 1.87;
	max-height: 180px;
}
#shopify-product-reviews .spr-form-input-text:focus,
#shopify-product-reviews .spr-form-input-textarea:focus,
#shopify-product-reviews .spr-form-input-email:focus {
	border-color: #00badb;
	box-shadow: 0 0 0 1px #00badb;
	color: #1e2d7d;
	outline: none;
}
#shopify-product-reviews .spr-form-input::-webkit-input-placeholder {
	color: rgba(103, 114, 121, 0.6);
}
#shopify-product-reviews .spr-form-input::-moz-placeholder {
	color: rgba(103, 114, 121, 0.6);
}
#shopify-product-reviews .spr-form-input:-ms-input-placeholder {
	color: rgba(103, 114, 121, 0.6);
}
#shopify-product-reviews .spr-form-input::-ms-input-placeholder {
	color: rgba(103, 114, 121, 0.6);
}
#shopify-product-reviews .spr-form-input::placeholder {
	color: rgba(103, 114, 121, 0.6);
}
#shopify-product-reviews .spr-form-input.spr-starrating {
	display: block;
}
#shopify-product-reviews .spr-form-contact-name,
#shopify-product-reviews .spr-form-contact-email,
#shopify-product-reviews .spr-form-contact-location,
#shopify-product-reviews .spr-form-review-rating,
#shopify-product-reviews .spr-form-review-title,
#shopify-product-reviews .spr-form-review-body {
	margin-bottom: 25px;
}
#shopify-product-reviews .spr-form-message {
	display: block;
	margin-top: 6px;
	padding: 10px 15px;
	white-space: normal;
	font-size: 1rem;
	word-break: break-all;
	word-break: break-word;
}
#shopify-product-reviews .spr-form-message-success {
	background: rgba(0, 194, 0, 0.11);
	color: #00c200;
}
#shopify-product-reviews .spr-form-message-error {
	background: rgba(255, 0, 0, 0.07);
	color: red;
}
#shopify-product-reviews .spr-pagination {
	margin-top: 16px;
	color: #1e2d7d;
	font-weight: 500;
	font-size: 15px;
	border-top: none;
}
#shopify-product-reviews .spr-pagination a {
	transition: color 0.2s ease-in-out;
}
#shopify-product-reviews .spr-pagination a:hover {
	color: #00badb;
}
#shopify-product-reviews .spr-pagination-page {
	display: inline-block;
}
#shopify-product-reviews .spr-pagination-page a,
#shopify-product-reviews .spr-pagination-page.is-active {
	display: block;
	margin: 0 2px;
	padding: 8px 11px;
	line-height: 1;
}
#shopify-product-reviews .spr-pagination-page.is-active {
	display: inline-block;
	background: #00badb;
	color: #fff;
	border-radius: 3px;
}
#shopify-product-reviews .spr-pagination-next a,
#shopify-product-reviews .spr-pagination-prev a {
	display: flex;
	align-items: center;
}
#shopify-product-reviews .spr-pagination-next svg,
#shopify-product-reviews .spr-pagination-prev svg {
	position: relative;
	width: 8px;
	height: 12px;
}
#shopify-product-reviews .spr-pagination-prev svg {
	margin-right: 14px;
}
#shopify-product-reviews .spr-pagination-next svg {
	margin-left: 14px;
}
@media screen and (max-width: 640px) {
	#shopify-product-reviews .spr-form,
	#shopify-product-reviews .spr-reviews {
		margin-top: 24px;
		border-top: none;
		padding: 0;
	}
	#shopify-product-reviews .spr-form-submitted {
		margin-top: 0;
	}
	#shopify-product-reviews .spr-form::before,
	#shopify-product-reviews
		.spr-form:not(.spr-form-submitted)
		+ .spr-reviews::before,
	#shopify-product-reviews .spr-pagination::before {
		position: relative;
		content: "";
		display: block;
		width: calc(100% + 20px);
		height: 1px;
		background: #e1e3e4;
	}
	#shopify-product-reviews .spr-pagination > div {
		padding-top: 20px;
	}
	#shopify-product-reviews .spr-summary-actions {
		margin-top: 20px;
	}
}
@media screen and (min-width: 641px) {
	#shopify-product-reviews .spr-header-title {
		font-size: 22px;
		line-height: 1.5;
		margin-bottom: 15px;
	}
	#shopify-product-reviews.spr-reviews--has-reviews .spr-summary {
		margin-bottom: -13px;
	}
	#shopify-product-reviews .spr-summary-actions {
		position: absolute;
		right: 0;
		top: 0.5em;
		width: auto;
	}
	#shopify-product-reviews .spr-review + .spr-review {
		margin-top: 41px;
	}
	#shopify-product-reviews .spr-review:first-child {
		margin-top: 35px;
	}
	#shopify-product-reviews .spr-review-header-title {
		margin-bottom: 12px;
	}
	#shopify-product-reviews .spr-review-content,
	#shopify-product-reviews .spr-review-reply {
		margin: 0 0 17px 0;
	}
	#shopify-product-reviews .spr-review-footer {
		text-align: right;
	}
	#shopify-product-reviews .spr-review-reportreview {
		font-size: 14px;
	}
	#shopify-product-reviews .spr-review-header-byline {
		bottom: 0;
		font-size: 15px;
	}
	#shopify-product-reviews .spr-pagination {
		margin-top: 30px;
		padding-top: 30px;
		border-top: 1px solid #e1e3e4;
	}
}
.rte {
	word-break: break-word;
}
.rte::after {
	content: "";
	display: block;
	clear: both;
}
.rte h1,
.rte h2,
.rte h3,
.rte h4,
.rte h5,
.rte h6 {
	margin: 1em 0;
	font-family: "DIN Next", sans-serif;
	font-weight: 500;
	font-style: normal;
	color: #1e2d7d;
}
.rte h1:first-child,
.rte h2:first-child,
.rte h3:first-child,
.rte h4:first-child,
.rte h5:first-child,
.rte h6:first-child {
	margin-top: 0;
}
.rte h1 {
	margin: 1.4em 0 0.7em 0;
}
.rte h2 {
	margin: 1.55em 0 0.6em 0;
}
.rte h3 {
	margin: 1.8em 0 0.8em 0;
}
.rte h4 {
	margin: 1.9em 0 0.6em 0;
}
.rte h5 {
	margin: 2.2em 0 0.7em 0;
}
.rte h6 {
	margin: 2.5em 0 0.6em 0;
}
.rte img {
	display: block;
}
.rte p:not(:last-child),
.rte ul:not(:last-child),
.rte ol:not(:last-child) {
	margin-bottom: 0.7em;
}
.rte a:not(.button) {
	color: #00badb;
	transition: color 0.2s ease-in-out;
	text-decoration: underline;
	text-underline-position: under;
}
.rte a:not(.button):hover {
	color: #00798e;
}
.rte p + .button {
	margin-top: 1em;
}
.rte ul,
.rte ol {
	margin-left: 18px;
}
.rte ul li,
.rte ol li {
	position: relative;
}
.rte ul li:not(:last-child),
.rte ol li:not(:last-child) {
	margin-bottom: 5px;
}
.rte ul {
	list-style: none;
}
.rte ul li::before {
	position: absolute;
	content: "";
	left: -18px;
	top: 0.8em;
	height: 5px;
	width: 5px;
	border-radius: 100%;
	background: rgba(103, 114, 121, 0.4);
}
.rte ol {
	list-style-position: outside;
}
.rte img,
.rte .video-wrapper {
	margin: 2.7em 0;
}
.rte blockquote {
	margin: 3em 0 3em 10px;
	padding-left: 20px;
	font-size: 17px;
	border-left: 5px solid rgba(103, 114, 121, 0.4);
}
.rte table {
	margin-top: 2em;
}
@media screen and (min-width: 641px) {
	.rte blockquote {
		padding-left: 30px;
		font-size: 19px;
	}
	.rte img,
	.rte .video-wrapper {
		margin: 3em 0;
	}
}
.section {
	margin: 50px 0;
}
.section--tight {
	margin: 30px 0;
}
.section--isolated {
	margin: 0 !important;
	padding: 50px 0;
}
.section--isolated.section--tight {
	padding: 40px 0;
}
.section--text-centered {
	text-align: center;
}
.section__header {
	display: flex;
	align-items: baseline;
	justify-content: space-between;
	margin-bottom: 15px;
	overflow: hidden;
}
.section__title {
	margin-bottom: 0;
}
.section__action-link {
	margin-left: 20px;
	flex-shrink: 0;
	font-weight: 500;
	color: #00badb;
}
.section__action-link svg {
	display: none;
}
.section__header-stack {
	display: flex;
	flex-direction: column;
}
.section--isolated .heading {
	color: inherit;
}
@media screen and (max-width: 640px) {
	.section__header-stack > .section__title + * {
		margin-top: 10px;
	}
}
@media screen and (min-width: 641px) {
	.section {
		margin: 60px 0;
	}
	.section--tight {
		margin: 40px 0;
	}
	.section--isolated {
		padding: 60px 0;
	}
	.section__header {
		overflow: visible;
		margin-bottom: 20px;
	}
	.section__header--tight {
		margin-bottom: 14px;
	}
	.section__header-stack {
		flex-direction: row;
		align-items: center;
	}
	.section__action-link {
		-webkit-transform: translateX(26px);
		transform: translateX(26px);
		transition: -webkit-transform 0.35s cubic-bezier(0.645, 0.045, 0.355, 1);
		transition: transform 0.35s cubic-bezier(0.645, 0.045, 0.355, 1);
		transition: transform 0.35s cubic-bezier(0.645, 0.045, 0.355, 1),
			-webkit-transform 0.35s cubic-bezier(0.645, 0.045, 0.355, 1);
	}
	.section__action-link svg {
		position: relative;
		display: inline-block;
		height: 14px;
		width: 14px;
		vertical-align: -2px;
		margin-left: 8px;
		right: -5px;
		opacity: 0;
		transition: all 0.35s cubic-bezier(0.645, 0.045, 0.355, 1);
	}
	.section__header-stack > .section__title {
		margin: 0 16px 0 0;
	}
}
@media (-moz-touch-enabled: 0), (hover: hover) {
	.section__action-link:hover {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
	.section__action-link:hover svg {
		opacity: 1;
		right: 0;
	}
}
.slideshow {
	height: 400px;
	overflow: hidden;
}
.slideshow--small {
	height: 350px;
}
.slideshow--large {
	height: 450px;
}
.slideshow__slide {
	position: relative;
	display: block;
	width: 100%;
	height: 100%;
}
.slideshow__slide-inner {
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center;
}
.slideshow:not(.flickity-enabled) .slideshow__slide:not(.is-selected) {
	display: none;
}
.slideshow__placeholder {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: -1;
}
.slideshow__image-placeholder {
	height: 100%;
	fill: dimgrey;
	background: darkgrey;
}
.slideshow__title {
	margin-bottom: 7px;
	color: inherit;
}
.slideshow__content-wrapper {
	position: relative;
	z-index: 1;
	width: 100%;
	padding-top: 40px;
	padding-bottom: 25px;
}
.slideshow__content {
	max-width: 650px;
	margin: 0 auto 22px auto;
}
.slideshow .flickity-page-dots {
	bottom: 30px;
}
.slideshow .flickity-page-dots .dot {
	width: 12px;
	height: 12px;
	margin: 0 8px;
	background: #ffffff;
	border: 3px solid #ffffff;
	-webkit-transform: none !important;
	transform: none !important;
	transition: background 0.25s ease-in-out;
}
.slideshow .flickity-page-dots .dot::before {
	top: -10px;
	right: -10px;
	left: -10px;
	bottom: -10px;
}
.slideshow .flickity-page-dots .dot.is-selected {
	background: transparent;
}
.slideshow__slide--overlay::before {
	position: absolute;
	content: "";
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
	z-index: 1;
}
.slideshow--preserve-ratio {
	height: auto !important;
}
.slideshow--preserve-ratio .slideshow__slide {
	height: auto;
}
.slideshow--preserve-ratio .slideshow__slide-inner {
	position: relative;
	display: block;
	width: auto;
	height: auto;
}
.slideshow--preserve-ratio .slideshow__content-wrapper {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
@media screen and (min-width: 641px) {
	.slideshow:not(.slideshow--edge2edge) {
		margin: 40px 0;
	}
	.slideshow:not(.slideshow--edge2edge) .flickity-viewport {
		border-radius: 3px;
	}
	.slideshow__image-placeholder {
		height: auto;
		width: 100%;
	}
	.slideshow__slide-inner--top-left,
	.slideshow__slide-inner--top-center,
	.slideshow__slide-inner--top-right {
		justify-content: flex-start;
	}
	.slideshow__slide-inner--bottom-left,
	.slideshow__slide-inner--bottom-center,
	.slideshow__slide-inner--bottom-right {
		justify-content: flex-end;
	}
	.slideshow__slide-inner--bottom-left .slideshow__content-wrapper,
	.slideshow__slide-inner--bottom-center .slideshow__content-wrapper,
	.slideshow__slide-inner--bottom-right .slideshow__content-wrapper {
		padding-bottom: 75px;
		padding-top: 25px;
	}
	.slideshow__slide-inner--top-left,
	.slideshow__slide-inner--middle-left,
	.slideshow__slide-inner--bottom-left {
		text-align: left;
	}
	.slideshow__slide-inner--top-left .slideshow__content,
	.slideshow__slide-inner--middle-left .slideshow__content,
	.slideshow__slide-inner--bottom-left .slideshow__content {
		margin-left: 0;
	}
	.slideshow__slide-inner--top-right,
	.slideshow__slide-inner--middle-right,
	.slideshow__slide-inner--bottom-right {
		text-align: right;
	}
	.slideshow__slide-inner--top-right .slideshow__content,
	.slideshow__slide-inner--middle-right .slideshow__content,
	.slideshow__slide-inner--bottom-right .slideshow__content {
		margin-right: 0;
	}
	.slideshow--preserve-ratio
		.slideshow__slide-inner--top-left
		.slideshow__content-wrapper,
	.slideshow--preserve-ratio
		.slideshow__slide-inner--top-center
		.slideshow__content-wrapper,
	.slideshow--preserve-ratio
		.slideshow__slide-inner--top-right
		.slideshow__content-wrapper {
		top: 0;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
	}
	.slideshow--preserve-ratio
		.slideshow__slide-inner--bottom-left
		.slideshow__content-wrapper,
	.slideshow--preserve-ratio
		.slideshow__slide-inner--bottom-center
		.slideshow__content-wrapper,
	.slideshow--preserve-ratio
		.slideshow__slide-inner--bottom-right
		.slideshow__content-wrapper {
		top: auto;
		bottom: 0;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
	}
}
@media screen and (min-width: 1000px) {
	.slideshow {
		height: 430px;
	}
	.slideshow--small {
		height: 380px;
	}
	.slideshow--large {
		height: 520px;
	}
}
.team {
	margin-top: 45px;
	margin-bottom: 62px;
	text-align: center;
}
.team__block-list {
	margin-bottom: 45px;
}
.team__block-item {
	margin-bottom: 20px;
}
@media screen and (min-width: 641px) {
	.team {
		margin-top: 62px;
		margin-bottom: 80px;
	}
	.team__block-list {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin: -15px -15px 47px -15px;
	}
	.team__block-item {
		margin: 15px;
		flex: none;
		width: calc(50% - 30px);
	}
}
@media screen and (min-width: 1000px) {
	.team__block-item {
		width: calc(33.33333% - 30px);
	}
}
@media screen and (min-width: 1280px) {
	.team__block-item {
		width: calc(25% - 30px);
	}
}
.team__section-title {
	margin-bottom: 25px;
}
@media screen and (min-width: 641px) {
	.team__section-title {
		margin-bottom: 40px;
	}
}
.team__member {
	padding: 30px 20px 20px 20px;
	border: 1px solid #e1e3e4;
	border-radius: 3px;
	background: #fff;
}
.team__member-pic {
	width: 144px;
	margin-bottom: 20px;
	border: 1px solid #e1e3e4;
	padding: 8px;
	background: #f3f5f6;
}
.team__member-pic--rounded {
	border-radius: 50%;
}
.team__member-title,
.team__member-subheading {
	margin-bottom: 0;
	line-height: 1.4;
}
.team__member-title {
	margin-bottom: 6px;
}
.team__member-link {
	display: inline-block;
	margin-top: 6px;
}
.team__promotion {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 3px;
	padding: 30px;
}
.team__promotion .heading {
	color: inherit;
	margin-bottom: 8px;
}
.team__promotion .button {
	min-width: 105px;
	margin-top: 22px;
}
.text-with-icons__item {
	width: 100%;
	line-height: 1.6;
}
.text-with-icons__title {
	margin-bottom: 4px;
}
.text-with-icons__icon-wrapper {
	margin-bottom: 15px;
}
.text-with-icons__icon-wrapper svg {
	width: 30px;
	height: 30px;
	vertical-align: middle;
}
.text-with-icons__icon-wrapper img {
	max-width: 30px;
}
.text-with-icons__content > p {
	margin-bottom: 0;
}
@media screen and (max-width: 640px) {
	.text-with-icons {
		padding-bottom: 28px;
		text-align: center;
	}
	.text-with-icons::after {
		content: "flickity";
		display: none;
	}
	.text-with-icons--stacked {
		padding-bottom: 0;
	}
	.text-with-icons--stacked .text-with-icons__item:not(:last-child) {
		margin-bottom: 34px;
	}
	.text-with-icons--stacked::after {
		content: "";
	}
}
@media screen and (min-width: 641px) {
	.text-with-icons {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -40px -45px -40px;
	}
	.text-with-icons__item {
		display: flex;
		flex-basis: calc(50% - 90px);
		margin: 0 40px 45px 40px;
	}
	.text-with-icons__icon-wrapper {
		margin: 0.5em 18px 0 0;
	}
}
@media screen and (min-width: 1000px) {
	.text-with-icons {
		padding: 0 100px;
		justify-content: center;
	}
	.text-with-icons__item {
		display: flex;
		flex-basis: calc(50% - 140px);
	}
}
@media screen and (min-width: 1280px) {
	.text-with-icons {
		padding: 0;
		margin: 0 -35px;
		flex-wrap: nowrap;
		justify-content: space-around;
	}
	.text-with-icons__item {
		flex-basis: calc(25% - 70px);
		margin: 0 35px;
	}
}
.text-with-icons--boxed {
	display: block;
	text-align: center;
	padding-bottom: 0;
	border: 1px solid #e1e3e4;
	border-radius: 3px;
}
.text-with-icons--boxed .text-with-icons__item {
	display: block;
	text-align: center;
	padding: 28px 35px;
}
.text-with-icons--boxed .text-with-icons__item:not(:first-child) {
	border-top: 1px solid #e1e3e4;
}
.text-with-icons--boxed .text-with-icons__icon-wrapper {
	margin: 0 0 1em 0;
}
@media screen and (min-width: 641px) {
	.text-with-icons--boxed {
		display: flex;
		flex-wrap: nowrap;
		margin: 0;
		padding: 0;
	}
	.text-with-icons--boxed .text-with-icons__item {
		margin: 0;
		flex: 1 0 0;
	}
	.text-with-icons--boxed .text-with-icons__item:not(:first-child) {
		border-top: none;
		border-left: 1px solid #e1e3e4;
	}
}
.clearfix::before,
.clearfix::after {
	display: table;
	content: "";
}
.list--unstyled {
	list-style: none;
}
.collapsible {
	overflow: hidden;
	height: 0;
	transition: height 0.2s ease-in-out;
}
.social-media__item-list {
	display: flex;
	flex-wrap: wrap;
	margin: -5px;
}
.social-media__item {
	display: inline-block;
	margin: 5px;
}
.social-media__item > a {
	display: block;
}
.social-media__item svg {
	width: 28px;
	height: 28px;
	opacity: 0.4;
	transition: color 0.25s ease-in-out, opacity 0.25s ease-in-out;
	will-change: opacity;
}
.social-media__item-list--stack {
	display: block;
	margin: -10px 0;
}
.social-media__item-list--stack .social-media__item {
	display: block;
	margin: 0;
	padding: 7px 0;
}
.social-media__item-list--stack svg {
	vertical-align: top;
	margin-right: 12px;
}
@media (-moz-touch-enabled: 0), (hover: hover) {
	.social-media__item:hover svg {
		opacity: 1;
	}
	.social-media__item--facebook:hover svg {
		color: #3b5998;
	}
	.social-media__item--twitter:hover svg {
		color: #1da1f2;
	}
	.social-media__item--pinterest:hover svg {
		color: #bd081c;
	}
	.social-media__item--instagram:hover svg {
		color: #d83776;
	}
	.social-media__item--youtube:hover svg {
		color: #ff0000;
	}
	.social-media__item--vimeo:hover svg {
		color: #1ab7ea;
	}
	.social-media__item--linkedin:hover svg {
		color: #0077b5;
	}
	.social-media__item--snapchat:hover svg {
		color: #f5dc30;
	}
	.social-media__item--tumblr:hover svg {
		color: #35465c;
	}
	.social-media__item--fancy:hover svg {
		color: #494e58;
	}
	.social-media__item--rss:hover svg {
		color: #f26522;
	}
}
.alert {
	display: block;
	padding: 12px 20px;
	margin-bottom: 20px;
	border-radius: 2px;
	white-space: normal;
	font-size: 1rem;
	word-break: break-all;
	word-break: break-word;
	background: #f3f5f6;
	color: #1e2d7d;
	text-align: left;
}
.alert:last-child {
	margin-bottom: 0;
}
.alert--block {
	display: block;
}
.alert--center {
	text-align: center;
}
.alert--tight {
	padding: 6px 14px;
}
.alert--error {
	background: rgba(255, 0, 0, 0.07);
	color: red;
}
.alert--error a:hover {
	color: #c00;
}
.alert--success {
	background: rgba(0, 194, 0, 0.11);
	color: #00c200;
}
.alert--success a:hover {
	color: #008f00;
}
.alert__ribbon {
	margin-left: 5px;
	margin-right: 25px;
	align-self: center;
}
.alert__ribbon svg {
	display: block;
	width: 24px;
	height: 24px;
}
.alert__error-list {
	list-style: none;
}
.alert a {
	text-decoration: underline;
	transition: color 0.2s ease-in-out;
}
.pagination {
	padding: 40px 0;
	color: #1e2d7d;
	font-weight: 500;
	font-size: 15px;
	border-top: 1px solid #e1e3e4;
}
.pagination__inner {
	position: relative;
}
.pagination__prev,
.pagination__next {
	position: absolute;
	display: flex;
	align-items: center;
	top: 0;
	height: 100%;
}
.pagination__prev svg,
.pagination__next svg {
	position: relative;
	width: 8px;
	height: 12px;
}
.pagination__prev {
	left: 0;
}
.pagination__prev svg {
	margin-right: 14px;
}
.pagination__next {
	right: 0;
}
.pagination__next svg {
	margin-left: 14px;
}
.pagination__nav,
.pagination__page-count {
	display: block;
	text-align: center;
}
.pagination__nav {
	display: none;
}
.pagination__nav-item {
	display: inline-block;
	margin: 0 2px;
	padding: 8px 11px;
	line-height: 1;
}
.pagination__nav-item.is-active {
	background: #00badb;
	color: #fff;
	border-radius: 3px;
}
.card .pagination {
	padding: 20px;
}
@media screen and (min-width: 641px) {
	.pagination__nav {
		display: block;
	}
	.pagination__page-count {
		display: none;
	}
	.card .pagination {
		padding: 20px 30px;
	}
}
@media screen and (max-width: 999px) {
	.scroller {
		overflow: hidden;
		margin: 0 -20px;
	}
	.scroller--flush {
		margin: 0 !important;
	}
	.scroller__inner {
		padding-bottom: 20px;
		margin-bottom: -20px;
		overflow-x: auto;
		overflow-y: hidden;
		-webkit-overflow-scrolling: touch;
		-ms-scroll-snap-type: x mandatory;
		scroll-snap-type: x mandatory;
		white-space: nowrap;
	}
}
@media screen and (min-width: 641px) and (max-width: 999px) {
	.scroller {
		margin: 0 -40px;
	}
	.scroller__inner {
		scroll-padding-left: 40px;
	}
	.scroller--mobile-only {
		margin: 0;
		overflow: visible;
	}
	.scroller--mobile-only > .scroller__inner {
		padding-bottom: 0;
		margin-bottom: 0;
		overflow: visible;
	}
}
@-webkit-keyframes skeletonShimmerAnimation {
	0% {
		opacity: 0.45;
	}
	100% {
		opacity: 0.9;
	}
}
@keyframes skeletonShimmerAnimation {
	0% {
		opacity: 0.45;
	}
	100% {
		opacity: 0.9;
	}
}
.skeleton-container {
	-webkit-animation: skeletonShimmerAnimation 1s linear infinite alternate;
	animation: skeletonShimmerAnimation 1s linear infinite alternate;
	will-change: opacity;
}
.skeleton-text {
	height: 10px;
	width: 100%;
	background: #e1e3e4;
}
.skeleton-image {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: #e1e3e4;
}
.skeleton-paragraph .skeleton-text {
	margin-bottom: 8px;
}
.skeleton-paragraph .skeleton-text:last-child {
	width: 38%;
	margin-bottom: 0;
}
.plus-button {
	display: block;
	position: relative;
	right: 0;
	top: calc(50% - 5px);
	width: 10px;
	height: 10px;
}
.plus-button::before,
.plus-button::after {
	position: absolute;
	content: "";
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%) rotate(-90deg);
	transform: translate(-50%, -50%) rotate(-90deg);
	background-color: currentColor;
	transition: opacity 0.35s ease-in-out, -webkit-transform 0.35s ease-in-out;
	transition: transform 0.35s ease-in-out, opacity 0.35s ease-in-out;
	transition: transform 0.35s ease-in-out, opacity 0.35s ease-in-out,
		-webkit-transform 0.35s ease-in-out;
}
.plus-button::before {
	width: 10px;
	height: 2px;
	opacity: 1;
}
.plus-button::after {
	width: 2px;
	height: 10px;
}
[aria-expanded="true"] .plus-button::before,
[aria-expanded="true"] .plus-button::after {
	-webkit-transform: translate(-50%, -50%) rotate(90deg);
	transform: translate(-50%, -50%) rotate(90deg);
}
[aria-expanded="true"] .plus-button::before {
	opacity: 0;
}
@media screen and (min-width: 641px) {
	.plus-button--large {
		width: 14px;
		height: 14px;
	}
	.plus-button--large::before {
		width: 14px;
	}
	.plus-button--large::after {
		height: 14px;
	}
}
.shopify-challenge__container {
	margin-top: 50px;
	margin-bottom: 50px;
	text-align: center;
}
.shopify-challenge__button.shopify-challenge__button {
	position: relative;
	display: inline-block;
	padding: 9px 30px;
	min-height: 48px;
	line-height: normal;
	border-color: #00badb;
	border-radius: 2px;
	text-align: center;
	font-weight: 500;
	font-size: 16px;
	cursor: pointer;
	transition: background 0.25s ease-in-out, color 0.25s ease-in-out,
		border 0.25s ease-in-out;
	background: #00badb;
	color: #fff;
}
.shopify-challenge__button.shopify-challenge__button:hover {
	background: #00869e;
	border-color: #00869e;
}
@media screen and (min-width: 641px) {
	.shopify-challenge__container {
		margin-top: 100px;
		margin-bottom: 100px;
	}
}
.shopify-policy__container {
	max-width: 800px;
}
.shopify-policy__title {
	margin: 30px 0;
}
.shopify-policy__title h1 {
	margin-bottom: 0;
	font-family: "DIN Next", sans-serif;
	font-weight: 500;
	font-style: normal;
	font-size: 25px;
	line-height: 1.45;
	color: #1e2d7d;
}
@media screen and (min-width: 641px) {
	.shopify-policy__title {
		margin: 30px 0;
	}
	.shopify-policy__title h1 {
		font-size: 29px;
		line-height: 1.45;
	}
}
.announcement-bar {
	position: relative;
	display: block;
	font-weight: 500;
	font-size: 13px;
	border-bottom: 1px solid rgba(163, 175, 239, 0.3);
}
.announcement-bar__content {
	display: block;
	padding: 4px 0;
	margin-bottom: 0;
}
.announcement-bar__content--center {
	text-align: center;
}
@media screen and (max-width: 640px) {
	.announcement-bar__inner {
		text-align: center;
	}
}
@media screen and (min-width: 641px) {
	.announcement-bar {
		font-size: 15px;
		text-align: left;
	}
	.announcement-bar__inner {
		display: flex;
		align-items: center;
	}
	.announcement-bar__content {
		padding: 7px 0;
		margin-right: auto;
	}
	.announcement-bar__content--center {
		margin-left: auto;
		padding-left: var(--announcement-bar-button-width, 0px);
	}
	.announcement-bar__button {
		padding: 7px 18px 7px 15px;
		background: #00badb;
		color: #fff;
		box-shadow: 0 1px #00badb;
	}
	.announcement-bar__button svg {
		display: inline-block;
		width: 20px;
		height: 17px;
		margin-right: 12px;
		vertical-align: text-bottom;
	}
	.announcement-bar__close-container {
		position: relative;
	}
	.announcement-bar__close {
		position: absolute;
		top: 25px;
		right: 0;
		color: #1e2d7d;
		transition: color 0.2s ease-in-out;
	}
	.announcement-bar__close:hover {
		color: #00badb;
	}
	.announcement-bar__close svg {
		width: 19px;
		height: 19px;
	}
	.announcement-bar__newsletter {
		height: 0;
		overflow: hidden;
		visibility: hidden;
		text-align: center;
		transition: height 0.5s cubic-bezier(0.77, 0, 0.175, 1),
			visibility 0.5s cubic-bezier(0.77, 0, 0.175, 1);
	}
	.announcement-bar__newsletter[aria-hidden="false"] {
		visibility: visible;
	}
	.announcement-bar__newsletter-inner {
		margin: 50px 0;
	}
}
.offer-item {
	padding: 20px;
	background: #fff;
	border: 1px solid #e1e3e4;
	border-radius: 3px;
}
.offer-item__image-wrapper {
	min-width: 70px;
	max-width: 70px;
	margin-bottom: 14px;
}
.offer-item__title {
	margin-bottom: 6px;
	font-size: 18px;
}
.offer-item__content {
	font-size: 15px;
}
@media screen and (min-width: 641px) {
	.offer-item {
		padding: 28px 30px;
	}
	.offer-item__image-wrapper {
		min-width: 110px;
		max-width: 110px;
	}
}
@media screen and (min-width: 1280px) {
	.offer-item--inline {
		display: flex;
		align-items: center;
	}
	.offer-item--inline .offer-item__image-wrapper {
		margin: 0 30px 0 0;
	}
}
.image-with-text {
	display: flex;
	flex-direction: column;
}
.image-with-text__image-container {
	margin-bottom: 25px;
}
@media screen and (max-width: 999px) {
	.image-with-text__image-container {
		width: 100% !important;
		order: -1;
	}
}
@media screen and (min-width: 1000px) {
	.image-with-text {
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
	.image-with-text__image-container {
		max-width: 50%;
		min-width: 30%;
	}
	.image-with-text__image-container,
	.image-with-text__text-container {
		width: 50%;
		margin: 0;
	}
	.image-with-text__text-container:last-child {
		padding-left: 60px;
	}
	.image-with-text__text-container:first-child {
		text-align: right;
		padding-right: 60px;
	}
	.image-with-text__text-container:first-child .image-with-text__text-aligner {
		display: inline-block;
		text-align: left;
	}
}
@media screen and (min-width: 1000px) {
	.image-with-text__image-container {
		max-width: none;
		min-width: 0;
	}
}
@media screen and (min-width: 1280px) {
	.image-with-text__text-container:last-child {
		padding-left: 75px;
	}
	.image-with-text__text-container:first-child {
		padding-right: 75px;
	}
}
.newsletter {
	margin-top: 30px;
}
.newsletter__form {
	padding-bottom: 10px;
}
.newsletter__text {
	margin-top: 16px;
}
.newsletter--compact .newsletter__form {
	padding-bottom: 0;
}
.newsletter--compact .newsletter__text {
	margin-top: 16px;
}
.quick-links {
	display: flex;
	flex-wrap: wrap;
	text-align: center;
	border-radius: 3px;
	list-style: none;
	background: #fff;
	border: 1px solid #e1e3e4;
	font-size: 16px;
}
.quick-links__link {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 0 0 auto;
	width: 50%;
	box-shadow: 1px 0 #e1e3e4, 0 1px #e1e3e4;
	padding: 20px 10px;
	word-break: break-all;
	word-break: break-word;
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
	text-transform: none;
	transition: color 0.2s ease-in-out;
}
.quick-links__link:hover {
	color: #00badb;
}
.quick-links__link--grow {
	flex-grow: 1;
}
.quick-links__image-container {
	display: block;
	margin: 0 auto;
	flex: 1 1 auto;
	font-size: 0;
}
.quick-links__image-ie-fix {
	margin: 0 auto;
	transition: -webkit-transform 0.3s ease-in-out;
	transition: transform 0.3s ease-in-out;
	transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}
@media (-moz-touch-enabled: 0), (hover: hover) {
	.quick-links__link:hover .quick-links__image-ie-fix {
		-webkit-transform: scale(1.05);
		transform: scale(1.05);
	}
}
@media screen and (min-width: 641px) {
	.quick-links__link {
		padding: 24px;
		width: 33.333333%;
	}
}
@media screen and (min-width: 1000px) {
	.quick-links__link {
		width: 16.66666667%;
	}
}
.breadcrumb__list {
	display: flex;
	align-items: center;
	list-style: none;
}
.breadcrumb__list svg {
	margin: 0 7px;
	width: 6px;
	height: 8px;
	vertical-align: inherit;
}
.loading-bar {
	position: fixed;
	top: 0;
	left: 0;
	height: 3px;
	width: 0;
	opacity: 0;
	background: #00badb;
	transition: width 0.25s ease-in-out;
	z-index: 50;
	pointer-events: none;
}
.loading-bar.is-visible {
	opacity: 1;
}
.empty-state {
	margin: 100px 0;
	text-align: center;
}
.empty-state--extra-tight {
	margin: 30px 0;
}
.empty-state--tight {
	margin: 40px 0;
}
.empty-state__heading {
	margin-bottom: 15px;
}
.empty-state__text {
	margin-bottom: 32px;
	font-size: 16px;
}
.empty-state__quick-form {
	max-width: 410px;
	margin: 40px auto 25px auto;
}
.empty-state__button-container {
	margin-top: 16px;
}
.empty-state__button {
	min-width: 230px;
}
.empty-state__icon {
	position: relative;
	display: inline-block;
	margin-bottom: 6px;
}
.empty-state__icon .icon--package {
	width: 46px;
	height: 46px;
}
.empty-state__icon .icon--address {
	width: 46px;
	height: 45px;
}
.empty-state__icon-description {
	margin-bottom: 30px;
}
.empty-state__count {
	position: absolute;
	top: 2px;
	right: -9px;
	height: 20px;
	width: 20px;
	line-height: 20px;
	font-size: 13px;
	font-weight: 500;
	text-align: center;
	background: #00badb;
	color: #fff;
	border-radius: 100%;
}
@media screen and (min-width: 641px) {
	.empty-state {
		margin: 170px 0;
	}
	.empty-state--extra-tight {
		margin: 40px 0;
	}
	.empty-state--tight {
		margin: 105px 0;
	}
}
.payment-list {
	display: flex;
	flex-wrap: wrap;
	margin: -4px;
}
.payment-list__item {
	width: 38px;
	height: 24px;
	margin: 4px;
}
.payment-list__notice {
	margin-top: 18px;
}
.payment-list--centered {
	justify-content: center;
}
.layout {
	margin-bottom: 70px;
}
.layout .layout {
	margin-top: 18px;
	margin-bottom: 0;
}
.layout__section {
	margin-bottom: 18px;
}
.layout .card:last-child {
	margin-bottom: 0;
}
@media screen and (max-width: 999px) {
	.layout--reverse {
		display: flex;
		flex-direction: column-reverse;
	}
}
@media screen and (min-width: 641px) {
	.layout {
		margin-bottom: 100px;
	}
	.layout .layout {
		margin-top: 30px;
	}
}
@media screen and (min-width: 1000px) {
	.layout {
		display: flex;
		margin-left: -15px;
		margin-right: -15px;
	}
	.layout--pad {
		padding-top: 25px;
	}
	.layout__section {
		margin: 0 15px;
		flex-grow: 1;
		flex-shrink: 0;
		flex-basis: 0;
	}
	.layout__section:only-child {
		max-width: 680px;
		margin-left: auto;
		margin-right: auto;
	}
	.layout__section--secondary {
		flex: none;
		width: 260px;
	}
	.layout__section--large-secondary {
		flex: none;
		width: 320px;
	}
}
@media screen and (min-width: 1280px) {
	.layout__section--secondary {
		width: 288px;
	}
	.layout__section--large-secondary {
		width: 365px;
	}
}
.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	z-index: 10;
	visibility: hidden;
	pointer-events: none;
	opacity: 0;
	transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
}
.modal::before {
	position: fixed;
	content: "";
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.35);
	pointer-events: none;
}
.modal[aria-hidden="false"] {
	visibility: visible;
	opacity: 1;
	pointer-events: auto;
}
.modal[aria-hidden="false"] .modal__dialog {
	-webkit-transform: scale(1);
	transform: scale(1);
	transition: visibility 0.4s linear,
		-webkit-transform 0.4s cubic-bezier(0.18, 1.25, 0.4, 1);
	transition: transform 0.4s cubic-bezier(0.18, 1.25, 0.4, 1),
		visibility 0.4s linear;
	transition: transform 0.4s cubic-bezier(0.18, 1.25, 0.4, 1),
		visibility 0.4s linear,
		-webkit-transform 0.4s cubic-bezier(0.18, 1.25, 0.4, 1);
}
.modal__dialog {
	position: absolute;
	width: 100%;
	height: 100%;
	max-height: 100vh;
	background: #fff;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	-webkit-transform: scale(0.9);
	transform: scale(0.9);
	transition: visibility 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
	transition: transform 0.25s ease-in-out, visibility 0.25s ease-in-out;
	transition: transform 0.25s ease-in-out, visibility 0.25s ease-in-out,
		-webkit-transform 0.25s ease-in-out;
}
.modal__loader {
	display: none;
	padding: 60px 0;
	text-align: center;
}
.modal__loader .icon--search-loader {
	width: 35px;
	height: 35px;
	color: #00badb;
}
.modal__header {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #e1e3e4;
	background: #fff;
	z-index: 1;
}
.modal__header,
.modal__content {
	padding: 20px;
}
.modal__header + .modal__content {
	padding-top: 30px;
}
.modal__title {
	margin-bottom: 0;
	text-align: center;
}
.modal__description {
	display: block;
	margin-bottom: 30px;
	text-align: center;
}
.modal__close {
	color: #1e2d7d;
	z-index: 1;
}
.modal__close svg {
	display: block;
	width: 20px;
	height: 20px;
}
.modal .card {
	background: none;
}
.modal.is-loading .modal__loader {
	display: block;
}
.modal.is-loading .modal__inner {
	display: none;
}
@media screen and (max-width: 640px) {
	.modal__content--ios-push {
		padding-bottom: 45px;
	}
}
@media screen and (min-width: 641px) {
	.modal {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.modal__dialog {
		width: 580px;
		height: auto;
		max-height: calc(100% - 80px);
		border-radius: 3px;
	}
	.modal__dialog--stretch {
		width: calc(100% - 80px);
		margin-left: auto;
		margin-right: auto;
		max-width: 1200px;
	}
	.modal__header,
	.modal__content {
		padding: 30px;
	}
	.modal__header {
		position: relative;
		display: block;
		padding-bottom: 0;
		text-align: center;
		border-bottom: none;
	}
	.modal__close {
		position: absolute;
		right: 28px;
		top: 28px;
	}
	.modal__description {
		margin-top: -18px;
	}
}
.icon-state {
	position: relative;
	vertical-align: middle;
}
.icon-state .icon-state__primary,
.icon-state .icon-state__secondary {
	display: block;
	transition: opacity 0.35s ease-in-out, -webkit-transform 0.35s ease-in-out;
	transition: opacity 0.35s ease-in-out, transform 0.35s ease-in-out;
	transition: opacity 0.35s ease-in-out, transform 0.35s ease-in-out,
		-webkit-transform 0.35s ease-in-out;
}
.icon-state .icon-state__secondary {
	position: absolute;
	top: 50%;
	left: 0;
	opacity: 0;
	-webkit-transform: translateY(-50%) scale(0.3);
	transform: translateY(-50%) scale(0.3);
}
.icon-state[aria-expanded="true"] .icon-state__primary {
	opacity: 0;
	-webkit-transform: scale(0.3);
	transform: scale(0.3);
}
.icon-state[aria-expanded="true"] .icon-state__secondary {
	opacity: 1;
	-webkit-transform: translateY(-50%) scale(1);
	transform: translateY(-50%) scale(1);
}
@media (-moz-touch-enabled: 0), (hover: hover) {
	[data-tooltip] {
		position: relative;
	}
	[data-tooltip]::before {
		position: absolute;
		content: attr(data-tooltip);
		bottom: calc(100% + 3px);
		left: 50%;
		padding: 2px 11px;
		white-space: nowrap;
		background: #00badb;
		color: #fff;
		font-size: 14px;
		pointer-events: none;
		visibility: hidden;
		opacity: 0;
		border-radius: 3px;
		box-shadow: 0 1px rgba(0, 0, 0, 0.065);
		transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out;
		z-index: 1;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
	}
	[data-tooltip]::after {
		position: absolute;
		content: "";
		left: calc(50% - 7px);
		bottom: calc(100% - 2px);
		width: 0;
		height: 0;
		border-width: 6px;
		border-style: solid;
		border-color: transparent transparent #00badb #00badb;
		visibility: hidden;
		z-index: 1;
		opacity: 0;
		transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out;
		-webkit-transform: rotate(-45deg);
		transform: rotate(-45deg);
		box-shadow: -1px 1px 1px 0 rgba(0, 0, 0, 0.1);
	}
	[data-tooltip]:hover::before,
	[data-tooltip]:hover::after {
		opacity: 1;
		visibility: visible;
	}
	[data-tooltip-position="bottom-left"]::before {
		top: calc(100% + 4px);
		bottom: auto;
		left: auto;
		right: -6px;
		-webkit-transform: none;
		transform: none;
	}
	[data-tooltip-position="bottom-left"]::after {
		top: calc(100% - 1px);
		-webkit-transform: rotate(135deg);
		transform: rotate(135deg);
		left: calc(50% - 6px);
	}
}
.quantity-selector {
	display: inline-flex;
	align-items: center;
	height: 38px;
	font-size: 16px;
	border: 1px solid #e1e3e4;
	border-radius: 3px;
	box-shadow: 0 1px 1px rgba(225, 227, 228, 0.2);
	vertical-align: middle;
}
.quantity-selector svg:hover {
	opacity: 1;
}
.quantity-selector .icon--minus {
	width: 10px;
	height: 2px;
}
.quantity-selector .icon--plus {
	width: 10px;
	height: 10px;
}
.quantity-selector__button {
	display: flex;
	align-items: center;
	padding: 0 12px;
	color: rgba(103, 114, 121, 0.6);
	height: 100%;
	transition: color 0.2s ease-in-out;
}
.quantity-selector__button:not([disabled]):hover {
	color: #1e2d7d;
}
.quantity-selector__value {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	padding: 0 5px;
	min-width: 32px;
	text-align: center;
	border: none;
}
.quantity-selector__value:focus {
	outline: none;
}
.shipping-estimator__results {
	margin: 24px 0 2px 0;
	font-size: 1rem;
}
.shipping-estimator__results p {
	margin-bottom: 6px;
}
.shipping-estimator__results ul {
	color: #1e2d7d;
	font-weight: 500;
}
.cookie-bar {
	position: fixed;
	bottom: 20px;
	left: 0;
	visibility: hidden;
	opacity: 0;
	-webkit-transform: translateY(100%);
	transform: translateY(100%);
	width: 100%;
	z-index: 3;
	font-size: 12px;
	transition: opacity 0.35s ease-in-out, visibility 0.35s ease-in-out,
		-webkit-transform 0.35s ease-in-out;
	transition: transform 0.35s ease-in-out, opacity 0.35s ease-in-out,
		visibility 0.35s ease-in-out;
	transition: transform 0.35s ease-in-out, opacity 0.35s ease-in-out,
		visibility 0.35s ease-in-out, -webkit-transform 0.35s ease-in-out;
}
.cookie-bar[aria-hidden="false"] {
	visibility: visible;
	-webkit-transform: translateY(0);
	transform: translateY(0);
	opacity: 1;
}
.cookie-bar__inner {
	max-width: 960px;
	margin: 0 auto;
	padding: 15px;
	z-index: 4;
	border-radius: 3px;
	background: #fff;
	box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.1);
}
.cookie-bar__text {
	line-height: 1.45;
}
.cookie-bar__button {
	margin-top: 12px;
	padding: 0 16px;
	line-height: 30px;
	font-size: 13px;
}
@media screen and (min-width: 641px) {
	.cookie-bar {
		bottom: 25px;
		font-size: 14px;
	}
	.cookie-bar__inner {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 20px 30px 20px 25px;
	}
	.cookie-bar__text {
		line-height: 1.25;
	}
	.cookie-bar__button {
		flex: none;
		margin-left: 25px;
		margin-top: 0;
		padding: 0 18px;
		line-height: 36px;
		font-size: 14px;
	}
}
.contact {
	margin-bottom: 40px;
}
.contact__store-info {
	margin-top: 50px;
}
.contact__store-heading,
.contact__store-image {
	margin-bottom: 30px;
}
@media screen and (min-width: 641px) {
	.contact {
		margin-bottom: 90px;
	}
}
@media screen and (min-width: 1000px) {
	.contact__store-info {
		margin-top: 0;
		margin-left: 25px;
	}
}
.password {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 100vh;
	padding: 20px 0;
}
@supports (--css: variables) {
	.password {
		height: calc(100vh - var(--header-height, 0px));
	}
}
.password__content {
	display: flex;
	align-items: center;
	flex: 1 0 auto;
	text-align: center;
}
.password__footer {
	font-size: 14px;
	text-align: center;
}
.password__footer-social {
	display: flex;
	justify-content: center;
	margin-top: 15px;
}
.password__social-text {
	margin-right: 20px;
}
.password__powered-by a {
	display: inline-block;
	margin-left: 4px;
	vertical-align: bottom;
}
.password__powered-by svg {
	width: 98px;
	height: 28px;
}
.password__admin-link {
	display: block;
	margin-left: 18px;
}
@media screen and (min-width: 1000px) {
	.template-password .header {
		padding-top: 30px;
		padding-bottom: 30px;
	}
	.password {
		padding: 40px 0;
	}
	.password__footer {
		text-align: left;
	}
	.password__footer--with-social {
		display: flex;
		width: 100%;
		justify-content: space-between;
	}
	.password__admin-link {
		display: inline-block;
	}
	.password__footer-social {
		margin-top: 0;
	}
}
.faq {
	margin: 40px 0 70px 0;
}
.faq__group {
	margin-bottom: 30px;
}
.faq__group-title {
	margin-bottom: 20px;
	text-align: center;
}
.faq__item {
	padding: 20px 20px;
	background: #fff;
	border-top: 1px solid #e1e3e4;
	border-bottom: 1px solid #e1e3e4;
}
.faq__item + .faq__item {
	border-top: none;
}
.faq__question {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	text-align: left;
}
.faq__question .plus-button {
	margin-left: 40px;
}
.faq__answer-wrapper {
	overflow: hidden;
	height: 0;
	transition: height 0.2s ease-in-out;
}
.faq__answer {
	margin: 10px 30px 0 0;
}
.faq__contact-info {
	margin-top: 50px;
}
.faq__contact-info .page__header {
	margin: 30px 0;
}
.faq__contact-info .page__description {
	margin-top: 18px;
}
@media screen and (min-width: 641px) {
	.faq {
		margin: 70px 0 115px 0;
	}
	.faq__group {
		margin-bottom: 50px;
	}
	.faq__group-title {
		margin-bottom: 25px;
		margin-left: 0;
	}
	.faq__item {
		padding: 20px 30px;
		border: 1px solid #e1e3e4;
		border-radius: 3px 3px 0 0;
	}
	.faq__item + .faq__item {
		border-radius: 0;
	}
	.faq__item:last-child {
		border-radius: 0 0 3px 3px;
	}
}
@media screen and (min-width: 1000px) {
	.faq__group {
		margin-bottom: 0;
	}
	.faq__group:first-child .faq__group-title {
		margin-top: 10px;
	}
	.faq__group + .faq__group {
		margin-top: 50px;
	}
}
.modal--newsletter {
	bottom: 0;
	top: auto;
	height: auto;
}
.modal--newsletter .modal__dialog {
	position: relative;
	height: auto;
	padding: 48px 20px 20px 20px;
}
.popup-newsletter__title {
	text-align: center;
	margin-bottom: 10px;
	font-size: 21px;
}
.popup-newsletter__close {
	position: absolute;
	right: 20px;
	top: 16px;
	color: #1e2d7d;
}
.popup-newsletter__close svg {
	width: 15px;
	height: 15px;
}
.popup-newsletter__content {
	text-align: center;
}
.popup-newsletter__form {
	margin-top: 20px;
}
@media screen and (min-width: 641px) {
	.modal--newsletter {
		height: 100%;
	}
	.modal--newsletter .modal__dialog {
		max-width: 540px;
		padding: 64px 80px 80px 80px;
	}
	.popup-newsletter__close {
		position: absolute;
		right: 30px;
		top: 24px;
	}
	.popup-newsletter__close svg {
		width: 20px;
		height: 20px;
	}
	.popup-newsletter__title {
		font-size: 29px;
	}
	.popup-newsletter__form {
		margin-top: 30px;
	}
}
.modal--exit-popup {
	bottom: 0;
	top: auto;
	height: auto;
}
.modal--exit-popup .modal__dialog {
	position: relative;
	height: auto;
	padding: 48px 20px 20px 20px;
}
.exit-popup__title {
	text-align: center;
	margin-bottom: 8px;
	font-size: 29px;
	line-height: 1.2;
}
.exit-popup__subheading {
	text-align: center;
	font-size: 19px;
}
.exit-popup__close {
	position: absolute;
	right: 20px;
	top: 16px;
	color: #1e2d7d;
}
.exit-popup__close svg {
	width: 15px;
	height: 15px;
}
.exit-popup__form {
	margin-top: 20px;
}
.exit-popup__pay-more {
	display: block;
	margin-top: 20px;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
}
@media screen and (min-width: 641px) {
	.modal--exit-popup {
		height: 100%;
	}
	.modal--exit-popup .modal__dialog {
		max-width: 540px;
		padding: 95px 80px 30px 80px;
	}
	.exit-popup__close {
		position: absolute;
		right: 30px;
		top: 24px;
	}
	.exit-popup__close svg {
		width: 20px;
		height: 20px;
	}
	.exit-popup__title {
		font-size: 49px;
	}
	.exit-popup__form {
		margin-top: 30px;
	}
	.exit-popup__pay-more {
		margin-top: 75px;
	}
}
.drawer {
	position: fixed;
	top: 0;
	right: 0;
	height: 100vh;
	width: 100vw;
	z-index: 5;
	visibility: hidden;
	transition: visibility 0.35s linear;
}
.drawer::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: opacity 0.35s ease-in-out;
	background: #000000;
}
.drawer__inner {
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
	height: 100%;
	max-width: 345px;
	margin-left: auto;
	background: white;
	-webkit-transform: translateX(100%);
	transform: translateX(100%);
	transition: -webkit-transform 0.35s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: transform 0.35s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: transform 0.35s cubic-bezier(0.645, 0.045, 0.355, 1),
		-webkit-transform 0.35s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.drawer[aria-hidden="false"] {
	visibility: visible;
}
.drawer[aria-hidden="false"]::before {
	opacity: 0.4;
}
.drawer[aria-hidden="false"] .drawer__inner {
	-webkit-transform: translateX(0);
	transform: translateX(0);
}
.link-search-results:not(:only-child) {
	margin-top: 40px;
}
.link-search-results__list {
	list-style: none;
}
.link-search-results__link {
	display: block;
	padding: 8px 20px;
	transition: color 0.15s ease-in-out, background 0.15s ease-in-out;
}
.link-search-results__link:hover,
.link-search-results__link:focus {
	background: rgba(0, 186, 219, 0.08);
	color: #00badb;
	outline: none;
}
@media screen and (min-width: 641px) {
	.link-search-results:not(:only-child) {
		margin-top: 60px;
	}
}
.expandable-content[aria-expanded] {
	position: relative;
	max-height: 320px;
	overflow: hidden;
	transition: 0.2s ease-in-out;
}
.expandable-content__toggle {
	position: absolute;
	display: flex;
	align-items: center;
	left: 0;
	bottom: 0;
	width: 100%;
	padding: 16px 20px;
	visibility: hidden;
	opacity: 0;
	color: #00badb;
	text-align: left;
	font-weight: 500;
	background: #fff;
	z-index: 1;
	transition: opacity 0.1s ease-in-out, visibility 0.1s ease-in-out;
}
.expandable-content__toggle::before {
	position: absolute;
	content: "";
	bottom: 100%;
	left: 0;
	width: 100%;
	height: 50px;
	pointer-events: none;
	background: linear-gradient(
		rgba(255, 255, 255, 0),
		rgba(255, 255, 255, 0.4) 15%,
		#fff
	);
}
.expandable-content__toggle-icon {
	position: relative;
	margin-right: 15px;
	width: 10px;
	height: 10px;
	transition: -webkit-transform 0.2s ease-in-out;
	transition: transform 0.2s ease-in-out;
	transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
.expandable-content__toggle-icon::before,
.expandable-content__toggle-icon::after {
	content: "";
	position: absolute;
	background: currentColor;
	transition: -webkit-transform 0.2s ease-in-out;
	transition: transform 0.2s ease-in-out;
	transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	top: 50%;
	left: 50%;
}
.expandable-content__toggle-icon::before {
	width: 2px;
	height: 10px;
}
.expandable-content__toggle-icon::after {
	width: 10px;
	height: 2px;
}
.expandable-content--expandable[aria-expanded="true"]
	.expandable-content__toggle-icon {
	-webkit-transform: rotateZ(90deg);
	transform: rotateZ(90deg);
}
.expandable-content--expandable[aria-expanded="true"]
	.expandable-content__toggle-icon::after {
	display: none;
}
.expandable-content--expandable .expandable-content__toggle {
	visibility: visible;
	opacity: 1;
}
.expandable-content--expandable[aria-expanded="true"]
	.expandable-content__toggle::before {
	height: 0;
}
@media (-moz-touch-enabled: 0), (hover: hover) {
	.expandable-content[aria-expanded="false"]
		.expandable-content__toggle:hover
		.expandable-content__toggle-icon {
		-webkit-transform: rotateZ(45deg);
		transform: rotateZ(45deg);
	}
}
@media screen and (min-width: 641px) {
	.expandable-content__toggle {
		padding-left: 30px;
		padding-right: 30px;
	}
}
.value-picker-button svg {
	width: 9px;
	height: 7px;
	margin-left: 10px;
	transition: -webkit-transform 0.2s ease-in-out;
	transition: transform 0.2s ease-in-out;
	transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
.value-picker-button svg path {
	stroke-width: 2.5px;
}
.value-picker-button--pill {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 14px 20px;
	text-align: left;
	border-bottom: 1px solid #e1e3e4;
	background: #fff;
}
.value-picker-button--pill svg {
	width: 12px;
	height: 8px;
	transition: -webkit-transform 0.2s ease-in-out;
	transition: transform 0.2s ease-in-out;
	transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
.value-picker-button--pill path {
	stroke-width: 2px;
}
.value-picker-button[aria-expanded="true"] svg {
	-webkit-transform: rotateZ(180deg);
	transform: rotateZ(180deg);
}
.value-picker {
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100vw;
	height: 100vh;
	z-index: 5;
	font-size: 17px;
	visibility: hidden;
	transition: visibility 0.35s ease-in-out;
	cursor: pointer;
}
.value-picker::before {
	content: "";
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	opacity: 0;
	transition: opacity 0.35s ease-in-out;
	background: #000000;
}
.value-picker[aria-hidden="false"] {
	visibility: visible;
}
.value-picker[aria-hidden="false"]::before {
	opacity: 0.4;
}
.value-picker[aria-hidden="false"] .value-picker__inner {
	-webkit-transform: translateY(0);
	transform: translateY(0);
}
.value-picker .icon--nav-triangle-borderless {
	display: none;
}
.value-picker__inner {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	background: #fff;
	-webkit-transform: translateY(100%);
	transform: translateY(100%);
	transition: visibility 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
	transition: visibility 0.2s ease-in-out, transform 0.2s ease-in-out;
	transition: visibility 0.2s ease-in-out, transform 0.2s ease-in-out,
		-webkit-transform 0.2s ease-in-out;
}
.value-picker__header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 17px 20px 16px 20px;
	border-bottom: 1px solid #e1e3e4;
}
.value-picker__header .icon--close {
	width: 17px;
	height: 17px;
	color: #1e2d7d;
}
.value-picker__title {
	margin: 0;
}
.value-picker__close svg {
	display: block;
}
.value-picker__choice-list {
	padding: 15px 0;
	max-height: 430px;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	-ms-scroll-chaining: none;
	overscroll-behavior: none;
}
.value-picker__choice-item {
	display: block;
	position: relative;
	width: 100%;
	text-align: left;
	padding: 8px 50px 7px 20px;
}
.value-picker__choice-item svg {
	position: absolute;
	width: 13px;
	height: 11px;
	right: 20px;
	top: 17px;
	-webkit-transform: scale(0);
	transform: scale(0);
	transition: -webkit-transform 0.2s ease-in-out;
	transition: transform 0.2s ease-in-out;
	transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
.value-picker__choice-item.is-selected {
	color: #00badb;
	font-weight: 500;
}
.value-picker__choice-item.is-selected svg {
	-webkit-transform: scale(1);
	transform: scale(1);
}
@media screen and (min-width: 641px) {
	.value-picker-wrapper {
		position: relative;
	}
	.value-picker {
		position: absolute;
		width: auto;
		height: auto;
		top: calc(100% + 10px);
		left: auto;
		right: -25px;
		bottom: auto;
		min-width: 160px;
		box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.1);
		visibility: hidden;
		opacity: 0;
		-webkit-transform: scale(0.9);
		transform: scale(0.9);
		background: #fff;
		color: #677279;
		z-index: 4;
		cursor: auto;
		border-radius: 3px;
		transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out,
			-webkit-transform 0.25s ease-in-out;
		transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out,
			visibility 0.25s ease-in-out;
		transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out,
			visibility 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
		will-change: transform;
	}
	.value-picker::before {
		display: none;
	}
	.value-picker[aria-hidden="false"] {
		visibility: visible;
		opacity: 1;
		-webkit-transform: scale(1.001);
		transform: scale(1.001);
		transition: opacity 0.4s cubic-bezier(0, 1, 0.4, 1), visibility 0.4s linear,
			-webkit-transform 0.4s cubic-bezier(0.18, 1.25, 0.4, 1);
		transition: opacity 0.4s cubic-bezier(0, 1, 0.4, 1),
			transform 0.4s cubic-bezier(0.18, 1.25, 0.4, 1), visibility 0.4s linear;
		transition: opacity 0.4s cubic-bezier(0, 1, 0.4, 1),
			transform 0.4s cubic-bezier(0.18, 1.25, 0.4, 1), visibility 0.4s linear,
			-webkit-transform 0.4s cubic-bezier(0.18, 1.25, 0.4, 1);
	}
	.value-picker .icon--nav-triangle-borderless {
		display: block;
		position: absolute;
		bottom: 100%;
		right: 20px;
		width: 18px;
		height: 8px;
		z-index: 2;
		-webkit-filter: drop-shadow(0 -2px 2px rgba(0, 0, 0, 0.12));
		filter: drop-shadow(0 -2px 2px rgba(0, 0, 0, 0.12));
	}
	.value-picker__inner {
		position: relative;
		width: auto;
		-webkit-transform: none !important;
		transform: none !important;
		transition: none;
		border-radius: 3px;
	}
	.value-picker__header {
		display: none;
	}
	.value-picker__choice-list {
		padding: 15px 0;
		max-height: none;
		white-space: nowrap;
		font-size: 16px;
	}
	.value-picker__choice-item {
		display: block;
		padding: 2px 25px;
		cursor: pointer;
		transition: color 0.15s ease-in-out, background 0.15s ease-in-out;
	}
	.value-picker__choice-item.is-selected,
	.value-picker__choice-item:hover {
		background: rgba(0, 186, 219, 0.08);
		color: #00badb;
		font-weight: normal;
	}
	.value-picker__choice-item.is-selected svg,
	.value-picker__choice-item:hover svg {
		display: none;
	}
}
