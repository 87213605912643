.navbar .navbar-expand-lg {
	height: 63px;
}

.help {
	float: right;
	width: 10;
}
.nav-bar__linklist {
	margin-top: 15px;
	margin-left: 25px;
	font-size: 16px;
	text-align: center;
}
/* .nav-bar__item > a {
  color: #7f8386 !important;
} */
.nav-bar__item {
	margin-right: 42px;
	list-style: none;
	text-align: left;
}
.prdct-drop-down:hover #all-prdcts {
	display: block;
}
.mega-menu__column {
	display: none;
}
.mega-menu__linklist .mega-menu__item > a {
	color: #7f8386 !important;
	list-style: none;
}

.styles_modal__gNwvD {
	margin-left: 1150px;
	margin-top: 53px;
	transition: 10s;
	width: 330px;
}
.styles_overlay__CLSq- {
	background: transparent;

	position: fixed;
	top: 0;
	left: 140px !important;
	right: 260px !important;

	bottom: 0;
	overflow-y: auto;
	overflow-x: hidden;
	z-index: 1000;
	padding: 1.2rem;
}
.owl-nav {
	margin-top: 23px;
	text-align: center;
}
.owl-nav button {
	margin: 3px;
}
.owl-prev,
.owl-next {
	background-color: #abb1b4 !important;
	font-size: 30px !important;
	border-radius: 100% !important;
	width: 40px !important;
}
.owl-prev:hover,
.owl-next:hover {
	background-color: #00badb !important;
}
.chbutleft {
	margin-left: 50px;
	background-color: rgba(255, 255, 255, 0.2) !important;
	width: 60px;
	height: 60px;
	border-radius: 100px;
}
.chbutright {
	margin-right: 50px;
	background-color: rgba(255, 255, 255, 0.2) !important;
	width: 60px;
	height: 60px;
	border-radius: 100px;
}
input[type="radio"] {
	-ms-transform: scale(1.5); /* IE 9 */
	-webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
	transform: scale(1.5);
}
/* .cat-image {
  width: 50% !important;
  height: 50% !important;

  left: 50px !important;
  top: 50px !important;
} */

/* @media (min-width: 300px) {
  .img-hover-zoom {
    margin-top: 25px;
  }
  .slider-slide > img {
    height: 430px;
  }
} */
.btn-primary.dropdown-toggle {
	background-color: transparent !important;
}
.btn {
	box-shadow: none !important;
}
.text-color {
	display: block;
	color: #a3afef;
	font-size: 15px;
	font-weight: normal;
}
.btn .text-color:hover {
	color: #a3afef;
}
.btn:hover {
	background-color: transparent;
	color: white !important;
}
.drpdown {
	background-color: white;
	width: 350px !important;
	position: absolute;
	top: 100%;
	left: -53px !important;
	z-index: 1000;
	float: left;
	/* min-width: 10rem; */
	/* padding: .5rem 0; */
	margin: 0.125rem 0 0;
	font-size: 1rem;
	color: #212529;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	/* border: 1px solid rgba(0,0,0,.15); */
	border-radius: 0.25rem;
}
/* .dropdown-menu {
	width: 350px !important;
	position: absolute;
	top: 100%;
	left: -53px !important;
	z-index: 1000;
	display: block;
	float: left;
	min-width: 10rem;
	padding: .5rem 0;
	margin: 0.125rem 0 0;
	font-size: 1rem;
	color: #212529;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0,0,0,.15);
	border-radius: 0.25rem;
} */
.dropdown-menu {
	width: 350px !important;
	position: absolute;
	top: 100%;
	left: -53px !important;
	z-index: 1000;
	float: left;
	min-width: 10rem;
	padding: 0.5rem 0;
	margin: 0.125rem 0 0;
	font-size: 1rem;
	color: #212529;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 0.25rem;
}
.cartdropdown {
	width: 450px !important;
	position: absolute;
	top: 100%;
	left: -340px !important;
	z-index: 1000;
	display: none;
	float: left;
	/* min-width: 10rem; */
	/* padding: .5rem 0; */
	margin: 0.125rem 0 0;
	font-size: 1rem;
	color: #212529;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	/* border: 1px solid rgba(0,0,0,.15); */
	border-radius: 0.25rem;
}
.butpadd .btn {
	margin: 0rem !important;
	padding: 1rem !important;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
	color: black;
	background-color: transparent;
	border: none;
}
.nav-link {
	padding: 0px !important;
}
.nav-tabs {
	border-bottom: none !important;
}
.customdropdown {
	left: 0px !important;
	position: absolute;
	top: 100%;
	width: 200px;
	z-index: 1000;
	float: left;
	min-width: 10rem;
	padding: 5px;
	margin: 0.125rem 0 0;
	font-size: 1rem;
	color: #212529;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 0.25rem;
}
ul li {
	list-style-type: none;
}
.cursor-pointer {
	cursor: pointer;
}
.megadropdown {
	position: relative;
	display: inline-block;
}

.megadropdown-content {
	/* position: absolute;
	background-color: #f1f1f1;
	display: none;
	z-index: 9999;
	top: 100%;
	transform: translateY(0%);
	transition: 0.3s ease-in-out; */
	position: absolute;
	display: none;
	width: auto;
	left: 0px !important;
	top: 380px;
	background: #fff;
	border-radius: 0 0 3px 3px;
	border-top: 1px solid #e1e3e4;
	border-bottom: 1px solid #e1e3e4;
	z-index: 9999;
	opacity: 100;
	transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
}

.megadropdown-content a {
	color: black;
	text-decoration: none;
	display: block;
}

.megadropdown-content a:hover {
	background-color: #ddd;
}

.megadropdown:hover .megadropdown-content {
	display: block;

	transform: translateY(-100%);
}
.aspect-ratio div {
	display: contents !important;
}
.js-image-zoom__zoomed-area {
	height: 200px !important;
	width: 200px !important;
	background-attachment: fixed !important;
}

.js-image-zoom__zoomed-image {
	z-index: 9999 !important;
	width: 600px !important;
	height: 600px !important;
	left: 0px !important;
	background-size: 700px 500px !important;
	transform: translateX(93%) !important;
}
.outinventory--high {
	color: #ef063c;
}
.btn-secondary {
	background-color: transparent !important;
}
.dropdown-item {
	background-color: white !important;
}
.cust {
	width: 100% !important;
	left: 0px !important;
}
.custdrop {
	width: 500px !important;
	height: 100px !important;
	left: -128px !important;
}
.h-100 {
	height: 100px !important;
}
.searchcontainer {
	position: fixed;
	background-color: white;
	top: 78px;
	left: 470px;
	width: 46%;
	height: 500px;
	z-index: 1000;
	overflow-y: scroll;
}
.searchcontainer p {
	color: black;
	font-size: 16px;
	padding: 10px;
}

.Collapsible {
	width: 200px;
}
.perimeter {
	max-width: 400px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
}

.image {
	flex: 0 0 100%;
}

.react-multi-carousel-item {
	width: auto !important;
}

.fluid {
	max-width: 1200px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	font-family: Arial;
	line-height: 1.3;
	font-size: 16px;
}

.fluid__instructions {
	flex: 0 0 auto;
	margin: 0 20px;
}

.fixed__instructions {
	flex: 1;
	margin: 0 20px;
}

a {
	color: black;
}

a:hover {
	color: #666;
}

.code {
	font-family: Courier New, Courier, monospace;
}

@media (min-width: 480px) {
	.fluid {
		flex-direction: row;
	}

	.fluid__image-container {
		flex: 0 0 30%;
		margin: 20px 0 20px 20px;
	}

	.fluid__instructions {
		flex: 0 0 50%;
		padding-top: 30px;
	}

	.fixed__instructions {
		padding-top: 30px;
		margin: 0 10px;
	}

	.portal {
		position: absolute;
		top: 40px;
		left: -30px;
	}
}
